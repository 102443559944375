import axiosInterceptors from "../axiosInterceptor/axiosInterceptor";
import { postalUrl } from "../config/apiConfig";

export const getPostalList = (data) => {
  return axiosInterceptors()
    .get(postalUrl + "/getPostalListPage?" + data)
    .then((res) => res);
};

export const deletePostalById = (id) => {
  return axiosInterceptors()
    .delete(postalUrl + "/deletePostalById/" + id)
    .then((res) => res);
};

export const getPostalById = (id) => {
  return axiosInterceptors()
    .get(postalUrl + "/getPostalById/" + id)
    .then((res) => res);
};

export const addNewPostalInfo = (data) => {
  return axiosInterceptors()
    .post(postalUrl + "/addNewPostalInfo", data)
    .then((res) => res);
};
