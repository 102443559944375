import React, { createRef, useEffect } from "react";

const OtherFeesChild = ({ index, data, setOtherFees }) => {
    const handleDeleteRow = () => {
        setOtherFees((prev) => {
            let temp = prev.filter((_, i) => (i !== index));
            return [...temp];
        });
    }
    return (
        <div key={index} className="flex items-center mb-4">
            <div className="items-center border border-[#CED4DA] rounded-[5px] py-[6px] px-[14px] w-[48%] mr-4">
                <input
                    type="text"
                    value={data.otherFeeLabel}
                    className="text-sm leading-[21px] placeholder-[#C5C5C5] text-[#404040] outline-none w-full bg-transparent"
                    placeholder="Enter Fee Detail"
                    onChange={(event) => {
                        setOtherFees(prev => {
                            data.labelRef.current.style = "";
                            prev[index].otherFeeLabel = event.target.value;
                            return [...prev];
                        })
                    }}
                    ref={data.labelRef}
                />
            </div>
            <div className="border border-[#CED4DA] rounded-[5px] py-[6px] pl-[14px] w-[48%] mr-4">
                <input
                    type="text"
                    value={data.otherFeeValue}
                    placeholder="Enter Fee"
                    className="text-sm leading-[21px] placeholder-[#C5C5C5] text-[#404040] outline-none w-full bg-transparent"
                    onChange={(event) => {
                        const re = /^[0-9\b]+$/;
                        data.valueRef.current.style = "";
                        if (event.target.value === "") {
                            setOtherFees(prev => {
                                prev[index].otherFeeValue = 0;
                                return [...prev];
                            })
                        }
                        if (re.test(event.target.value)) {
                            setOtherFees(prev => {
                                if (event.target.value[0] === "0") {
                                    prev[index].otherFeeValue = event.target.value.substring(1);
                                } else {
                                    prev[index].otherFeeValue = event.target.value;
                                }
                                return [...prev];
                            })
                        }
                    }}
                    ref={data.valueRef}
                />
            </div>
            <i className="far fa-trash-alt cursor-pointer text-[#FF0000]" onClick={() => { handleDeleteRow(index) }}></i>
        </div>
    );
}
export const OtherFeesSection = ({ otherFees, setOtherFees, totalFee, calculateTotalFee }) => {
    const addOtherFee = () => {
        setOtherFees((prev) => {
            return [...prev, { otherFeeLabel: "", otherFeeValue: 0, labelRef: createRef(), valueRef: createRef() }]
        })
    }
    useEffect(() => {
        calculateTotalFee();
        // eslint-disable-next-line
    }, [otherFees])
    return (
        <div className="col-span-2 flex flex-col justify-between pl-2">
            <div className="overflow-auto">
                <div className="otherfees-section">
                    <div className="flex text-sm text-[#404040] mb-2 items-center">
                        <p className="mr-2">
                            Other Fees
                        </p>
                        <div className="text-center p-2 w-[20px] h-[20px] flex justify-center items-center rounded-[50%] cursor-pointer text-white  bg-[#80509F]" onClick={addOtherFee}>
                            <i className="far fa-plus cursor-pointer"></i>
                        </div>
                    </div>
                    <div>
                        {
                            otherFees.length > 0 ?
                                otherFees.map((e, i) => {
                                    return <OtherFeesChild key={i} index={i} data={e} setOtherFees={setOtherFees} />
                                }) : <p className="text-sm text-[#404040] pl-2 pt-2">No Other Fess</p>
                        }
                    </div>
                </div>
            </div>
            <div className="flex justify-end my-3 pr-2">
                <strong className="mr-2">Total Fees : </strong> {totalFee}/-
            </div>
        </div>
    );
}