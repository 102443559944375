import axiosInterceptors from "../axiosInterceptor/axiosInterceptor";
import { userUrl } from "../config/apiConfig";

export const getUserListPage = (data) => {
  return axiosInterceptors()
    .get(userUrl + "/getUserListPage?" + data)
    .then((res) => res);
};

export const deleteUser = (id) => {
  return axiosInterceptors()
    .delete(userUrl + "/deleteUser/" + id)
    .then((res) => res);
};

export const getUserById = (id) => {
  return axiosInterceptors()
    .get(userUrl + "/getUserById/" + id)
    .then((res) => res);
};

export const editUser = (data) => {
  return axiosInterceptors()
    .put(userUrl + "/editUser/" + data.userId, data)
    .then((res) => res);
};

export const getPolicy = (id) => {
  return axiosInterceptors()
    .get(userUrl + "/getPolicy/" + id)
    .then((res) => res);
};

export const editPolicy = (id, data) => {
  return axiosInterceptors()
    .post(userUrl + "/editPolicy/" + id, data)
    .then((res) => res);
};

export const editProfile = (data) => {
  return axiosInterceptors()
    .put(userUrl + "/editProfile", data)
    .then((res) => res);
};
