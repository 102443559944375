import React from "react";
import WithHeaderSidebar from "../layout/withHeaderSidebar";

const PageNotFound = () => {
  const token = sessionStorage.getItem("token");

  return (
    <div
      className={`flex flex-col items-center justify-center px-10 md:px-44 ${
        token ? "pt-32" : " h-[100vh]"
      }`}
    >
      <div className="flex justify-center items-center w-full mb-[20px]">
        <img
          src="/images/Pulse_Logo_Navbar.png"
          className="h-[60px] w-[60px]"
          alt="logo"
        />
        <p className="ml-[13px] text-[#00ADEE] text-[20px] leading-[28px] font-bold">
          PULSE EMR
        </p>
      </div>
      <div className="shadow-[0_0.5rem_1rem_#00000026] p-4 md:p-14 rounded-lg w-full">
        <p className="text-7xl font-bold text-center">404</p>
        <p className="text-2xl text-center font-normal mt-5">
          OPPS!!! PAGE NOT FOUND
        </p>
        <p className="text-2xl text-center font-normal mt-14">
          Sorry, the page you're looking for doesn't exist.
        </p>
      </div>
    </div>
  );
};

const token = sessionStorage.getItem("token");

const Component = token ? WithHeaderSidebar(PageNotFound) : PageNotFound;

export default Component;
