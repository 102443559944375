import React, { createRef, useRef, useState } from "react";
import TextInput from "../../components/textInput";
import Button from "../../components/button";
import { useNavigate } from "react-router-dom";
import { verifyEmail } from "../../services/auth";

const validation = {
  email: [
    {
      type: "required",
      message: "Enter your email address",
    },
    {
      type: "regex",
      regex:
        "^[_A-Za-z0-9-\\+]+(\\.[_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(\\.[A-Za-z0-9]+)*(\\.[A-Za-z]{2,})$",
      message: "Please enter a valid email address.",
    },
  ],
};
const ForgotPassword = () => {
  const [error, setError] = useState()
  const navigate = useNavigate();
  const inputRefs = useRef([createRef()]);
  const [info, setInfo] = useState({ email: "" });

  const handleChange = (name, value) => {
    setInfo((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  const handleSubmit = () => {
    setError("")
    let isValid = true;
    for (let i = 0; i < inputRefs.current.length; i++) {
      const valid = inputRefs.current[i].current.validate();
      if (!valid) {
        isValid = false;
      }
    }
    if (!isValid) {
      return;
    }
    const data = "email=" + info.email
    verifyEmail(data).then(res => {
      if (res.status === 200) {
        navigate("/forgot-method-select", { state: { user: res?.result?.userDto } });
      }else{
        setError(res.message)
      }
    })
  };

  return (
    <div
      className="flex flex-col md:flex-row h-screen w-screen"
      style={{
        backgroundImage: "url(/images/auth_screens_Background.png)",
        backgroundSize: "cover",
      }}
    >
      <div className="md:flex items-center justify-center md:w-[50%] bg-white opacity-90 hidden">
        <img
          src="/images/Pulse_Logo_Auth_Screens.png"
          className="w-[192px] h-[232px]"
          alt="logo"
        />
      </div>
      <div className="flex flex-col md:w-[50%] h-screen bg-[#f3f3ffe6] items-center  sm:justify-center p-5 md:px-10 lg:px-28">
        <img
          src="/images/Pulse_Logo_Auth_Screens.png"
          className="w-[132px] h-[160px] m-5 md:hidden"
          alt="logo"
        />
        <div className="flex flex-col bg-white p-8 sm:px-10 sm:pt-[71px] sm:pb-[75px] w-full rounded-[10px] shadow-2xl">
          <p className="text-2xl leading-7 text-[#000000] font-bold">
            Forgot Password?
          </p>
          <p className="text-sm leading-[17px] text-[#898989] font-bold mt-2">
            Enter Your Registered Email
          </p>
          <TextInput
            label="Email Address"
            placeholder="YourEmail@pulseemr.com"
            inputContainerStyle="bg-[#F3F3FF] border-[0px]"
            containerStyle="mt-10"
            validations={validation.email}
            ref={inputRefs.current[0]}
            name="email"
            value={info.email}
            onChange={handleChange}
          />
          {error ? <span className="text-sm text-red-500 mb-2">{error}</span> : null}
          <Button
            text="Verify Email"
            buttonStyle="py-[9px] border border-[#B9C1CC] rounded-[3px]"
            onClick={handleSubmit}
          />
          <div className="h-[1px] w-full bg-[#707070] mt-[13px]" />
          <p className="text-center text-[#404040] text-sm leading-[17px] mt-[14px]">
            Already have an account?{" "}
            <span
              className="text-[#00ADEE] cursor-pointer"
              onClick={() => {
                navigate("/");
              }}
            >
              Sign in
            </span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
