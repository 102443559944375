import axiosInterceptors from "../../axiosInterceptor/axiosInterceptor";
import { policyUrl } from "../../config/apiConfig";

export const createPolicy = (data) => {
  return axiosInterceptors()
    .post(policyUrl + "/createPolicy", data)
    .then((res) => res);
};

export const policyListPage = (data) => {
  return axiosInterceptors()
    .get(policyUrl + "/policyListPage?" + data)
    .then((res) => res);
};

export const getPolicyById = (id) => {
  return axiosInterceptors()
    .get(policyUrl + "/" + id)
    .then((res) => res);
};

export const updatePolicyById = (data) => {
  return axiosInterceptors()
    .put(policyUrl + "/" + data.policyId, data)
    .then((res) => res);
};

export const deletePolicyById = (id) => {
  return axiosInterceptors()
    .delete(policyUrl + "/" + id)
    .then((res) => res);
};

export const getAllPolicy = () => {
  return axiosInterceptors()
    .get(policyUrl + "/getAllPolicy")
    .then((res) => res);
};
