import DatePicker from "../../components/datePicker";
import TextInput from "../../components/textInput";
import WithHeaderSidebar from "../../layout/withHeaderSidebar";
import TextArea from "../../components/textArea";
import Button from "../../components/button";
import SearchBox from "../../components/searchbox";
import Dropdown from "../../components/dropdown";
import { createRef, useEffect, useRef, useState } from "react";
import {
  addNewPhoneCallEntry,
  getPhoneCallById,
} from "../../services/phoneCall";
import { useNavigate, useParams } from "react-router-dom";
import dayjs from "dayjs";
import WithAuth from "../../layout/withAuth";

const validations = {
  referenceNumber: [
    {
      type: "required",
      message: "Please enter reference id",
    },
  ],
  date: [
    {
      type: "required",
      message: "Please select call date",
    },
  ],
  time: [
    {
      type: "required",
      message: "Please select call time",
    },
  ],
  contactNumber: [
    {
      type: "required",
      message: "Please enter contact number",
    },
  ],
  contactName: [
    {
      type: "required",
      message: "Please enter contact name",
    },
  ],
  callType: [
    {
      type: "required",
      message: "Please select call type",
    },
  ],
  followUpDate: [
    {
      type: "required",
      message: "Please select follow up date",
    },
  ],
  followUpTime: [
    {
      type: "required",
      message: "Please select follow up time",
    },
  ],
  description: [
    {
      type: "required",
      message: "Please enter description",
    },
  ],
  remark: [
    {
      type: "required",
      message: "Please enter remark",
    },
  ],
};

const RegisterPhoneCall = (props) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const inputRefs = useRef([
    createRef(),
    createRef(),
    createRef(),
    createRef(),
    createRef(),
    createRef(),
    createRef(),
    createRef(),
    createRef(),
    createRef(),
  ]);

  const [info, setInfo] = useState({
    phoneCallId: "0",
    referenceNumber: "",
    date: "",
    time: "",
    contactNumber: "",
    contactName: "",
    callType: "",
    followUpDate: "",
    followUpTime: "",
    description: "",
    remark: "",
  });

  useEffect(() => {
    if (id) {
      getPhoneCallById(id).then((res) => {
        if (res.status === 200) {
          const phoneCallDto = res?.result?.phoneCallDto;
          setInfo({
            ...phoneCallDto,
            phoneCallId: phoneCallDto.phoneCallId.toString(),
            date: new Date(phoneCallDto.date),
            followUpDate: new Date(phoneCallDto.followUpDate),
          });
        }
      });
    }
  }, [id]);

  const handleChange = (name, value) => {
    setInfo((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  const handleRegister = () => {
    let isValid = true;
    for (let i = 0; i < inputRefs.current.length; i++) {
      const valid = inputRefs.current[i].current.validate();
      if (!valid) {
        isValid = false;
      }
    }
    if (!isValid) {
      return;
    }
    const payload = {
      ...info,
      phoneCallId: Number(info.phoneCallId),
      date: dayjs(info.date).format("YYYY-MM-DD HH:mm:ss"),
      followUpDate: dayjs(info.followUpDate).format("YYYY-MM-DD HH:mm:ss"),
    };
    addNewPhoneCallEntry(payload).then((res) => {
      if (res.status === 200) {
        navigate("/phone-call-book/manage");
      }
    });
  };
  return (
    <div
      className="flex flex-col"
  style={{ /*minHeight: `calc(100vh - ${props.headerHeight}px)` */}}
    >
      <div className="p-5 sm:pl-8 sm:pt-[17px] sm:pb-[23px]">
        <p className="text-xl sm:text-2xl sm:leading-6 leading-6 text-[#404040] font-bold">
          Phone Call
        </p>
      </div>
      <div className="flex flex-col lg:flex-row bg-[#F7F8F9] pt-[18px] p-5 sm:px-7 sm:pb-9 gap-[22px]">
        <div className="flex flex-col bg-[#FFFFFF] rounded-[15px] lg:w-[20%] shadow-[0px_9px_20px] shadow-[#2E235E1A]">
          <div className="p-3 lg:px-[30px] border-b">
            <p className="text-base md:text-xl md:leading-6 text-[#404040] font-bold">
              Recent Call List
            </p>
          </div>
          <div className="p-5 lg:px-[30px]">
            <SearchBox />
          </div>
        </div>
        <div className="flex flex-col bg-[#FFFFFF] rounded-[15px] lg:w-[80%] shadow-[0px_9px_20px] shadow-[#2E235E1A]">
          <div className="p-5 lg:px-[30px] border-b">
            <p className="text-base md:text-xl md:leading-6 text-[#404040] font-bold">
              Phone Call Entry
            </p>
          </div>
          <div className="p-5 lg:px-[30px] lg:pb-9 lg:pt-6">
            <div className="grid grid-cols-1 md:grid-cols-3 md:gap-4 lg:gap-6 xl:gap-9">
              <TextInput
                ref={inputRefs.current[0]}
                value={info.referenceNumber}
                name="referenceNumber"
                label="Reference No."
                placeholder="000000"
                onChange={handleChange}
                validations={validations.referenceNumber}
              />
              <DatePicker
                ref={inputRefs.current[1]}
                value={info.date}
                label="Date"
                name="date"
                placeholderText="DD / MM / YYYY"
                onChange={handleChange}
                validations={validations.date}
              />
              <TextInput
                label="Call Time"
                placeholder="00 : 00 --"
                ref={inputRefs.current[2]}
                name="time"
                value={info.time}
                onChange={handleChange}
                validations={validations.time}
              />
            </div>
            <div className="grid grid-cols-1 md:grid-cols-3 md:gap-4 lg:gap-6 xl:gap-9">
              <TextInput
                label="Contact No."
                name="contactNumber"
                value={info.contactNumber}
                ref={inputRefs.current[3]}
                onChange={handleChange}
                placeholder="+91 ----- -----"
                validations={validations.contactNumber}
              />
              <TextInput
                name="contactName"
                label="Contact Name"
                placeholder="Contact Name"
                containerStyle="sm:col-span-2"
                value={info.contactName}
                onChange={handleChange}
                ref={inputRefs.current[4]}
                validations={validations.contactName}
              />
            </div>
            <div className="grid grid-cols-1 md:grid-cols-3 md:gap-4 lg:gap-6 xl:gap-9">
              <Dropdown
                label="Call Type"
                placeholder="Call Type"
                value={info.callType}
                options={[
                  {
                    label: "Urgent",
                    value: "urgent",
                  },
                ]}
                onChange={(value) => {
                  handleChange("callType", value.value);
                }}
                ref={inputRefs.current[5]}
                validations={validations.callType}
              />
              <DatePicker
                label="Follow Up Date"
                placeholderText="DD / MM / YYYY"
                ref={inputRefs.current[6]}
                value={info.followUpDate}
                name="followUpDate"
                onChange={handleChange}
                validations={validations.followUpDate}
              />
              <TextInput
                label="Schedule Time"
                placeholder="00 : 00 --"
                ref={inputRefs.current[7]}
                value={info.followUpTime}
                name="followUpTime"
                onChange={handleChange}
                validations={validations.followUpTime}
              />
            </div>
            <TextArea
              label="Call Description"
              placeholder="Call Description"
              rows={2}
              name="description"
              value={info.description}
              onChange={handleChange}
              ref={inputRefs.current[8]}
              validations={validations.description}
            />
            <TextArea
              label="Remark"
              rows={2}
              name="remark"
              value={info.remark}
              onChange={handleChange}
              ref={inputRefs.current[9]}
              validations={validations.remark}
            />
            <div className="flex justify-end">
              <Button
                text="Register"
                textStyle="mx-10"
                onClick={handleRegister}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WithAuth(WithHeaderSidebar(RegisterPhoneCall));
