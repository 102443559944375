import React, { createRef, useLayoutEffect, useRef, useState } from "react";
import Sidebar from "../components/sidebar";
// import Header from "../components/header";
import Drawer from "../components/drawer";
import { useLocation } from "react-router-dom";

const WithHeaderSidebar = (Component) => {
  return function Wrapper(props) {
    const location = useLocation();
    // const [headerHeight, setHeaderHeight] = useState(60);
    const [sidebarCollapsed, setSidebarCollapsed] = useState(false);
    useLayoutEffect(() => {
      // const header = document?.getElementById("header");
      // setHeaderHeight(header.offsetHeight);
      if (location.pathname.includes("patient-history")) {
        setSidebarCollapsed(true);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const scrollDiv = useRef(createRef());
    const handleTop = () => {
     scrollDiv.current.scrollTo({
        top: 0,
        behavior: "smooth"
      })
    }

    return (
      <div className="flex flex-row bg-white h-screen w-screen overflow-hidden">
        <div className="fixed bottom-4 right-4">
          <button className="bg-[#80509F] hover:bg-blue-600 text-white rounded-[50%] w-[30px] h-[30px] shadow-lg" onClick={handleTop}>
          <i className="fas fa-long-arrow-up"></i>
          </button>
        </div>
        <Sidebar
          onCollapseChange={(val) => {
            setSidebarCollapsed(val);
          }}
        />
        <Drawer
          open={sidebarCollapsed}
          setSidebarCollapsed={setSidebarCollapsed}
        />
        <div
          className={`h-screen bg-[#F7F8F9] overflow-auto ${sidebarCollapsed ? "w-full" : "w-[100%] md:w-[85%]" }`}
          ref={scrollDiv}
        >
          {/* <Header setSidebarCollapsed={setSidebarCollapsed} /> */}
          {/* <div
            className="overflow-auto"
            style={{ height: `calc(100vh - 10px)` }}
          > */}
          <Component {...props} /*headerHeight={headerHeight}*/ />
          {/* </div> */}
        </div>
      </div>
    );
  };
};

export default WithHeaderSidebar;
