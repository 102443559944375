import DatePicker from "../../components/datePicker"
import TextInput from "../../components/textInput"
import WithHeaderSidebar from "../../layout/withHeaderSidebar"
import TextArea from '../../components/textArea'
import Button from "../../components/button"
import SearchBox from "../../components/searchbox"
import Dropdown from "../../components/dropdown"
import { useState } from "react"
import WithAuth from "../../layout/withAuth"
// import { useLocation, useParams } from "react-router-dom"

const AmbulanceLog = (props) => {
    // const location = useLocation()
    // const { id } = useParams()

    const [ambulanceInfo, setAmbulanceInfo] = useState({
        logNumber: "",
        date: ""
    })

    const handleChange = (name, value) => {
        setAmbulanceInfo(prev => {
            return {
                ...prev,
                [name]: value
            }
        })
    }
    return (
        <div className="flex flex-col" style={{ /*minHeight: `calc(100vh - ${props.headerHeight}px)`*/ }}>
            <div className="p-5 lg:pl-8 pt-[17px] pb-[23px]">
                <p className="text-xl sm:text-2xl leading-6 text-[#404040] font-bold">Ambulance</p>
            </div>
            <div className="flex flex-col lg:flex-row bg-[#F7F8F9] pt-[18px] p-5 sm:px-7 sm:pb-9 gap-[22px]">
                <div className="flex flex-col bg-[#FFFFFF] rounded-[15px] lg:w-[20%] shadow-[0px_9px_20px] shadow-[#2E235E1A]">
                    <div className="p-3 lg:px-[30px] border-b">
                        <p className="text-base md:text-xl md:leading-6 text-[#404040] font-bold">Ambulance Log</p>
                    </div>
                    <div className="p-5 lg:px-[30px]">
                        <SearchBox />
                    </div>
                </div>
                <div className="flex flex-col bg-[#FFFFFF] rounded-[15px] lg:w-[80%] shadow-[0px_9px_20px] shadow-[#2E235E1A]">
                    <div className="p-5 lg:px-[30px] border-b">
                        <p className="text-base md:text-xl md:leading-6 text-[#404040] font-bold">Ambulance Log Entry</p>
                    </div>
                    <div className="p-5 lg:px-[30px] lg:pb-9 lg:pt-6">
                        <div className="grid grid-cols-1 md:grid-cols-3 md:gap-4 lg:gap-6 xl:gap-9">
                            <TextInput
                                label="Log Number"
                                labelStyle="font-normal"
                                placeholder="000000"
                                name="logNumber"
                                value={ambulanceInfo.logNumber}
                                onChange={handleChange}
                            />
                            <DatePicker
                                label="Date"
                                placeholderText="DD / MM / YYYY"
                                labelStyle="font-normal"
                                name="date"
                                value={ambulanceInfo.date}
                                onChange={handleChange}
                            />
                            <Dropdown label="Available Ambulance" placeholder="Select Ambulance" labelStyle="font-normal" />
                        </div>
                        <div className="grid grid-cols-1 md:grid-cols-3 md:gap-4 lg:gap-6 xl:gap-9">
                            <TextInput label="Ambulance Code" placeholder="000000" labelStyle="font-normal" />
                            <TextInput label="Vehicle Number" placeholder="000000" labelStyle="font-normal" />
                            <TextInput label="Vehicle Model" placeholder="Force Amb" labelStyle="font-normal" />
                        </div>
                        <div className="grid grid-cols-1 md:grid-cols-3 md:gap-4 lg:gap-6 xl:gap-9">
                            <Dropdown label="Driver Name" placeholder="Driver Name" labelStyle="font-normal" />
                            <TextInput label="Driver Contact No." placeholder="+91 ----- -----" labelStyle="font-normal" />
                        </div>
                        <TextArea label="Address" placeholder="Address" rows={2} labelStyle="font-normal" />
                        <div className="grid grid-cols-1 md:grid-cols-3 md:gap-4 lg:gap-6 xl:gap-9">
                            <TextInput label="Patient Contact No." placeholder="+91 ----- -----" labelStyle="font-normal" />
                            <TextInput label="Secondary Contact" placeholder="+91 ----- -----" labelStyle="font-normal" />
                        </div>
                        <div className="flex justify-end">
                            <Button text="Register" textStyle="mx-10" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default WithAuth(WithHeaderSidebar(AmbulanceLog))