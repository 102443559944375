import React, { createRef, useEffect, useRef, useState } from "react";
import TextInput from "../../components/textInput";
import Button from "../../components/button";
import Dropdown from "../../components/dropdown";
import { useLocation, useNavigate } from "react-router-dom";
import { registerSecurityQuestions, resetPasswordWithSecurityQuestions } from "../../services/auth";
import { getSecurityQuestions } from "../../services/common";

const validations = {
  securityQuestionId1: [
    {
      type: "required",
      message: "Please select the security question",
    },
  ],
  answer1: [
    {
      type: "required",
      message: "Please enter answer for the security question",
    },
  ],
  securityQuestionId2: [
    {
      type: "required",
      message: "Please select the security question",
    },
  ],
  answer2: [
    {
      type: "required",
      message: "Please enter answer for the security question",
    },
  ],
};
const SecurityQuestions = () => {
  let location = useLocation();
  const [error, setError] = useState()
  const { userId, flow } = location?.state || {};
  const navigate = useNavigate();
  const [questions, setQuestions] = useState([])
  const inputRefs = useRef([
    createRef(),
    createRef(),
    createRef(),
    createRef(),
  ]);
  const [info, setInfo] = useState({
    securityQuestionId1: "",
    answer1: "",
    securityQuestionId2: "",
    answer2: "",
  });

  const handleChange = (name, value) => {
    setInfo((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  useEffect(() => {
    getSecurityQuestions().then(res => {
      if (res.status === 200) {
        const securityQuestions = res?.result?.securityQuestions?.map(securityQuestion => {
          return {
            label: securityQuestion.securityQuestionName,
            value: securityQuestion.securityQuestionId
          }
        })
        setQuestions(securityQuestions)
      }
    })
  }, [])

  const handleSubmit = () => {
    let isValid = true;
    for (let i = 0; i < inputRefs.current.length; i++) {
      const valid = inputRefs.current[i].current.validate();
      if (!valid) {
        isValid = false;
      }
    }
    if (!isValid) {
      return;
    }
    const payload = {
      "userId": userId,
      "securityQuestions": [
        {
          "securityQuestionId": info.securityQuestionId1,
          "answer": info.answer1
        },
        {
          "securityQuestionId": info.securityQuestionId2,
          "answer": info.answer2
        }
      ]
    }
    if (flow === 'registration') {
      registerSecurityQuestions(payload).then(res => {
        if (res.status === 200) {
          navigate("/account-created");
        } else {
          setError(res?.message)
        }
      })
    } else if (flow === "resetPassword") {
      resetPasswordWithSecurityQuestions(payload).then(res => {
        if (res.status === 200) {
          navigate("/reset-password", { state: { userId } })
        } else {
          setError(res?.message)
        }
      })
    }
  };

  return (
    <div
      className="flex flex-col md:flex-row h-screen w-screen"
      style={{
        backgroundImage: "url(/images/auth_screens_Background.png)",
        backgroundSize: "cover",
      }}
    >
      <div className="md:flex items-center justify-center md:w-[50%] bg-white opacity-90 hidden">
        <img
          src="/images/Pulse_Logo_Auth_Screens.png"
          className="w-[192px] h-[232px]"
          alt="logo"
        />
      </div>
      <div className="flex flex-col md:w-[50%] h-screen bg-[#f3f3ffe6] items-center sm:justify-center p-5 md:px-10 lg:px-28">
        <img
          src="/images/Pulse_Logo_Auth_Screens.png"
          className="w-[132px] h-[160px] m-5 md:hidden"
          alt="logo"
        />
        <div className="flex flex-col bg-white p-8 sm:px-10 sm:pt-[71px] sm:pb-[75px] w-full rounded-[10px] shadow-2xl">
          <p className="text-2xl leading-7 text-[#000000] font-bold">
            Security Questions
          </p>
          <p className="text-sm leading-[17px] text-[#898989] font-bold mt-2">
            Select Security Questions and Answers
          </p>
          <Dropdown
            label="Security Question One"
            className="mt-8"
            options={questions}
            controlStyle={{ borderWidth: 0, backgroundColor: "#F3F3FF" }}
            dropdownIndicator={
              <i className="fa-solid fa-caret-down text-[#9A9A9A]"></i>
            }
            indicatorContainerStyle="bg-[#F3F3FF] border-l-0"
            name="securityQuestionId1"
            value={info.securityQuestionId1}
            onChange={(value) => {
              handleChange("securityQuestionId1", value.value);
            }}
            ref={inputRefs.current[0]}
            validations={validations.securityQuestionId1}
          />
          <TextInput
            label="Security Answer One"
            placeholder="Your Answer"
            inputContainerStyle="bg-[#F3F3FF] border-[0px]"
            name="answer1"
            value={info.answer1}
            onChange={handleChange}
            ref={inputRefs.current[1]}
            validations={validations.answer1}
          />
          <Dropdown
            label="Security Question One"
            options={questions}
            controlStyle={{ borderWidth: 0, backgroundColor: "#F3F3FF" }}
            dropdownIndicator={
              <i className="fa-solid fa-caret-down text-[#9A9A9A]"></i>
            }
            indicatorContainerStyle="bg-[#F3F3FF] border-l-0"
            name="securityQuestionId2"
            value={info.securityQuestionId2}
            onChange={(value) => {
              handleChange("securityQuestionId2", value.value);
            }}
            ref={inputRefs.current[2]}
            validations={validations.securityQuestionId2}
          />
          <TextInput
            label="Security Answer Two"
            placeholder="Your Answer"
            inputContainerStyle="bg-[#F3F3FF] border-[0px]"
            name="answer2"
            value={info.answer2}
            onChange={handleChange}
            ref={inputRefs.current[3]}
            validations={validations.answer2}
          />
          {error ? <span className="text-sm text-red-500 mt-2">{error}</span> : null}
          <Button
            text={flow === "resetPassword" ? "Reset Password" : "Save Security Questions"}
            buttonStyle="py-[9px] mt-[19px] border border-[#B9C1CC] rounded-[3px]"
            onClick={handleSubmit}
          />
        </div>
      </div>
    </div>
  );
};

export default SecurityQuestions;
