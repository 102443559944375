import React, { useEffect, useState } from "react";
import StepWizard from "react-step-wizard";
import CustomStepper from "./stepper";

const FormStepper = ({ steps = [], activeStep = 0, children }) => {
  const [step, setStep] = useState(0);
  useEffect(() => {
    setStep(activeStep);
  }, [activeStep]);

  const handleStepChange = (e) => {
    setStep(e.activeStep - 1);
  };

  return (
    <StepWizard
      nav={<CustomStepper steps={steps} step={step} />}
      onStepChange={handleStepChange}
    >
      {children}
    </StepWizard>
  );
};

export default FormStepper;
