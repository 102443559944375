import React, { useState } from "react";
import Button from "../../components/button";
import RadioGroup from "../../components/radioGroup";
import { useLocation, useNavigate } from "react-router-dom";
import { resendOtp } from "../../services/auth"

const ForgotMethodSelect = () => {
  const navigate = useNavigate();
  let location = useLocation();
  const [methodSelected, setMethodSelected] = useState("email");
  const { user } = location.state || {}

  const options = [
    {
      label: `Send Verification Code to ${user?.email}`,
      value: "email",
    },
    {
      label: `Send Verification Code to ${user?.mobileNumber}`,
      value: "phone",
    },
  ];

  const handleSubmit = () => {
    let payload = {}
    if (methodSelected === "email") {
      payload = {
        userId: user.userId,
        email: user.email,
        mobileNumber: null
      }
    } else {
      payload = {
        userId: user.userId,
        email: null,
        mobileNumber: user.mobileNumber
      }
    }
    resendOtp(payload).then(res => {
      if (res.status === 200) {
        navigate("/verify-otp", { state: { verifyFlow: "forgotPassword", userId: user.userId, toVerify: methodSelected } });
      }
    })
  };
  return (
    <div
      className="flex flex-col md:flex-row h-screen w-screen"
      style={{
        backgroundImage: "url(/images/auth_screens_Background.png)",
        backgroundSize: "cover",
      }}
    >
      <div className="md:flex items-center justify-center md:w-[50%] bg-white opacity-90 hidden">
        <img
          src="/images/Pulse_Logo_Auth_Screens.png"
          className="w-[192px] h-[232px]"
          alt="logo"
        />
      </div>
      <div className="flex flex-col md:w-[50%] h-screen bg-[#f3f3ffe6] items-center sm:justify-center p-5 md:px-10 lg:px-28">
        <img
          src="/images/Pulse_Logo_Auth_Screens.png"
          className="w-[132px] h-[160px] m-5 md:hidden"
          alt="logo"
        />
        <div className="flex flex-col bg-white p-8 sm:px-10 sm:pt-[71px] sm:pb-[75px] w-full rounded-[10px] shadow-2xl">
          <p className="text-2xl leading-7 text-[#000000] font-bold">
            Forgot Password?
          </p>
          <p className="text-sm leading-[17px] text-[#898989] font-bold mt-2">
            Select Option to Receive OPT
          </p>
          <RadioGroup
            options={options}
            className="mt-12 mb-11"
            value={methodSelected}
            onChange={(name, value) => {
              setMethodSelected(value);
            }}
          />
          <Button
            text="Send Verification Code"
            buttonStyle="py-[9px] border border-[#B9C1CC] rounded-[3px]"
            onClick={handleSubmit}
          />
          <div className="h-[1px] w-full bg-[#707070] mt-[13px]" />
          <p className="text-center text-[#404040] text-sm leading-[17px] mt-[13px]">
            Not Sure?{" "}
            <span className="text-[#00ADEE] cursor-pointer" onClick={() => {
              navigate("/security-questions", { state: { userId: user.userId, flow: "resetPassword" } })
            }}>
              Try Another Way
            </span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default ForgotMethodSelect;
