import React from "react";
import { Stepper } from "react-form-stepper";
import useWindowDimensions from "../hooks/useWindowDimension";

const CustomStepper = ({ steps = [], step = 0 }) => {
  const { width } = useWindowDimensions();

  return (
    <Stepper
      steps={steps}
      activeStep={step}
      connectorStateColors={true}
      connectorStyleConfig={{
        completedColor: "#80509F",
        activeColor: "#80509F",
        disabledColor: "#C5C5C5",
      }}
      styleConfig={{
        activeBgColor: "#80509F",
        completedBgColor: "#80509F",
        inactiveBgColor: "#A1A1A1",
        activeTextColor: "#ffffff",
        completedTextColor: "#ffffff",
        inactiveTextColor: "#ffffff",
        labelFontSize: width > 430 ? 14 : 10,
      }}
    />
  );
};

export default CustomStepper;
