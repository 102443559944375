import React from "react";

const Button = ({
  text = "",
  textStyle = "",
  buttonStyle = "",
  disabled = false,
  onClick = () => {},
}) => {
  return (
    <button
      onClick={onClick}
      disabled={disabled}
      className={`${
        disabled ? "bg-[#CED4DA]" : "bg-[#80509F]"
      }  py-[5px] px-4 rounded-[5px] ${buttonStyle}`}
    >
      <p
        className={`text-sm leading-[21px] text-center text-white ${textStyle}`}
      >
        {text}
      </p>
    </button>
  );
};

export default Button;
