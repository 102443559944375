import axiosInterceptors from "../axiosInterceptor/axiosInterceptor";
import { opdUrl } from "../config/apiConfig";

export const getOpdAppointmentById = (id) => {
  return axiosInterceptors()
    .get(opdUrl + "/getOpdAppointmentById/" + id)
    .then((res) => res);
};

export const deleteOpdAppointmentById = (id) => {
  return axiosInterceptors()
    .delete(opdUrl + "/deleteOpdAppointmentById/" + id)
    .then((res) => res);
};

export const getOpdAppointmentsListPage = (data) => {
  return axiosInterceptors()
    .get(opdUrl + "/getOpdAppointmentsListPage?" + data)
    .then((res) => res);
};

export const getOpdAppointmentsListPageByDocId = (data) => {
  return axiosInterceptors()
    .get(opdUrl + "/getOpdAppointmentsListPageByDocId?" + data)
    .then((res) => res);
};

export const addNewOpdAppointment = (data) => {
  return axiosInterceptors()
    .post(opdUrl + "/addNewOpdAppointment", data)
    .then((res) => res);
};
