import React, { useCallback, useEffect, useState } from "react";
import WithHeaderSidebar from "../../layout/withHeaderSidebar";
import Button from "../../components/button";
import TableWithSearchAndPagination from "../../components/tableWithSearchAndPagination";
import { useNavigate } from "react-router-dom";
import { deleteVisitor, getVisitorsList } from "../../services/visitor";
import dayjs from "dayjs";
import WithAuth from "../../layout/withAuth";

const headings = [
  {
    label: "Visitor No.",
    width: "10%",
    align: "left",
  },
  {
    label: "Visitor Name",
    width: "12%",
    align: "left",
  },
  {
    label: "Date",
    width: "15%",
    align: "left",
  },
  {
    label: "In Time",
    width: "15%",
    align: "left",
  },
  {
    label: "Out Time",
    width: "15%",
    align: "left",
  },
  {
    label: "Purpose",
    width: "13%",
    align: "left",
  },
  {
    label: "Contact",
    width: "20%",
    align: "left",
  },
  {
    label: "Actions",
    width: "15%",
    align: "center",
  },
];

const dataToShow = [
  {
    key: "visitorId",
    align: "left",
  },
  {
    key: "visitorName",
    align: "left",
  },
  {
    key: "date",
    align: "left",
  },
  {
    key: "inTime",
    align: "left",
  },
  {
    key: "outTime",
    align: "left",
  },
  {
    key: "purposeOfVisit",
    align: "left",
  },
  {
    key: "contactNumber",
    align: "left",
  },
];

const VisitorsBook = () => {
  const navigate = useNavigate();
  const [totalData, setTotalData] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [callbackAttributes, setCallbackAttributes] = useState({
    searchSend: "",
    perPage: "25",
    selectedPage: "0",
    sort: "visitorId,asc",
  });
  const [visitorsList, setVisitorsList] = useState([]);

  const displayVisitorsList = useCallback(() => {
    setVisitorsList([]);
    const { searchSend, selectedPage, perPage, sort } = callbackAttributes;
    let data = `searchKey=${searchSend}&page=${selectedPage}&size=${perPage}&sort=${sort}`;
    getVisitorsList(data).then((res) => {
      if (res?.status === 200) {
        const visitorsList = res?.result?.visitorListPage.map((visitor) => {
          return {
            ...visitor,
            visitorName: visitor.firstName + " " + visitor.lastName,
            date: dayjs(visitor.date).format("DD/MM/YYYY"),
          };
        });
        setVisitorsList(visitorsList || []);
        setTotalData(res?.result?.getTotalRecords || 0);
        setTotalPages(res?.result?.getTotalPages || 0);
      } else {
        // props?.setSnackbar({
        //     showSnackbar: true,
        //     message: res?.message,
        //     variant: "error"
        // })
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callbackAttributes]);

  useEffect(() => {
    displayVisitorsList();
  }, [displayVisitorsList, callbackAttributes]);

  const handleDelete = (item) => {
    deleteVisitor(item.visitorId).then((res) => {
      if (res.status === 200) {
        displayVisitorsList();
      }
    });
  };

  const handleEdit = (item) => {
    navigate("/visitors-book/edit/" + item.visitorId);
  };

  return (
    <div className="h-full">
      <div className="p-5 sm:pl-8 sm:pt-7 sm:pb-[23px]">
        <p className="text-xl sm:text-2xl md:leading-6 text-[#404040] font-bold">
          Visitor's Book
        </p>
      </div>
      <div className="bg-[#F7F8F9] sm:pt-[18px] p-5 sm:pl-7 sm:pr-11 sm:pb-9">
        <div className="bg-white w-full h-full shadow-[0px_9px_20px] shadow-[#2E235E1A] rounded-[15px]">
          <div className="flex flex-col sm:flex-row sm:items-center justify-between p-3 sm:p-7 border-b ">
            <p className="text-base md:text-xl leading-6 text-[#404040] font-bold">
              Visitor ( Registered )
            </p>
            <div>
              <Button
                buttonStyle="mt-3 sm:mt-0"
                text="New Visit Registration"
                onClick={() => {
                  navigate("/visitors-book/add");
                }}
              />
            </div>
          </div>
          <div className="p-3 sm:p-7 sm:pt-5">
            <TableWithSearchAndPagination
              headings={headings}
              dataToShow={dataToShow}
              data={visitorsList}
              totalData={totalData}
              totalPages={totalPages}
              actions={["delete", "edit"]}
              setCallbackAttributes={setCallbackAttributes}
              handleActionDelete={handleDelete}
              handleActionEdit={handleEdit}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default WithAuth(WithHeaderSidebar(VisitorsBook));
