import axiosInterceptors from "../../axiosInterceptor/axiosInterceptor";
import { opdReportUrl } from "../../config/apiConfig";


export const getReport = (data) => {
    return axiosInterceptors()
        .get(`${opdReportUrl}/getReport?${data}`)
        .then(res=>res)
}

export const getReportByDoctorId = (data) => {
    return axiosInterceptors()
        .get(`${opdReportUrl}/getReportByDoctorId?${data}`)
        .then(res=>res)
}

export const getOpdReport = (data, payload) => {
    return axiosInterceptors()
        .post(`${opdReportUrl}/getOpdReport?${data}`, payload)
        .then(res=>res)
}
