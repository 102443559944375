import React, { createRef, useRef, useState } from "react";
import TextInput from "../../components/textInput";
import Button from "../../components/button";
import { useLocation, useNavigate } from "react-router-dom";
import { resetPassword } from "../../services/auth";

const validations = {
  password: [
    {
      type: "required",
      message: "Please enter new password",
    },
  ],
  confirmPassword: [
    {
      type: "required",
      message: "Please enter confirm password",
    },
  ],
};

const ResetPassword = () => {
  const [error, setError] = useState()

  const navigate = useNavigate();
  const location = useLocation()
  const { userId } = location.state || {}
  const inputRefs = useRef([createRef(), createRef()]);
  const [info, setInfo] = useState({
    password: "",
    confirmPassword: "",
  });

  const handleChange = (name, value) => {
    setInfo((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  const handleSubmit = () => {
    setError("")
    let isValid = true;
    for (let i = 0; i < inputRefs.current.length; i++) {
      const valid = inputRefs.current[i].current.validate();
      if (!valid) {
        isValid = false;
      }
    }
    if (info.password !== info.confirmPassword) {
      inputRefs.current[1].current.setError("Password and Confirm password should match.")
      isValid = false
    }
    if (!isValid) {
      return;
    }
    const payload = {
      userId,
      password: info.password
    }
    resetPassword(payload).then(res => {
      if (res.status === 200) {
        navigate("/");
      } else {
        setError(res?.message)
      }
    })
  };

  return (
    <div
      className="flex flex-col md:flex-row min-h-screen h-screen w-screen"
      style={{
        backgroundImage: "url(/images/auth_screens_Background.png)",
        backgroundSize: "cover",
      }}
    >
      <div className="md:flex items-center justify-center md:w-[50%] bg-white opacity-90 hidden">
        <img
          src="/images/Pulse_Logo_Auth_Screens.png"
          className="w-[192px] h-[232px]"
          alt="logo"
        />
      </div>
      <div className="flex flex-col md:w-[50%] h-screen bg-[#f3f3ffe6] items-center sm:justify-center p-5 md:px-10 lg:px-28">
        <img
          src="/images/Pulse_Logo_Auth_Screens.png"
          className="w-[132px] h-[160px] m-5 md:hidden"
          alt="logo"
        />
        <div className="flex flex-col bg-white p-8 sm:px-10 sm:pt-[71px] sm:pb-[75px] w-full rounded-[10px] shadow-2xl">
          <p className="text-2xl leading-7 text-[#000000] font-bold">
            Reset Password
          </p>
          <TextInput
            label="New Password"
            placeholder="******"
            inputContainerStyle="bg-[#F3F3FF] border-[0px]"
            containerStyle="mt-[64px]"
            name="password"
            type="password"
            value={info.password}
            onChange={handleChange}
            ref={inputRefs.current[0]}
            validations={validations.password}
          />
          <TextInput
            label="Confirm Password"
            placeholder="******"
            inputContainerStyle="bg-[#F3F3FF] border-[0px]"
            name="confirmPassword"
            type="confirmPassword"
            value={info.confirmPassword}
            onChange={handleChange}
            ref={inputRefs.current[1]}
            validations={validations.confirmPassword}
          />
          <div className="flex flex-row">
            <input type="checkbox" />
            <p className="text-[#404040] text-sm leading-[17px] font-bold ml-2">
              Remember Password
            </p>
          </div>
          {error ? <span className="text-sm text-red-500 mt-2">{error}</span> : null}
          <Button
            text="Reset Password"
            buttonStyle="py-[9px] mt-[18px] border border-[#B9C1CC] rounded-[3px]"
            onClick={handleSubmit}
          />
          <div className="h-[1px] w-full bg-[#707070] mt-[68px]" />
          <p className="text-center text-[#404040] text-sm leading-[17px] mt-[13px]">
            Already have an account?{" "}
            <span
              className="text-[#00ADEE] cursor-pointer"
              onClick={() => {
                navigate("/");
              }}
            >
              Sign in
            </span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
