import React from 'react';

const Modal = ({ isVisible, onClose, children }) => {
  if (!isVisible) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center transition-colors z-50">
      <div className="relative bg-white rounded-lg shadow-lg max-w-md w-full p-6">
          <button
            className="absolute top-2 right-4 rounded-lg text-gray-500 hover:text-gray-700"
            onClick={onClose}
          >
            &times;
          </button>
        <div>{children}</div>
      </div>
    </div>
  );
};

export default Modal;
