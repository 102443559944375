import { jwtDecode } from "jwt-decode";
import { useEffect } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { resetUser } from "../actions/userActions";

const WithAuth = (Component) => {
  function Wrapper(componentProps) {
    const navigate = useNavigate();
    const { userPermissions, userDetails, module, permission } = componentProps;
    useEffect(() => {
      const token = sessionStorage.getItem("token");
      if (!token) {
        navigate("/");
        return;
      }
      const { exp } = jwtDecode(token);
      const isAccessTokenExpired = Date.now() / 1000 > exp;
      if (isAccessTokenExpired) {
        sessionStorage.removeItem("token");
        navigate("/");
        return;
      }
      if (
        userDetails?.roleName !== "Owner" &&
        module &&
        permission &&
        !userPermissions?.[module]?.includes(permission)
      ) {
        navigate("/not-granted");
        return;
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <Component {...componentProps} />;
  }

  const mapStateToProps = (state) => {
    return {
      userPermissions: state.user.permission,
      userDetails: state.user.userDetails,
    };
  };
  const mapDispatchToProps = (dispatch) => {
    return {
      resetUser: () => dispatch(resetUser()),
    };
  };

  return connect(mapStateToProps, mapDispatchToProps)(Wrapper);
};

export default WithAuth;
