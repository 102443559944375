import axiosInterceptors from "../axiosInterceptor/axiosInterceptor";
import { phoneCallUrl } from "../config/apiConfig";

export const addNewPhoneCallEntry = (data) => {
  return axiosInterceptors()
    .post(phoneCallUrl + "/addNewPhoneCallEntry", data)
    .then((res) => res);
};

export const getPhoneCallList = (data) => {
  return axiosInterceptors()
    .get(phoneCallUrl + "/getPhoneCallListPage?", data)
    .then((res) => res);
};

export const getPhoneCallById = (id) => {
  return axiosInterceptors()
    .get(phoneCallUrl + "/getPhoneCallById/" + id)
    .then((res) => res);
};

export const deletePhoneCallLogById = (id) => {
  return axiosInterceptors()
    .delete(phoneCallUrl + "/deletePhoneCallLogById/" + id)
    .then((res) => res);
};
