import axiosInterceptors from "../axiosInterceptor/axiosInterceptor";
import { userUrl } from "../config/apiConfig";

export const createAccount = (data) => {
  return axiosInterceptors()
    .post(userUrl + "/createNewAccount", data)
    .then((res) => res);
};

export const verifyOtp = (data) => {
  return axiosInterceptors()
    .get(userUrl + "/validateOtp?" + data)
    .then((res) => res);
};

export const resendOtp = (data) => {
  return axiosInterceptors()
    .post(userUrl + "/resendOtp", data)
    .then((res) => res);
};

export const registerSecurityQuestions = (data) => {
  return axiosInterceptors()
    .post(userUrl + "/registerSecurityQuestions", data)
    .then((res) => res);
};

export const userLogin = (data) => {
  return axiosInterceptors()
    .post(userUrl + "/userLogin", data)
    .then((res) => res);
};

export const verifyEmail = (data) => {
  return axiosInterceptors()
    .get(userUrl + "/verifyEmail?" + data)
    .then((res) => res);
};

export const resetPassword = (data) => {
  return axiosInterceptors()
    .post(userUrl + "/resetPassword", data)
    .then((res) => res);
};

export const resetPasswordWithSecurityQuestions = (data) => {
  return axiosInterceptors()
    .post(userUrl + "/resetPasswordWithSecurityQuestions", data)
    .then((res) => res);
};
