import React, { useCallback, useEffect, useState } from "react";
import WithHeaderSidebar from "../../layout/withHeaderSidebar";
import Button from "../../components/button";
import TableWithSearchAndPagination from "../../components/tableWithSearchAndPagination";
import RadioGroup from "../../components/radioGroup";
import { useNavigate } from "react-router-dom";
import { deletePostalById, getPostalList } from "../../services/postal";
import dayjs from "dayjs";
import WithAuth from "../../layout/withAuth";

const headings = [
  {
    label: "Post No.",
    width: "10%",
    align: "left",
  },
  {
    label: "From",
    width: "15%",
    align: "left",
  },
  {
    label: "Ref. No.",
    width: "10%",
    align: "left",
  },
  {
    label: "Address",
    width: "15%",
    align: "left",
  },
  {
    label: "To",
    width: "15%",
    align: "left",
  },
  {
    label: "Date",
    width: "10%",
    align: "left",
  },
  {
    label: "Note",
    width: "10%",
    align: "left",
  },
  {
    label: "Actions",
    width: "15%",
    align: "center",
  },
];

const dataToShow = [
  {
    key: "postalId",
    align: "left",
  },
  {
    key: "postalFrom",
    align: "left",
  },
  {
    key: "referenceNumber",
    align: "left",
  },
  {
    key: "toAddress",
    align: "left",
  },
  {
    key: "postalTo",
    align: "left",
  },
  {
    key: "date",
    align: "left",
  },
  {
    key: "remark",
    align: "left",
  },
];

const options = [
  {
    label: "Received",
    value: "received",
  },
  {
    label: "Dispatch",
    value: "dispatch",
  },
];

const Postal = (props) => {
  const navigate = useNavigate();
  const [typeSelected, setTypeSelected] = useState("received");
  const [totalData, setTotalData] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [callbackAttributes, setCallbackAttributes] = useState({
    searchSend: "",
    perPage: "25",
    selectedPage: "0",
    sort: "postalId,asc",
    isReceived: true,
  });
  const [postalList, setPostalList] = useState([]);

  const displayPostalList = useCallback(() => {
    setPostalList([]);
    const { searchSend, selectedPage, perPage, sort, isReceived } =
      callbackAttributes;
    let data = `searchKey=${searchSend}&page=${selectedPage}&size=${perPage}&sort=${sort}&isReceived=${isReceived}`;
    getPostalList(data).then((res) => {
      if (res?.status === 200) {
        const postalList = res?.result?.postalListPage?.map((item) => {
          return {
            ...item,
            date: dayjs(item.date).format("DD/MM/YYYY"),
          };
        });
        setPostalList(postalList || []);
        setTotalData(res?.result?.getTotalRecords || 0);
        setTotalPages(res?.result?.getTotalPages || 0);
      } else {
        // props?.setSnackbar({
        //     showSnackbar: true,
        //     message: res?.message,
        //     variant: "error"
        // })
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callbackAttributes]);

  useEffect(() => {
    displayPostalList();
  }, [displayPostalList, callbackAttributes]);

  const handleDelete = (item) => {
    deletePostalById(item.postalId).then((res) => {
      if (res.status === 200) {
        displayPostalList();
      }
    });
  };

  const handleEdit = (item) => {
    navigate("/postal/edit/" + item.postalId);
  };

  return (
    <div className="flex flex-col">
      <div className="p-5 sm:pl-8 sm:pt-[17px] sm:pb-[23px]">
        <p className="text-xl md:text-2xl md:leading-6 text-[#404040] font-bold">
          Postal
        </p>
      </div>
      <div className="flex bg-[#F7F8F9] sm:pt-[18px] p-5 sm:pl-7 sm:pr-11 sm:pb-9">
        <div className="bg-white w-full shadow-[0px_9px_20px] shadow-[#2E235E1A] rounded-[15px] h-full">
          <div className="flex flex-col md:flex-row md:items-center justify-between p-3 md:p-7 border-b">
            <div className="flex flex-row items-center justify-between">
              <p className="text-base md:text-xl leading-6 text-[#404040] font-bold">
                Postal List
              </p>
              <RadioGroup
                className="ml-4 sm:ml-8 md:ml-14"
                options={options}
                value={typeSelected}
                optionsContainerStyle="flex gap-2 md:gap-5"
                onChange={(name, value) => {
                  setTypeSelected(value);
                  setCallbackAttributes((prev) => {
                    return {
                      ...prev,
                      isReceived: value === "received" ? true : false,
                    };
                  });
                }}
              />
            </div>
            <div className="flex gap-4 justify-center mt-3 md:mt-0">
              <Button
                text="New Receive"
                onClick={() => {
                  navigate("/postal/add", {
                    state: { flow: "addReceive" },
                  });
                }}
              />
              <Button
                text="New Dispatch"
                onClick={() => {
                  navigate("/postal/add", {
                    state: { flow: "addDispatch" },
                  });
                }}
              />
            </div>
          </div>
          <div className="p-3 sm:p-7 sm:pt-5">
            <TableWithSearchAndPagination
              headings={headings}
              dataToShow={dataToShow}
              data={postalList}
              totalData={totalData}
              totalPages={totalPages}
              actions={["delete", "edit"]}
              setCallbackAttributes={setCallbackAttributes}
              handleActionDelete={handleDelete}
              handleActionEdit={handleEdit}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default WithAuth(WithHeaderSidebar(Postal));
