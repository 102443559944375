import axiosInterceptors from "../axiosInterceptor/axiosInterceptor";
import { patientOpdLifeCycleUrl } from "../config/apiConfig";

export const createPatientOpdLifeCycle = (data) => {
    return axiosInterceptors()
        .post(`${patientOpdLifeCycleUrl}/createPatientOpdLifeCycle`, data)
        .then((res) => res);
};

export const updatePatientOpdLifeCycleById = (polId, data) => {
    return axiosInterceptors()
        .put(`${patientOpdLifeCycleUrl}/updatePatientOpdLifeCycleById/${polId}`, data)
        .then((res) => res);
};

export const getPatientHistory = (patientId) => {
    return axiosInterceptors()
        .get(`${patientOpdLifeCycleUrl}/getPatientHistory/${patientId}`)
        .then((res) => res);
    }
    
export const checkEditablePatientOpdLifeCycle = (patientId, appointmentId) => {
    return axiosInterceptors()
        .get(`${patientOpdLifeCycleUrl}/checkEditablePatientOpdLifeCycle/${patientId}/${appointmentId}`)
        .then((res) => res);
        
}
