import React, { useEffect, useMemo, useState } from "react";
import { useDropzone } from "react-dropzone";
import { ReactComponent as User } from "../assets/svgs/user-alt.svg"
import { uploadFile } from "../services/common";

const activeStyle = {
  borderColor: "#2196f3",
};
const acceptStyle = {
  borderColor: "#00e676",
};
const rejectStyle = {
  borderColor: "#ff1744",
};

// function getBase64(file, cb) {
//   let reader = new FileReader();
//   reader.readAsDataURL(file);
//   reader.onload = function () {
//     cb(reader.result);
//   };
//   reader.onerror = function (error) {
//   };
// }

const ImageUpload = ({
  className = "",
  onImageUpload = () => { },
  image = null,
  userId,
  userType = "",
  label = "Click to Upload Photo",
  disabled = false,
  fileType="profilePhoto"
}) => {
  const [acceptedImage, setAcceptedImage] = useState({});
  useEffect(() => {
    setAcceptedImage(image);
  }, [image]);
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    noClick: false,
    noKeyboard: true,
    multiple: true,
    maxFiles: 1,
    onDrop: (acceptedFiles) => {
      acceptedFiles.forEach((file) => {
        handleSaveFile(file);
      });
    },
  });
  const handleSaveFile = async (file) => {
    let formData = new FormData()
    formData.append('file', file)
    formData.append("userType", userType)
    formData.append("fileType", fileType)
    if (userId !== "0") {
      formData.append("id", userId)
    }
    uploadFile(formData).then(res => {
      if (res.status === 200) {
        const { imageName, imagePath } = res?.result;
        onImageUpload(imagePath, imageName);
        setAcceptedImage(imagePath);
      }
    })
  };
  const style = useMemo(
    () => ({
      // ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragAccept, isDragReject]
  );
  return (
    <div {...getRootProps({ style })} className={`w-full h-full ${className} ${disabled ? "pointer-events-none" : ""}`}>
      <div className="flex flex-col w-full h-full border rounded-[5px] border-[#CED4DA] justify-between">
        {acceptedImage ?
          typeof acceptedImage === "string" && acceptedImage.split(".")[acceptedImage.split(".").length-1] === "pdf" ?
            <div className="w-full h-full flex justify-center items-center">
              <a href={acceptedImage}>
                <i className="far fa-file-pdf fa-10x"></i>
              </a>
            </div>
          :
            <img src={acceptedImage} className="h-[200px]" alt="person" />
        : (
          <div className="flex justify-center items-center h-full w-full">
            <User height={94} width={82} fill="#CED4DA" />
          </div>
        )}
        <div className="flex items-center justify-center rounded-[5px] bg-[#C5C5C5] py-[6px]">
          <p className="text-center text-sm leading-[21px] text-[#404040]">
            {label}
          </p>
        </div>
      </div>
      <input {...getInputProps()} />
    </div>
  );
};

export default ImageUpload;
