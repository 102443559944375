import React, { createRef, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import TextInput from "../../../components/textInput";
import Button from "../../../components/button";
import Dropdown from "../../../components/dropdown";
import Modal from "../../../components/modal";
import SuccessBtn from "../../../components/successBtn";
import { updatePatientById } from "../../../services/patient";

const validations = {
    relationName: [
        {
            type: "required",
            message: "Please enter relative's name",
        }
    ],
    relationWithPatient: [
        {
            type: "required",
            message: "Please select relation with patient",
        },
    ],
    primaryEmergencyContact: [
        {
            type: "required",
            message: "Please enter contact number",
        },
    ],
    secondaryEmergencyContact: [
        {
            type: "required",
            message: "Please enter contact number",
        },
    ]
};

const relationOptions = [
    {
        label: "Father",
        value: "Father"
    },
    {
        label: "Mother",
        value: "Mother"
    },
    {
        label: "Brother",
        value: "Brother"
    },
    {
        label: "Sister",
        value: "Sister"
    },
    {
        label: "Spouse",
        value: "Spouse"
    },
    {
        label: "Friend",
        value: "Friend"
    },
    {
        label: "Other",
        value: "Other"
    }
]

const EmergencyContact = (props) => {
    const navigate = useNavigate();
    const inputRefs = useRef([
        createRef(),
        createRef(),
        createRef(),
        createRef()
    ]);
    const [info, setInfo] = useState({
        relationName: "",
        relationWithPatient: "",
        primaryEmergencyContact: "",
        secondaryEmergencyContact: "",
        relativeHistoryShare: 0,
    })
    const [isModalVisible, setModalVisible] = useState(false);
    const openModal = () => {
        setModalVisible(true)
    };
    const closeModal = () => setModalVisible(false);

    useEffect(() => {
        if (props.info) {
            setInfo({
                relationName: props.info.relationName,
                relationWithPatient: props.info.relationWithPatient,
                primaryEmergencyContact: props.info.primaryEmergencyContact,
                secondaryEmergencyContact: props.info.secondaryEmergencyContact,
                relativeHistoryShare: props.info.relativeHistoryShare
            })
        }
    }, [props.info])

    const handleChange = (name, value) => {
        setInfo((prev) => {
            return {
                ...prev,
                [name]: value,
            };
        });
    };
    const handleNextClick = () => {
        let isValid = true;
        for (let i = 0; i < inputRefs.current.length; i++) {
            const valid = inputRefs.current[i].current.validate();
            if (!valid) {
                isValid = false;
            }
        }
        if (!isValid) {
            return;
        }
        props.saveInfo(info)
        props.goToStep(4)
    }
    const handleBackClick = () => {
        props.goToStep(2)
    }
    const handleSave = () => {
        let isValid = true;
        for (let i = 0; i < inputRefs.current.length; i++) {
            const valid = inputRefs.current[i].current.validate();
            if (!valid) {
                isValid = false;
            }
        }
        if (!isValid) {
            return;
        }
        props.saveInfo(info)
        const payload = { ...props.patientRegInfo, ...info }
        updatePatientById(props.patientRegInfo?.patientId, payload).then((res) => {
            if (res.status === 200) {
                openModal();
                // navigate("/patients/manage");
            }
        })
    }
    return (
        <>
            <Modal
                isVisible={isModalVisible}
                onClose={closeModal}
            >
                <div className="flex justify-center mb-4">
                    <i className="far fa-check-circle fa-2x text-[#118C42] "></i>
                </div>
                <p className="text-center mb-4">Patient Edited Successfully.</p>
                <div className="flex justify-center">
                    <SuccessBtn
                        text="DONE"
                        onClick={() => {
                            closeModal();
                            navigate("/patients/manage");
                        }}
                    />
                </div>
            </Modal>
            <div className="p-5 lg:px-[30px] lg:pb-9 lg:pt-6">
                <div className="grid grid-cols-1 md:grid-cols-3 md:gap-4 lg:gap-6 xl:gap-9">
                    <TextInput
                        label="Relative's Name"
                        placeholder="Relative's Name"
                        value={info.relationName}
                        ref={inputRefs.current[0]}
                        onChange={handleChange}
                        name="relationName"
                        validations={info.relativeHistoryShare === 1 ? validations.relationName : []}
                    />
                    <Dropdown
                        label="Relation with Patient"
                        placeholder="Select Relation"
                        value={info.relationWithPatient}
                        ref={inputRefs.current[1]}
                        onChange={(value) => {
                            handleChange("relationWithPatient", value.value)
                        }}
                        options={relationOptions}
                        validations={info.relativeHistoryShare === 1 ? validations.relationWithPatient : []}
                    />
                </div>
                <div className="grid grid-cols-1 md:grid-cols-3 md:gap-4 lg:gap-6 xl:gap-9">
                    <TextInput
                        label="Contact Number 1"
                        placeholder="+91 ----- -----"
                        value={info.primaryEmergencyContact}
                        ref={inputRefs.current[2]}
                        onChange={handleChange}
                        name="primaryEmergencyContact"
                        validations={info.relativeHistoryShare === 1 ? validations.primaryEmergencyContact : []}
                    />
                    <TextInput
                        label="Contact Number 2"
                        placeholder="+91 ----- -----"
                        value={info.secondaryEmergencyContact}
                        ref={inputRefs.current[3]}
                        onChange={handleChange}
                        name="secondaryEmergencyContact"
                    />
                </div>
                <div className="flex itemss-center">
                    <input
                        id="shareInfo"
                        type="checkbox"
                        className="h-[24px] w-[24px] rounded-[5px] outline-[#CED4DA]"
                        checked={info.relativeHistoryShare === 1}
                        // value={info.relativeHistoryShare}
                        onChange={(event) => {
                            setInfo((prev) => {
                                if (event.target.checked)
                                    prev.relativeHistoryShare = 1
                                else
                                    prev.relativeHistoryShare = 0
                                return { ...prev }
                            });
                        }}
                    />
                    <label className="ml-2" for="shareInfo">
                        Share Patient's Past / Medical History with Relative of Emergency Contact
                    </label>
                </div>
                <div className="flex items-center justify-between mt-10">
                    <Button text="Previous" textStyle="mx-5 sm:mx-10" onClick={handleBackClick} />
                    {
                        props.patientRegInfo?.patientId !== 0 && props.patientRegInfo?.patientId !== '0' ?
                            <Button text="Save & Next" textStyle="mx-5 sm:mx-10" onClick={handleSave} />
                            : <Button text="Next" textStyle="mx-5 sm:mx-10" onClick={handleNextClick} />
                    }
                </div>
            </div>
        </>
    )
}

export default EmergencyContact