import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import Select from "react-select";

const Dropdown = (
  {
    label = "",
    labelStyle = "",
    options = [],
    placeholder = "",
    className = "",
    onChange = () => {},
    value,
    validations = [],
    controlStyle = {},
    adjustableWidth = false
  },
  ref
) => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [error, setError] = useState("");
  useImperativeHandle(ref, () => {
    return {
      validate: () => validate(),
      setError: (e) => setError(e),
    };
  });

  const handleChange = (value) => {
    if (value.value) {
      setError(false);
    }
    onChange(value);
  };

  const validate = () => {
    let isValid = true;
    for (let i = 0; i < validations.length; i++) {
      const validation = validations[i];
      if (validation.type === "required") {
        if (!value) {
          setError(validation.message);
          isValid = false;
          break;
        }
      } else if (validation.type === "regex") {
        if (!new RegExp(validation.regex).test(value)) {
          setError(validation.message);
          isValid = false;
          break;
        }
      }
    }
    return isValid;
  };

  useEffect(() => {
    const selectedOptions = options.find((option) => option.value === value);
    if (selectedOptions) {
      setError("");
    }
    setSelectedOption(selectedOptions);
  }, [value, options]);

  return (
    <div className={`flex flex-col w-full ${className}`}>
      {label ? (
        <p
          className={`text-sm leading-[21px] text-[#404040] mb-2 ${labelStyle}`}
        >
          {label}
        </p>
      ) : null}
      <Select
        options={options}
        components={{ IndicatorSeparator: null }}
        placeholder={placeholder}
        value={selectedOption}
        closeMenuOnSelect={true}
        onChange={(value) => {
          handleChange(value);
        }}
        styles={{
          dropdownIndicator: (base) => ({
            ...base,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "#E9ECEF",
            height: "100%",
            borderLeftWidth: 1,
            borderColor: "rgb(206,212,218)",
            borderRadius: 5
          }),
          placeholder: (base) => ({
            ...base,
            fontSize: "14px",
            lineHeight: "21px",
            color: "#C5C5C5",
            fontWeight: 400,
            marginBottom: 6
          }),
          control: (base) => ({
            ...base,
            borderWidth: 1,
            borderColor: "#CED4DA",
            minWidth: adjustableWidth?160:0,
            width: "100%",
            borderRadius: 5,
            height: 36,
            ...controlStyle,
          }),
          valueContainer: (base) => ({
            ...base,
            alignItems: "center",
            padding: 0,
            paddingLeft: "14px",
            paddingRight: "14px",
            paddingTop: "6px",
            paddingBottom: "6px",
          }),
          indicatorsContainer: (base) => ({
            ...base,
            height: 36
          }),
          singleValue: (base) => ({
            ...base,
            marginBottom: 8
          })
        }}
      />
      {error ? (
        <span className="text-sm text-red-500 mb-5 mt-1">{error}</span>
      ) : (
        <span className="h-5" />
      )}
    </div>
  );
};

export default forwardRef(Dropdown);
