import axiosInterceptors from "../axiosInterceptor/axiosInterceptor";
import { baseURL } from "../config/apiConfig";

export const uploadFile = (data) => {
  return axiosInterceptors()
    .post(baseURL + "/uploadFile", data)
    .then((res) => res);
};

export const getSecurityQuestions = () => {
  return axiosInterceptors()
    .get(baseURL + "/getSecurityQuestions")
    .then((res) => res);
};
