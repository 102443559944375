import { useEffect, useState } from "react";
import PatientInformation from "./patientInformation";
import ContactInformation from "./contactInformation";
import EmergencyContact from "./emergencyContact";
import PaymentInformation from "./paymentInformation";
import InsuranceInfo from "./insuranceInfo";
import FormStepper from "../../../components/formStepper";
import WithAuth from "../../../layout/withAuth";
import WithHeaderSidebar from "../../../layout/withHeaderSidebar";
import { getPatientById, searchPatientByName } from "../../../services/patient";
import { useParams } from "react-router-dom";

const steps = [
  { label: "Patient's Information" },
  { label: "Contact Information" },
  { label: "Emergency Contact" },
  { label: "Insurance Information" },
  { label: "Payment Information" },
];

const PatientRegistration = (props) => {
  const { id } = useParams();
  const [patientRegInfo, setPatientRefInfo] = useState({});
  const [searchKey, setSearchKey] = useState("");
  const [searchList, setSearchList] = useState([]);
  const [patientInfo, setPatientInfo] = useState(null);
  const [insuranceScreen, setInsuranceScreen] = useState(0);

  const handleSaveInfo = (info) => {
    setPatientRefInfo((prev) => {
      return {
        ...prev,
        ...info,
      };
    });
  };

  useEffect(() => {
    if (searchKey) {
      const data = `searchKey=${searchKey}`;
      searchPatientByName(data).then((res) => {
        if (res.status === 200) {
          setSearchList(res?.result?.patientDtos);
        }
      });
    }
  }, [searchKey]);

  useEffect(() => {
    if (id) {
      getPatientById(id).then((res) => {
        if (res.status === 200) {
          setPatientInfo((prev) => {
            return {
              ...prev,
              ...res.result.patientDto,
            };
          });
        }
      });
    } else {
      setPatientInfo(null);
    }
  }, [id]);

  return (
    <div
      className="flex flex-col"
      style={{ /*minHeight: `calc(100vh - ${props.headerHeight}px)` */ }}
    >
      <div className="py-7 px-8">
        <p className="text-xl sm:text-2xl leading-6 text-[#404040] font-bold">
          Patient Registration
        </p>
      </div>
      <div className="flex flex-col lg:flex-row bg-[#F7F8F9] pt-[18px] p-5 sm:px-7 sm:pb-9 gap-[22px]">
        <div className="flex flex-col bg-[#FFFFFF] rounded-[15px] w-full shadow-[0px_9px_20px] shadow-[#2E235E1A]">
          <div className="p-3 lg:px-[30px] border-b">
            <p className="text-base md:text-xl md:leading-6 text-[#404040] font-bold">
              Patient Registration
            </p>
          </div>
          <FormStepper steps={steps}>
            <PatientInformation saveInfo={handleSaveInfo} info={patientInfo} />
            <ContactInformation saveInfo={handleSaveInfo} info={patientInfo} />
            <EmergencyContact 
              saveInfo={handleSaveInfo} 
              info={patientInfo} 
              patientRegInfo={patientRegInfo}
            />
            <InsuranceInfo
              info={patientInfo}
              saveInfo={handleSaveInfo}
              insuranceScreen={insuranceScreen} 
              setInsuranceScreen={setInsuranceScreen}
            />
            <PaymentInformation
              patientRegInfo={patientRegInfo}
              info={patientInfo}
              id={id}
            />
          </FormStepper>
        </div>
      </div>
    </div>
  );
};

export default WithAuth(WithHeaderSidebar(PatientRegistration));
