import React, { useCallback, useEffect, useState } from "react";
import WithHeaderSidebar from "../../layout/withHeaderSidebar";
import Button from "../../components/button";
import TableWithSearchAndPagination from "../../components/tableWithSearchAndPagination";
import { useNavigate } from "react-router-dom";
import WithAuth from "../../layout/withAuth";
import {
  deleteOpdAppointmentById,
  getOpdAppointmentsListPage,
  getOpdAppointmentsListPageByDocId
} from "../../services/opdAppointment";
import dayjs from "dayjs";
import CheckPermission from "../../components/checkPermission";
import DangerBtn from "../../components/dangerBtn";
import SecondaryBtn from "../../components/secondaryBtn";
import { connect } from "react-redux";
import Modal from "../../components/modal";

const headings = [
  {
    label: "Patient ID",
    width: "10%",
    align: "left",
  },
  {
    label: "Appointment No.",
    width: "15%",
    align: "left",
  },
  {
    label: "Patient Name",
    width: "20%",
    align: "left",
  },
  {
    label: "Priority",
    width: "15%",
    align: "left",
  },
  {
    label: "Appointment Date",
    width: "15%",
    align: "left",
  },
  {
    label: "Contact",
    width: "15%",
    align: "left",
  },
  {
    label: "Actions",
    width: "10%",
    align: "center",
  },
];

const dataToShow = [
  {
    key: "patientId",
    align: "left",
  },
  {
    key: "appointmentId",
    align: "left",
  },
  {
    key: "fullName",
    align: "left",
  },
  {
    key: "priority",
    align: "left",
  },
  {
    key: "appointmentDate",
    align: "left",
  },
  {
    key: "primaryContactNumber",
    align: "left",
  },
];

const OpdAppointment = ({ doctorId }) => {
  const navigate = useNavigate();
  const [totalData, setTotalData] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [callbackAttributes, setCallbackAttributes] = useState({
    searchSend: "",
    perPage: "25",
    selectedPage: "0",
    sort: "appointmentId,desc",
  });
  const [appointmentList, setAppointmentList] = useState([]);
  const [isModalVisible, setModalVisible] = useState(false);
  const [selectedAppointmentId, setSelectedAppoinemtnId] = useState(0);
  const openModal = (aptId) => {
    setSelectedAppoinemtnId(aptId);
    setModalVisible(true)
  };
  const closeModal = () => setModalVisible(false);

  const displayAppointmentList = useCallback(() => {
    setAppointmentList([]);
    const { searchSend, selectedPage, perPage, sort } = callbackAttributes;
    let data = doctorId === 0 ? `searchKey=${searchSend}&page=${selectedPage}&size=${perPage}&sort=${sort}` : `docId=${doctorId}&searchKey=${searchSend}&page=${selectedPage}&size=${perPage}&sort=${sort}`;
    if (doctorId === 0) {
      getOpdAppointmentsListPage(data).then((res) => {
        if (res?.status === 200) {
          const appointmentList = res?.result?.opdAppointmentListPage?.map(
            (item) => {
              return {
                ...item,
                priority: (
                  <p
                    className={`${item.priority === "Very Urgent"
                      ? "text-[#FF0000]"
                      : item.priority === "Normal"
                        ? "text-[#00ADEE]"
                        : "text-[#80509F]"
                      }`}
                  >
                    {item.priority}
                  </p>
                ),
                appointmentDate: dayjs(item.appointmentDate).format("DD/MM/YYYY"),
              };
            }
          );
          setAppointmentList(appointmentList || []);
          setTotalData(res?.result?.getTotalRecords || 0);
          setTotalPages(res?.result?.getTotalPages || 0);
        } else {
          // props?.setSnackbar({
          //     showSnackbar: true,
          //     message: res?.message,
          //     variant: "error"
          // })
        }
      });
    } else {
      getOpdAppointmentsListPageByDocId(data).then((res) => {
        if (res?.status === 200) {
          const appointmentList = res?.result?.opdAppointmentListPage?.map(
            (item) => {
              return {
                ...item,
                priority: (
                  <p
                    className={`${item.priority === "Very Urgent"
                      ? "text-[#FF0000]"
                      : item.priority === "Normal"
                        ? "text-[#00ADEE]"
                        : "text-[#80509F]"
                      }`}
                  >
                    {item.priority}
                  </p>
                ),
                appointmentDate: dayjs(item.appointmentDate).format("DD/MM/YYYY"),
              };
            }
          );
          setAppointmentList(appointmentList || []);
          setTotalData(res?.result?.getTotalRecords || 0);
          setTotalPages(res?.result?.getTotalPages || 0);
        } else {
          // props?.setSnackbar({
          //     showSnackbar: true,
          //     message: res?.message,
          //     variant: "error"
          // })
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callbackAttributes]);

  useEffect(() => {
    displayAppointmentList();
  }, [displayAppointmentList, callbackAttributes]);

  const handleDelete = (item) => {
    openModal(item.appointmentId);
    // deleteOpdAppointmentById(item.appointmentId).then((res) => {
    //   if (res.status === 200) {
    //     displayAppointmentList();
    //   }
    // });
  };

  const handleEdit = (item) => {
    navigate("/opd-appointment/edit/" + item.appointmentId);
  };
  return (
    <div className="h-full">
      <Modal
        isVisible={isModalVisible}
        onClose={closeModal}
      >
        <div className="flex justify-center mb-4">
          <i className="far fa-times-circle fa-3x text-[#D42A46] "></i>
        </div>
        <p className="text-center mb-4">Are you sure, you want to delete OPD ?</p>
        <div className="flex justify-around">
          <DangerBtn
            text="DELETE"
            onClick={() => {
              if (selectedAppointmentId !== 0) {
                deleteOpdAppointmentById(selectedAppointmentId).then((res) => {
                  if (res.status === 200) {
                    closeModal();
                    displayAppointmentList();
                  }
                });
              } else {
                closeModal();
              }
            }}
          />
          <SecondaryBtn
            text="CANCEL"
            onClick={closeModal}
          />
        </div>
      </Modal>
      <div className="pl-8 py-7">
        <p className="text-2xl leading-6 text-[#404040] font-bold">
          OPD Appointment
        </p>
      </div>
      <div className="bg-[#F7F8F9] sm:pt-[18px] p-5 sm:pl-7 sm:pr-11 sm:pb-9">
        <div className="bg-white w-full h-full shadow-[0px_9px_20px] shadow-[#2E235E1A] rounded-[15px]">
          <div className="flex flex-col sm:flex-row sm:items-center justify-between p-3 sm:p-7 border-b ">
            <p className="text-base md:text-xl leading-6 text-[#404040] font-bold">
              OPD Appointment ( Registered )
            </p>
            <CheckPermission module="OPD Appointment" permission="Add">
              <Button
                buttonStyle="mt-3 sm:mt-0"
                text="New Appointment"
                onClick={() => {
                  navigate("/opd-appointment/add");
                }}
              />
            </CheckPermission>
          </div>
          <div className="p-3 sm:p-7 sm:pt-5">
            <TableWithSearchAndPagination
              headings={headings}
              dataToShow={dataToShow}
              data={appointmentList}
              totalData={totalData}
              totalPages={totalPages}
              actions={["delete", "edit"]}
              setCallbackAttributes={setCallbackAttributes}
              handleActionDelete={handleDelete}
              handleActionEdit={handleEdit}
              module="OPD Appointment"
              actionPermission={{
                edit: "Edit",
                delete: "Delete",
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    doctorId: state.user.doctorId,
  };
};

export default WithAuth(
  WithHeaderSidebar(connect(mapStateToProps, null)(OpdAppointment))
);
