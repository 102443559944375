import React, { useState, useEffect, createRef } from "react";
import ReactDatePicker from "react-datepicker";
import { useNavigate } from "react-router-dom";
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import Modal from "../../components/modal";
import TextInput from "../../components/textInput";
import AddOnTextBox from "../../components/addOnTextBox";
import Button from "../../components/button";
import SuccessBtn from "../../components/successBtn";
import BlinkIcon from "../../components/blinkIcon";
const dayjs = require("dayjs").extend(require("dayjs/plugin/advancedFormat"))


export const Accordion = ({ doctorInfo, patientHistory }) => {
    const [activeIndex, setActiveIndex] = useState(0); // Set the initial state to 0
    const toggleAccordion = (index) => {
        setActiveIndex(index === activeIndex ? null : index);
    };
    return (
        <div className="divide-y divide-gray-200">
            {patientHistory.map((item, index) => (
                <div key={index}>
                    <button
                        className="w-full text-left focus:outline-none"
                        onClick={() => toggleAccordion(index)}
                    >
                        <div className="flex items-center gap-4 px-4 py-2 bg-[#2E235E1A] text-sm">
                            {
                                activeIndex === index ? <i className="fas fa-chevron-down"></i> : <i className="fas fa-chevron-right"></i>

                            }
                            <span className="font-[800] text-black">{dayjs(item.createdOn).format("Do MMMM YYYY")}</span><span> by Dr. {doctorInfo?.docName}</span>
                        </div>
                    </button>
                    {activeIndex === index && (
                        <div>
                            <div className="mt-1 px-2 py-2">
                                <p className="font-[800] text-sm mb-1">Vitals :</p>
                                <p className="text-sm">
                                    Temprature: {item.vitals.temprature}
                                    &nbsp;|&nbsp;
                                    Height:  {item.vitals.height}
                                    &nbsp;|&nbsp;
                                    Weight: {item.vitals.weight}
                                    &nbsp;|&nbsp;
                                    Pulse: {item.vitals.pulse}
                                    &nbsp;|&nbsp;
                                    Head Circum: {item.vitals.headCircum}
                                    &nbsp;|&nbsp;
                                    SPO2: {item.vitals.spo2}
                                    &nbsp;|&nbsp;
                                    BMI: {item.vitals.bmi}
                                </p>
                            </div>
                            <div className="mt-1 px-2 py-2">
                                <p className="font-[800] text-sm mb-1">Chief Complaints :</p>
                                <p className="text-sm">
                                    {item.complaints}
                                </p>
                            </div>
                            <div className="mt-1 px-2 py-2">
                                <p className="font-[800] text-sm mb-1">Diagnosis :</p>
                                <p className="text-sm">
                                    {item.diagnosis}
                                </p>
                            </div>
                            <div className="mt-1 px-2 py-2">
                                <p className="font-[800] text-sm mb-1">Doctor's Notes :</p>
                                <p className="text-sm">
                                    {item.notes}
                                </p>
                            </div>
                            <div className="mt-1 px-2 py-2">
                                <p className="font-[800] text-sm mb-1">Rx :</p>
                                <div className="table-content-wrapper">
                                    <table striped="true" className="w-full">
                                        <thead>
                                            <tr>
                                                <th align="left" style={{ width: "20%" }}>Medicine</th>
                                                <th align="left" style={{ width: "6%" }}>Form</th>
                                                <th align="left" style={{ width: "10%" }}>Dose</th>
                                                <th align="left" style={{ width: "12%" }}>Timing</th>
                                                <th align="left" style={{ width: "10%" }}>Frequency</th>
                                                <th align="left" style={{ width: "10%" }}>Duration</th>
                                                <th align="left" style={{ width: "10%" }}>Qty</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                item.rx.length > 0 ?
                                                    item.rx.map((r, k) => {
                                                        return (
                                                            <tr key={k}>
                                                                <td>{r.medicine}</td>
                                                                <td>{r.form}</td>
                                                                <td>{r.dose}</td>
                                                                <td>{r.timing}</td>
                                                                <td>{r.frequency}</td>
                                                                <td>{r.duration}</td>
                                                                <td>{r.qty}</td>
                                                            </tr>
                                                        );
                                                    })
                                                    : null
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            ))}
        </div>
    );
};
export const PatientInfo = ({ patientInfo }) => {
    return (
        <div className="flex flex-col sm:flex-row sm:items-center p-[30px] sm:py-4 sm:pl-[30px]">
            <img src={patientInfo?.profilePicture} alt="" className="w-[50px] h-[50px] border-[1px] border-[#666] rounded-[50%] bg-[#ababab]" />
            <div className="flex flex-col ml-4">
                <p className="text-lg font-[800]">
                    {patientInfo?.firstName} {patientInfo?.middleName} {patientInfo?.lastName}
                </p>
                <p className="text-sm font-[300]">
                    <span>
                        {patientInfo?.age} years
                    </span>
                    <span className="mx-4">
                        |
                    </span>
                    <span>
                        {patientInfo?.gender}
                    </span>
                    <span className="mx-4">
                        |
                    </span>
                    <span>
                        {patientInfo?.primaryContactNumber}
                    </span>
                </p>
            </div>
        </div>
    );
}
export const SeenByHeader = ({ doctorInfo }) => {
    return (
        <div className="bg-[#F5F5F5] text-sm py-1 pl-3 h-[2rem]">
            <span className="font-[600]">({dayjs(new Date()).format("DD/MM/YYYY")})</span> &nbsp; by Dr. {doctorInfo?.docName}
        </div>
    );
}
export const VitalSection = ({ vitalRefs, vitals, setVitals }) => {
    return (
        <div className="mt-2 px-3 py-1">
            <p className="font-[800] text-sm mb-1">Vitals :</p>
            <div className="grid grid-cols-4 gap-3">
                <div className="flex items-center -mb-[8px]">
                    <AddOnTextBox
                        labelText={"Temperature"}
                        labelStyle="text-left"
                        value={vitals.temprature}
                        name="temprature"
                        type="text"
                        addOn={
                            <span className="text-sm text-center font-[500]">&deg;F</span>
                        }
                        onChange={(e) => {
                            setVitals(prev => ({ ...prev, temprature: e.target.value }))
                        }}
                        ref={vitalRefs.current[0]}
                    />
                </div>
                <div className="flex items-center -mb-[8px]">
                    <AddOnTextBox
                        labelText={"Height"}
                        labelStyle="text-right"
                        value={vitals.height}
                        name="height"
                        type="text"
                        addOn={
                            <span className="text-sm text-center font-[500]">cm</span>
                        }
                        onChange={(e) => {
                            setVitals(prev => ({ ...prev, height: e.target.value }))
                        }}
                        ref={vitalRefs.current[1]}
                    />
                </div>
                <div className="flex items-center -mb-[8px]">
                    <AddOnTextBox
                        labelText={"Weight"}
                        labelStyle="text-right"
                        value={vitals.weight}
                        name="weight"
                        type="text"
                        addOn={
                            <span className="text-sm text-center font-[500]">kg</span>
                        }
                        onChange={(e) => {
                            setVitals(prev => ({ ...prev, weight: e.target.value }))
                        }}
                        ref={vitalRefs.current[2]}
                    />
                </div>
                <div className="flex items-center -mb-[8px]">
                    <AddOnTextBox
                        labelText={"Pulse"}
                        labelStyle="text-right"
                        value={vitals.pulse}
                        name="pulse"
                        type="text"
                        addOn={
                            <span className="text-sm text-center font-[500]">bpm</span>
                        }
                        onChange={(e) => {
                            setVitals(prev => ({ ...prev, pulse: e.target.value }))
                        }}
                        ref={vitalRefs.current[3]}
                    />
                </div>
                <div className="flex items-center -mb-[8px]">
                    <AddOnTextBox
                        labelText={"Head Circum"}
                        labelStyle="text-left"
                        value={vitals.headCircum}
                        name="headCircum"
                        type="text"
                        addOn={
                            <span className="text-sm text-center font-[500]">cm</span>
                        }
                        onChange={(e) => {
                            setVitals(prev => ({ ...prev, headCircum: e.target.value }))
                        }}
                        ref={vitalRefs.current[4]}
                    />
                </div>
                <div className="flex items-center -mb-[8px]">
                    <AddOnTextBox
                        labelText={"SPO2"}
                        labelStyle="text-right"
                        value={vitals.spo2}
                        name="spo2"
                        type="text"
                        addOn={
                            <span className="text-sm text-center font-[500]">%</span>
                        }
                        onChange={(e) => {
                            setVitals(prev => ({ ...prev, spo2: e.target.value }))
                        }}
                        ref={vitalRefs.current[5]}
                    />
                </div>
                <div className="flex items-center -mb-[8px]">
                    <AddOnTextBox
                        labelText={"BMI"}
                        labelStyle="text-right"
                        value={vitals.bmi}
                        name="bmi"
                        type="text"
                        addOn={
                            <span className="text-sm text-center font-[500]">kg/m<sup>2</sup></span>
                        }
                        onChange={(e) => {
                            setVitals(prev => ({ ...prev, bmi: e.target.value }))
                        }}
                        ref={vitalRefs.current[6]}
                    />
                </div>
            </div>
        </div>
    );
}
export const ComplaintDiagnosisSection = ({ complainDiagnosisRef, chiefComplaints, setChiefComplaints, diagnosis, setDiagonosis, speech, setSpeech }) => {
    const { transcript, resetTranscript, browserSupportsSpeechRecognition } = useSpeechRecognition();
    useEffect(() => {
        if (speech.complaint) {
            setChiefComplaints(transcript);
        }
        if (speech.diagnosis) {
            setDiagonosis(transcript);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [transcript, speech])
    return (
        <div className="mt-3 px-3 py-1">
            <div className="grid grid-cols-2 gap-6">
                <div>
                    <p className="font-[800] text-sm mb-2">
                        Chief Complaints :
                        {
                            browserSupportsSpeechRecognition &&
                            <BlinkIcon
                                iconClass="far fa-microphone"
                                otherClasses={`ml-2 font-[400] cursor-pointer`}
                                style={{
                                    color: speech.complaint ? "red" : "black"
                                }}
                                duration={5000}
                                onClick={() => {
                                    SpeechRecognition.stopListening();
                                    resetTranscript();
                                    if (speech.complaint === true) {
                                        setSpeech((prev) => {
                                            prev.complaint = false;
                                            return prev;
                                        })
                                    } else {
                                        setTimeout(() => {
                                            SpeechRecognition.startListening({
                                                continuous: true,
                                                language: "en-IN"
                                            });
                                        }, 1000)
                                        setSpeech((prev) => {
                                            prev.complaint = true;
                                            return prev;
                                        })
                                    }
                                    setSpeech((prev) => {
                                        prev.diagnosis = false;
                                        prev.notes = false;
                                        return prev;
                                    })
                                    // setComplaintsSpeech(prev => !prev);
                                }}
                            />
                        }
                    </p>
                    <textarea
                        ref={complainDiagnosisRef.current[0]}
                        className="border w-full rounded-md p-2 focus:outline-none focus:border-blue-500 h-[60px]"
                        onChange={(e) => { setChiefComplaints(e.target.value) }}
                        value={chiefComplaints}
                    >
                    </textarea>
                </div>
                <div>
                    <p className="font-[800] text-sm mb-2">
                        Diagnosis :
                        {
                            browserSupportsSpeechRecognition &&
                            <BlinkIcon
                                iconClass="far fa-microphone"
                                otherClasses={`ml-2 font-[400] cursor-pointer`}
                                duration={5000}
                                style={{
                                    color: speech.diagnosis ? "red" : "black"
                                }}
                                onClick={() => {
                                    SpeechRecognition.stopListening();
                                    resetTranscript();
                                    if (speech.diagnosis === true) {
                                        setSpeech((prev) => {
                                            prev.diagnosis = false;
                                            return prev;
                                        })
                                    } else {
                                        setTimeout(() => {
                                            SpeechRecognition.startListening({
                                                continuous: true,
                                                language: "en-IN"
                                            });
                                        }, 1000)
                                        setSpeech((prev) => {
                                            prev.diagnosis = true;
                                            return prev;
                                        })
                                    }
                                    setSpeech((prev) => {
                                        prev.complaint = false;
                                        prev.notes = false;
                                        return prev;
                                    })
                                }}
                            />
                        }
                    </p>
                    <textarea
                        ref={complainDiagnosisRef.current[1]}
                        className="border w-full rounded-md p-2 focus:outline-none focus:border-blue-500 h-[60px]"
                        onChange={(e) => { setDiagonosis(e.target.value) }}
                        value={diagnosis}
                    >
                    </textarea>
                </div>
            </div>
        </div>
    );
}
export const RxSection = ({ rx, setRx, notes, setNotes, speech, setSpeech }) => {
    const { transcript, resetTranscript, browserSupportsSpeechRecognition } = useSpeechRecognition();

    useEffect(() => {
        if (speech.notes) {
            setNotes(transcript);
        }
        // eslint-disable-next-line
    }, [transcript, speech])
    return (
        <div className="mt-2 px-3 py-1">
            <div className="grid grid-cols-3 gap-4">
                <div className="col-span-2">
                    <p className="font-[800] text-sm mb-1">Rx :</p>
                    <div className="table-content-wrapper2">
                        <table striped="true" className="w-full">
                            <thead>
                                <tr>
                                    <th style={{ width: "20%" }}>Medicine</th>
                                    <th style={{ width: "10%" }}>Form</th>
                                    <th style={{ width: "10%" }}>Dose</th>
                                    <th style={{ width: "20%" }}>Timing</th>
                                    <th style={{ width: "15%" }}>Frequency</th>
                                    <th style={{ width: "5%" }}>Duration</th>
                                    <th style={{ width: "10%" }}>Qty</th>
                                    <th style={{ width: "10%" }}></th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    rx.map((e, i) => {
                                        return (
                                            <tr key={i}>
                                                <td className="py-[4px]">
                                                    <input
                                                        type="text"
                                                        value={e.medicine}
                                                        className="px-1 border-[1px] rounded-md text-[#404040] outline-none w-full"
                                                        onChange={(event) => {
                                                            setRx(prev => {
                                                                prev[i].medicine = event.target.value;
                                                                return [...prev];
                                                            });
                                                        }}
                                                    />
                                                </td>
                                                <td>
                                                    <select
                                                        value={e.timing}
                                                        className="h-[2rem] px-[5px] bg-white outline-none border-[1px] rounded-md text-[#404040]"
                                                        onChange={(event) => {
                                                            setRx(prev => {
                                                                prev[i].form = event.target.value;
                                                                return [...prev];
                                                            });
                                                        }}
                                                    >
                                                        <option value="Tablet">Tablet</option>
                                                        <option value="Syrup">Syrup</option>
                                                    </select>
                                                </td>
                                                <td className="py-[4px]">
                                                    <input
                                                        type="text"
                                                        value={e.dose}
                                                        className="px-1 border-[1px] rounded-md text-[#404040] outline-none w-full"
                                                        onChange={(event) => {
                                                            setRx(prev => {
                                                                prev[i].dose = event.target.value;
                                                                return [...prev];
                                                            });
                                                        }}
                                                    />
                                                </td>
                                                <td className="py-[4px]">
                                                    <select
                                                        value={e.timing}
                                                        className="h-[2rem] px-[5px] bg-white outline-none border-[1px] rounded-md text-[#404040]"
                                                        onChange={(event) => {
                                                            setRx(prev => {
                                                                prev[i].timing = event.target.value;
                                                                return [...prev];
                                                            });
                                                        }}
                                                    >
                                                        <option value="Before Food">Before Food</option>
                                                        <option value="After Food">After Food</option>
                                                    </select>
                                                </td>
                                                <td className="py-[4px]">
                                                    <select
                                                        value={e.frequency}
                                                        className="h-[2rem] px-[5px] bg-white outline-none border-[1px] rounded-md text-[#404040]"
                                                        onChange={(event) => {
                                                            setRx(prev => {
                                                                prev[i].frequency = event.target.value;
                                                                return [...prev];
                                                            });
                                                        }}
                                                    >
                                                        <option value="Daily">Daily</option>
                                                        <option value="Alternate Days">Alternate Days</option>
                                                        <option value="Weekly">Weekly</option>
                                                    </select>
                                                </td>
                                                <td className="py-[4px]">
                                                    <input
                                                        value={e.duration}
                                                        type="text"
                                                        className="px-1 border-[1px] rounded-md text-[#404040] outline-none w-full"
                                                        onChange={(event) => {
                                                            setRx(prev => {
                                                                prev[i].duration = event.target.value;
                                                                return [...prev];
                                                            });
                                                        }}
                                                    />
                                                </td>
                                                <td className="py-[4px]">
                                                    <input
                                                        value={e.qty}
                                                        type="number"
                                                        className="px-1 border-[1px] rounded-md text-[#404040] outline-none w-full"
                                                        onChange={(event) => {
                                                            setRx(prev => {
                                                                prev[i].qty = event.target.value;
                                                                return [...prev];
                                                            });
                                                        }}
                                                    />
                                                </td>
                                                <td>
                                                    <div className="flex gap-3 items-center">
                                                        {
                                                            (rx.length !== 1) ?
                                                                <i
                                                                    className="far fa-trash-alt cursor-pointer text-[#FF0000]"
                                                                    onClick={() => {
                                                                        setRx((prev) => {
                                                                            prev = prev.filter((_, j) => (i !== j));
                                                                            return [...prev];
                                                                        })
                                                                    }}
                                                                /> : null
                                                        }
                                                        {
                                                            (i === rx.length - 1) ?
                                                                <i
                                                                    className="far fa-plus cursor-pointer text-[#80509F]"
                                                                    onClick={() => {
                                                                        setRx((prev) => {
                                                                            return [...prev, { medicine: "", dose: "", timing: "Before Food", frequency: "daily", duration: 0, qty: 0 }];
                                                                        })
                                                                    }}
                                                                /> :
                                                                null
                                                        }
                                                    </div>
                                                </td>
                                            </tr>
                                        );
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="col-span-1">
                    <p className="font-[800] text-sm mb-1">
                        Doctor's Notes :
                        {
                            browserSupportsSpeechRecognition &&
                            <BlinkIcon
                                iconClass="far fa-microphone"
                                duration={5000}
                                otherClasses={`ml-2 font-[400] cursor-pointer`}
                                style={{
                                    color: speech.notes ? "red" : "black"
                                }}
                                onClick={() => {
                                    SpeechRecognition.stopListening();
                                    resetTranscript();
                                    if (speech.notes === true) {
                                        setSpeech((prev) => {
                                            prev.notes = false;
                                            return prev;
                                        })
                                    } else {
                                        setTimeout(() => {
                                            SpeechRecognition.startListening({
                                                continuous: true,
                                                language: "en-IN"
                                            });
                                        }, 1000)
                                        setSpeech((prev) => {
                                            prev.notes = true;
                                            return prev;
                                        })
                                    }
                                    setSpeech((prev) => {
                                        prev.complaint = false;
                                        prev.diagnosis = false;
                                        return prev;
                                    })
                                }}
                            />
                        }
                    </p>
                    <textarea
                        className="border w-full rounded-md p-2 focus:outline-none focus:border-blue-500 h-[122px]"
                        onChange={(e) => { setNotes(e.target.value) }}
                        value={notes}
                    ></textarea>
                </div>
            </div>
        </div>
    );
}
export const IntervalHistorySection = ({ intervalHistory, setIntervalHistory }) => {
    return (
        <div className="mt-2 px-3 py-1">
            <div className="grid grid-cols-1 ">
                <p className="font-[800] text-sm mb-1">Transfer Summry :</p>
                <div className="table-content-wrapper2">
                    <table striped="true" className="w-full">
                        <thead>
                            <tr>
                                <th align="left" style={{ width: "25%" }}>Date</th>
                                <th align="left" style={{ width: "25%" }}>Doctor Name</th>
                                <th align="left" style={{ width: "25%" }}>Reason</th>
                                <th align="left" style={{ width: "20%" }}>Note</th>
                                <th align="left" style={{ width: "5%" }}></th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                intervalHistory.map((e, i) => {
                                    return (
                                        <tr key={i}>
                                            <td>
                                                <div className="flex justify-between text-[#404040] bg-white border border-[#CED4DA] rounded-[5px] w-full">
                                                    <ReactDatePicker
                                                        selected={new Date(e.date)}
                                                        className="outline-none rounded-[5px] py-[6px] px-[14px] bg-transparent h-[2rem] w-full"
                                                        closeOnScroll={true}
                                                        dateFormat="dd/MM/yyyy"
                                                        onChange={(date) => {
                                                            setIntervalHistory(prev => {
                                                                prev[i].date = dayjs(date).format("YYYY-MM-DD HH:mm:ss");
                                                                return [...prev];
                                                            });
                                                        }}
                                                    />
                                                    <div className="flex justify-center items-center p-[9px] bg-[#E9ECEF] rounded-r-[5px] border-l border-[#CED4DA]" >
                                                        <i className="fa-solid fa-calendar-days h-3 w-3"></i>
                                                    </div>
                                                </div>
                                            </td>
                                            <td className="py-[4px]">
                                                <input
                                                    type="text"
                                                    value={e.doctorName}
                                                    className="px-1 border-[1px] rounded-md text-[#404040] outline-none w-full"
                                                    onChange={(event) => {
                                                        setIntervalHistory(prev => {
                                                            prev[i].doctorName = event.target.value;
                                                            return [...prev];
                                                        });
                                                    }}
                                                />
                                            </td>
                                            <td className="py-[4px]">
                                                <input
                                                    type="text"
                                                    value={e.reason}
                                                    className="px-1 border-[1px] rounded-md text-[#404040] outline-none w-full"
                                                    onChange={(event) => {
                                                        setIntervalHistory(prev => {
                                                            prev[i].reason = event.target.value;
                                                            return [...prev];
                                                        });
                                                    }}
                                                />
                                            </td>
                                            <td className="py-[4px]">
                                                <input
                                                    type="text"
                                                    value={e.note}
                                                    className="px-1 border-[1px] rounded-md text-[#404040] outline-none w-full"
                                                    onChange={(event) => {
                                                        setIntervalHistory(prev => {
                                                            prev[i].note = event.target.value;
                                                            return [...prev];
                                                        });
                                                    }}
                                                />
                                            </td>
                                            <td className="py-[4px]">
                                                <div className="flex gap-3 items-center">
                                                    {
                                                        (intervalHistory.length !== 1) ?
                                                            <i
                                                                className="far fa-trash-alt cursor-pointer text-[#FF0000]"
                                                                onClick={() => {
                                                                    setIntervalHistory((prev) => {
                                                                        prev = prev.filter((_, j) => (i !== j));
                                                                        return [...prev];
                                                                    })
                                                                }}
                                                            /> : null
                                                    }
                                                    {
                                                        (i === intervalHistory.length - 1) ?
                                                            <i
                                                                className="far fa-plus cursor-pointer text-[#80509F]"
                                                                onClick={() => {
                                                                    setIntervalHistory((prev) => {
                                                                        return [...prev, { date: new Date(), doctorName: "", reason: "", note: "" }];
                                                                    })
                                                                }}
                                                            /> :
                                                            null
                                                    }
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                })

                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
}
export const SaveSection = ({ nextApointmentDate, rx, setNextApointmentDate, handleSave }) => {
    return (
        <div className="mt-4 mb-0 px-3 py-1">
            <div className="flex justify-end gap-4">
                <div className="flex items-center gap-2">
                    <input
                        type="checkbox"
                        className="h-[24px] w-[24px] rounded-[5px] outline-[#CED4DA]"
                        checked={nextApointmentDate.cbx}
                        id="nextVisitOn"
                        onChange={(event) => {
                            if (event.target.checked) {
                                let maxDuration = Math.max(...rx.map((e) => (parseInt(e.duration))));
                                let tStamp = new Date().getTime() + (maxDuration * 24 * 60 * 60 * 1000)
                                setNextApointmentDate((prev) => {
                                    prev.dateVal = dayjs(new Date(tStamp)).format("YYYY-MM-DD HH:mm:ss");
                                    prev.cbx = event.target.checked;
                                    return { ...prev };
                                });
                            } else {
                                setNextApointmentDate((prev) => {
                                    prev.cbx = event.target.checked;
                                    return { ...prev };
                                });
                            }
                        }}
                    />
                    <label className="" for="nextVisitOn">
                        Next Visit On
                    </label>
                    <div className="flex justify-between items-center text-[#404040] bg-white border border-[#CED4DA] rounded-[5px] w-fit">
                        <ReactDatePicker
                            selected={new Date(nextApointmentDate.dateVal)}
                            className="outline-none rounded-[5px] py-[6px] px-[14px] bg-transparent h-[2rem] w-[175px]"
                            closeOnScroll={true}
                            dateFormat="dd/MM/yyyy"
                            onChange={(date) => {
                                setNextApointmentDate(prev => {
                                    prev.dateVal = dayjs(date).format("YYYY-MM-DD HH:mm:ss");
                                    return { ...prev };
                                });
                            }}
                            disabled={!nextApointmentDate.cbx}
                        />
                        <div className="flex justify-center items-center p-[9px] bg-[#E9ECEF] rounded-r-[5px] border-l border-[#CED4DA]" >
                            <i className="fa-solid fa-calendar-days"></i>
                        </div>
                    </div>
                </div>
                <div className="grid grid-cols-2 gap-2">
                    <Button text="Save" onClick={() => { handleSave(1) }} />
                    <Button text="Save For Later" onClick={() => { handleSave(0) }} />
                </div>
            </div>
        </div>
    )
}
export const FeesSection = ({ fees, setFees, otherFees, setOtherFees, totalFee, calculateTotalFee, feesRef }) => {
    const addOtherFee = () => {
        setOtherFees((prev) => {
            return [...prev, { otherFeeLabel: "", otherFeeValue: 0, labelRef: createRef(), valueRef: createRef() }]
        })
    }
    useEffect(() => {
        calculateTotalFee();
        // eslint-disable-next-line
    }, [otherFees])
    return (
        <div className="mt-4 mb-0 px-3 py-1">
            <div className="grid grid-cols-2">
                <div>
                    <div className="w-[25%]">
                        <TextInput
                            label="Fees"
                            placeholder="Fees"
                            name="fees"
                            value={String(fees)}
                            onChange={(_, v) => { setFees(v) }}
                            ref={feesRef.current}
                        />
                    </div>
                </div>
                <div className="flex flex-col justify-between pl-2">
                    <div className="overflow-auto">
                        <div className="otherfees-section">
                            <div className="flex text-sm text-[#404040] mb-2 items-center">
                                <p className="mr-2">
                                    Other Fees
                                </p>
                                <div className="text-center p-2 w-[20px] h-[20px] flex justify-center items-center rounded-[50%] cursor-pointer text-white  bg-[#80509F]" onClick={addOtherFee}>
                                    <i className="far fa-plus cursor-pointer"></i>
                                </div>
                            </div>
                            <div>
                                {
                                    otherFees.length > 0 ?
                                        otherFees.map((e, i) => {
                                            return <OtherFeesChild key={i} index={i} data={e} setOtherFees={setOtherFees} />
                                        }) : <p className="text-sm text-[#404040] pl-2 pt-2">No Other Fess</p>
                                }
                            </div>
                        </div>
                        <div className="flex justify-end my-3 pr-2">
                            <strong className="mr-2">Total Fees : </strong> {totalFee}/-
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export const SuccessModal = ({isModalVisible, closeModal}) => {
    const navigate = useNavigate();
    return (
        <Modal
            isVisible={isModalVisible}
            onClose={closeModal}
        >
            <div className="flex justify-center mb-4">
                <i className="far fa-check-circle fa-2x text-[#118C42] "></i>
            </div>
            <p className="text-center mb-4">Patient is successfully OPD OUT.</p>
            <div className="flex justify-center">
                <SuccessBtn
                    text="DONE"
                    onClick={() => {
                        closeModal();
                        navigate("/opd-out/manage");
                    }}
                />
            </div>
        </Modal>
    );
}
const OtherFeesChild = ({ index, data, setOtherFees }) => {
    const handleDeleteRow = () => {
        setOtherFees((prev) => {
            let temp = prev.filter((_, i) => (i !== index));
            return [...temp];
        });
    }
    return (
        <div key={index} className="flex items-center mb-4">
            <div className="items-center border border-[#CED4DA] rounded-[5px] py-[6px] px-[14px] w-[48%] mr-4">
                <input
                    type="text"
                    value={data.otherFeeLabel}
                    className="text-sm leading-[21px] placeholder-[#C5C5C5] text-[#404040] outline-none w-full bg-transparent"
                    placeholder="Enter Fee Detail"
                    onChange={(event) => {
                        setOtherFees(prev => {
                            data.labelRef.current.style = "";
                            prev[index].otherFeeLabel = event.target.value;
                            return [...prev];
                        })
                    }}
                    ref={data.labelRef}
                />
            </div>
            <div className="border border-[#CED4DA] rounded-[5px] py-[6px] pl-[14px] w-[48%] mr-4">
                <input
                    type="text"
                    value={data.otherFeeValue}
                    placeholder="Enter Fee"
                    className="text-sm leading-[21px] placeholder-[#C5C5C5] text-[#404040] outline-none w-full bg-transparent"
                    onChange={(event) => {
                        const re = /^[0-9\b]+$/;
                        data.valueRef.current.style = "";
                        if (event.target.value === "") {
                            setOtherFees(prev => {
                                prev[index].otherFeeValue = 0;
                                return [...prev];
                            })
                        }
                        if (re.test(event.target.value)) {
                            setOtherFees(prev => {
                                if (event.target.value[0] === "0") {
                                    prev[index].otherFeeValue = event.target.value.substring(1);
                                } else {
                                    prev[index].otherFeeValue = event.target.value;
                                }
                                return [...prev];
                            })
                        }
                    }}
                    ref={data.valueRef}
                />
            </div>
            <i className="far fa-trash-alt cursor-pointer text-[#FF0000]" onClick={() => { handleDeleteRow(index) }}></i>
        </div>
    );
}

