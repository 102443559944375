import React, { createRef, useRef, useState } from "react";
import { useNavigate } from "react-router-dom/dist";
import Button from "../../components/button";
import TextInput from "../../components/textInput";
import { resetPassword } from "../../services/auth";

const validations = {
  password: [
    {
      type: "required",
      message: "Please enter new password",
    },
  ],
  confirmPassword: [
    {
      type: "required",
      message: "Please enter confirm password",
    },
  ],
};

const ResetPassword = ({ userDetails, resetUser = () => {} }) => {
  const navigate = useNavigate();
  const inputRefs = useRef([createRef(), createRef()]);
  const [info, setInfo] = useState({
    password: "",
    confirmPassword: "",
  });
  const handleChange = (name, value) => {
    setInfo((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  const handleSubmit = () => {
    let isValid = true;
    for (let i = 0; i < inputRefs.current.length; i++) {
      const valid = inputRefs.current[i].current.validate();
      if (!valid) {
        isValid = false;
      }
    }
    if (info.password !== info.confirmPassword) {
      inputRefs.current[1].current.setError(
        "Password and Confirm password should match."
      );
      isValid = false;
    }
    if (!isValid) {
      return;
    }
    const payload = {
      userId: userDetails.userId,
      password: info.password,
    };
    resetPassword(payload).then((res) => {
      if (res.status === 200) {
        resetUser();
        sessionStorage.removeItem("token");
        navigate("/");
      } else {
      }
    });
  };

  return (
    <>
      <TextInput
        validations={validations.password}
        value={info.password}
        ref={inputRefs.current[0]}
        label="Password"
        name="password"
        onChange={handleChange}
        placeholder="Enter password"
      />
      <TextInput
        validations={validations.confirmPassword}
        value={info.confirmPassword}
        ref={inputRefs.current[1]}
        label="Confirm Password"
        name="confirmPassword"
        onChange={handleChange}
        placeholder="Enter confirm password"
      />
      <div className="flex items-center justify-end gap-5 col-span-2">
        <Button
          text="Cancel"
          onClick={() => {
            navigate("/users/manage");
          }}
        />
        <Button text="Submit" onClick={handleSubmit} />
      </div>
    </>
  );
};

export default ResetPassword;
