import React, { Fragment, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as HomeIcon } from "../assets/svgs/home-alt.svg";
import { ReactComponent as NurseIcon } from "../assets/svgs/user-nurse.svg";
import { ReactComponent as UsersIcon } from "../assets/svgs/users.svg";
import { ReactComponent as PostalIcon } from "../assets/svgs/box-alt.svg";
import { ReactComponent as AmbulanceIcon } from "../assets/svgs/ambulance.svg";
import { ReactComponent as PhoneIcon } from "../assets/svgs/phone-rotary.svg";
import { ReactComponent as GridIcon } from "../assets/svgs/grid-add.svg";
import { ReactComponent as Toggle } from "../assets/svgs/toggler.svg";
import { ReactComponent as UserShield } from "../assets/svgs/user-shield.svg";
import { ReactComponent as UserInjured } from "../assets/svgs/user-injured.svg";
import { ReactComponent as User } from "../assets/svgs/user-alt.svg";
import { ReactComponent as AngleUp } from "../assets/svgs/angle-up.svg";
import { ReactComponent as WatchIcon } from "../assets/svgs/watch.svg";
import { ReactComponent as Report } from "../assets/svgs/file-chart-pie.svg";
import { Popover } from "@mui/material";
import { resetUser } from "../actions/userActions";
import { connect } from "react-redux";

const sidebarOptionsTemp = [
  {
    label: "Dashboard",
    icon: HomeIcon,
    navigatesTo: "/dashboard",
  },
  {
    label: "Patient",
    icon: GridIcon,
    permission: "Patient",
    subOptions: [
      {
        label: "Registration",
        navigatesTo: "/patients/register",
        permission: "Patient Registration",
      },
      {
        label: "Manage",
        navigatesTo: "/patients/manage",
        permission: "Manage Patient",
      },
      {
        label: "Patient History",
        navigatesTo: "/patients/historys",
        permission: "Manage Patient",
      },
    ],
  },
  {
    label: "OPD Appointment",
    icon: NurseIcon,
    navigatesTo: "/opd-appointment/manage",
    extraRoutes: ["/opd-appointment/add"],
    permission: "OPD Appointment",
  },
  {
    label: "OPD Out",
    icon: UserInjured,
    navigatesTo: "/opd-out/manage",
    permission: "OPD Out"
  },
  {
    label: "Reminders",
    icon: WatchIcon,
    permission: "Reminders",
    subOptions: [
      {
        label: "Patients",
        navigatesTo: "/reminders/patient",
        permission: "Patient Reminders",
      }
    ]
  },
  // {
  //   label: "Visitor's Book",
  //   icon: UsersIcon,
  //   navigatesTo: "/visitors-book/manage",
  //   extraRoutes: ["/visitors-book/add"],
  //   permission: "Visitor's Book",
  // },
  // {
  //   label: "Postal",
  //   icon: PostalIcon,
  //   navigatesTo: "/postal/manage",
  //   extraRoutes: ["/postal/add"],
  //   permission: "Postal",
  // },
  // {
  //   label: "Ambulance Log Book",
  //   icon: AmbulanceIcon,
  //   navigatesTo: "/ambulance-log-book/manage",
  //   extraRoutes: ["/ambulance-log-book/add"],
  //   permission: "Ambulance Log Book",
  // },
  // {
  //   label: "Phone Call Book",
  //   icon: PhoneIcon,
  //   navigatesTo: "/phone-call-book/manage",
  //   extraRoutes: ["/phone-call-book/add"],
  //   permission: "Phone Call Book",
  // },
  {
    label: "Reports",
    icon: Report,
    permission: "Reports",
    subOptions: [
      {
        label: "OPD Report",
        navigatesTo: "/reports/opd",
        permission: "OPD Report"
      }
    ]
  },
  {
    label: "Permissions",
    icon: UserShield,
    permission: "Permissions",
    subOptions: [
      {
        label: "Roles",
        navigatesTo: "/roles/manage",
        extraRoutes: ["/roles/add"],
        permission: "Roles",
      },
      {
        label: "Functionality",
        navigatesTo: "/functionality/manage",
        permission: "Functionality",
      },
      {
        label: "Modules",
        navigatesTo: "/modules/manage",
        extraRoutes: ["/modules/add"],
        permission: "Modules",
      },
      {
        label: "Policy",
        navigatesTo: "/policy/manage",
        permission: "Policy",
      },
    ],
  },
  {
    label: "Users",
    icon: User,
    navigatesTo: "/users/manage",
    extraRoutes: ["/users/add"],
    permission: "Users",
  },
];

const getIndexes = (sidebarOptions) => {
  let indexes = {};
  sidebarOptions?.forEach((option, index) => {
    if (option) {
      if (option?.subOptions) {
        option?.subOptions?.forEach((subOption, subIndex) => {
          indexes[subOption?.navigatesTo] = [index, subIndex];
          subOption?.extraRoutes?.forEach((route) => {
            indexes[route] = [index, subIndex];
          });
        });
      } else {
        indexes[option?.navigatesTo] = [index];
        option?.extraRoutes?.forEach((route) => {
          indexes[route] = [index];
        });
      }
    }
  });
  return indexes;
};

const getParseOptions = (userPermissions) => {
  let sidebarOptions = [];
  sidebarOptionsTemp?.forEach((option) => {
    if (option.permission) {
      if (userPermissions[option?.permission]?.includes("Show Menu")) {
        if (option?.subOptions) {
          let optionTemp = {};
          optionTemp = {
            label: option.label,
            icon: option.icon,
            navigatesTo: option.navigatesTo,
            extraRoutes: option.extraRoutes,
            subOptions: [],
          };
          option?.subOptions?.forEach((item) => {
            if (item.permission) {
              if (userPermissions[item.permission]?.includes("Show Menu")) {
                optionTemp.subOptions.push(item);
              }
            } else {
              optionTemp.subOptions.push(item);
            }
          });
          sidebarOptions.push(optionTemp);
        } else {
          sidebarOptions.push(option);
        }
      }
    } else {
      sidebarOptions.push(option);
    }
  });
  return sidebarOptions;
};

const Sidebar = ({
  onCollapseChange = () => { },
  userDetails,
  resetUser,
  userPermissions = {},
}) => {
  const ImgIcon = () => {
    if (userDetails?.hasOwnProperty("photo") && userDetails?.photo !== null && userDetails?.photo !== "") {
      return <img src={userDetails?.photo} className="h-[24px] w-[24px] rounded-[4px] md:rounded-[10px]" alt="user pic" />
    } else {
      return <img src="/images/person.jpeg" className="h-[24px] w-[24px] rounded-[4px] md:rounded-[10px]" alt="user pic" />
    }
  }
  const userMenu = {
    label: `${userDetails?.firstName + " " + userDetails?.lastName} (${userDetails?.roleName})`,
    icon: ImgIcon,
    subOptions: [
      {
        label: "Logout",
        navigatesTo: "/logout"
      },
      {
        label: "Profile",
        navigatesTo: "/edit-profile"
      }
    ]
  };
  const location = useLocation();
  const navigate = useNavigate();
  const [collapse, setCollapse] = useState(location.pathname.includes("patient-history"));
  const [expandOptions, setExpandOptions] = useState(false);
  const [expandOptionsIndex, setExpandOptionsIndex] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [uanchorEl, setUAnchorEl] = useState(null);
  const [overlayIndex, setOverlayIndex] = useState("");
  const open = Boolean(anchorEl);
  const uopen = Boolean(uanchorEl);
  const id = open ? "simple-popover" : undefined;
  const uId = uopen ? "simple-popover1" : undefined;
  const sidebarOptions =
    userDetails.roleName === "Owner"
      ? [userMenu, ...sidebarOptionsTemp]
      : [userMenu, ...getParseOptions(userPermissions)];
  const indexes = getIndexes(sidebarOptions);
  const handleClick = (event, index) => {
    if (sidebarOptions?.[index]?.subOptions) {
      setOverlayIndex(index);
      setAnchorEl(event.currentTarget);
    }
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleUClick = (event) => {
    setUAnchorEl(event.currentTarget);
  };
  const handleUClose = () => {
    setUAnchorEl(null);
  };

  const handleCollapse = (value) => {
    onCollapseChange(value);
    setCollapse(value);
  };

  const handleLogout = () => {
    resetUser();
    sessionStorage.removeItem("token");
    navigate("/");
  };

  useEffect(() => {
    const index = indexes[location.pathname]?.[0];
    if (sidebarOptions[index]?.subOptions) {
      setExpandOptions(true);
      setExpandOptionsIndex(index);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      className={`flex-col ${collapse ? "w-[90px]" : "w-[20%]"
        } duration-300 bg-white shadow-[4px_0px_40px] shadow-[#00000029] hidden md:flex overflow-auto no-scrollbar`}
    >
      <div className="flex flex-row py-4 px-5 items-center justify-center w-full border-b">
        <div className="flex items-center w-full">
          <img
            src="/images/Pulse_Logo_Navbar.png"
            className="h-[46px] w-[46px]"
            alt="logo"
            onClick={() => {
              if (collapse) {
                handleCollapse(false);
              }
            }}
          />
          {!collapse ? (
            <p className="ml-[13px] text-[#00ADEE] text-[15px] leading-[18px] font-bold">
              PULSE EMR
            </p>
          ) : null}
        </div>
        {!collapse ? (
          <Toggle
            onClick={() => {
              handleCollapse(true);
            }}
          />
        ) : null}
      </div>
      <div className="flex flex-col my-[30px]">
        {sidebarOptions?.map((option, index) => {
          if (option === null) {
            return null;
          }
          const { icon: Icon, navigatesTo, label, subOptions } = option || {};
          const urlIndexes = indexes[location.pathname];

          return (
            <Fragment key={index}>
              <div
                className={`group flex flex-row items-center justify-between py-[3px] ${collapse ? "" : "pl-4"
                  } cursor-pointer ${urlIndexes?.[0] === index ? "bg-[#F1F0FF]" : ""
                  } hover:bg-[#F1F0FF]`}
                onClick={(event) => {
                  if (navigatesTo) {
                    navigate(navigatesTo);
                  } else {
                    if (expandOptionsIndex !== index) {
                      setExpandOptionsIndex(index);
                    } else {
                      setExpandOptions((prev) => !prev);
                    }
                  }
                  if (!collapse) {
                    handleCollapse(false);
                  } else {
                    handleClick(event, index);
                  }
                }}
              >
                <div
                  className={`flex flex-row items-center justify-center ${collapse ? "w-full" : ""
                    }`}
                >
                  <Icon
                    fill={urlIndexes?.[0] === index ? "#80509F" : "#000000"}
                    height={24}
                    width={24}
                  />
                  {!collapse ? (
                    <p
                      className={`text-sm leading-[17px] font-semibold ml-3 ${urlIndexes?.[0] === index ? "text-[#80509F]" : ""
                        }`}
                    >
                      {label}
                    </p>
                  ) : null}
                </div>
                <div className="flex items-center">
                  {option?.subOptions && !collapse ? (
                    expandOptions && expandOptionsIndex === index ? (
                      <AngleUp height={24} width={24} className="mr-2" />
                    ) : (
                      <AngleUp
                        height={24}
                        width={24}
                        className="mr-2 rotate-180"
                      />
                    )
                  ) : null}
                  <div
                    className={`${urlIndexes?.[0] === index
                      ? "bg-[#80509F]"
                      : "group-hover:bg-[#80509F]"
                      }  w-[5px] h-[39px]  rounded-l-[5px]`}
                  />
                </div>
              </div>

              {expandOptions && !collapse && expandOptionsIndex === index ? (
                <div className="flex flex-col px-10">
                  {subOptions?.map((option, subIndex) => {
                    return (
                      <div
                        key={subIndex}
                        className="flex py-3 cursor-pointer"
                        onClick={() => {
                          navigate(option.navigatesTo);
                        }}
                      >
                        {!collapse ? (
                          <p
                            className={`text-sm leading-[17px] font-semibold ml-3 ${urlIndexes?.[0] === index &&
                              urlIndexes?.[1] === subIndex
                              ? "text-[#80509F]"
                              : ""
                              }`}
                          >
                            {option.label}
                          </p>
                        ) : null}
                      </div>
                    );
                  })}
                </div>
              ) : null}
            </Fragment>
          );
        })}
      </div>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          horizontal: "left",
        }}
        style={{
          marginLeft: 70,
        }}
      >
        <div className="flex flex-col p-[14px] gap-3">
          {sidebarOptions[overlayIndex]?.subOptions?.map((option, index) => {
            return (
              <p
                className="text-sm leading-[17px] font-semibold cursor-pointer"
                onClick={() => {
                  navigate(option.navigatesTo);
                }}
                key={index}
              >
                {option.label}
              </p>
            );
          })}
        </div>
      </Popover>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    userPermissions: state.user.permission,
    userDetails: state.user.userDetails,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    resetUser: () => dispatch(resetUser()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
