import React, { useEffect, useState } from "react";
import Button from "../../../components/button";
import TextInput from "../../../components/textInput";
import Dropdown from "../../../components/dropdown";
import DatePicker from "../../../components/datePicker";
import ImageUpload from "../../../components/imageUpload";
import dayjs from "dayjs";

const policyTypeOption = [
    {
        label: "Individual",
        value: "Individual",
    },
    {
        label: "Group",
        value: "Group",
    }
]

const InsuranceInfo = (props) => {
    const [info, setInfo] = useState({
        patientId: props.info!== null?parseInt(props.info.patientId):0,
        company: "",
        personName: "",
        personId: "",
        sumAssured: 0,
        policyExpiryDate: new Date(),
        premiumPaid: "N",
        policyFile: "",
        policyType: "Individual",
        policyPersonName: "",
        policyPersonContact: ""
    });
    const [insuranceScreen, setInsuranceScreen] = [props.insuranceScreen, props.setInsuranceScreen];
    const handleBackClick = () => {
        setInsuranceScreen(0);
        props.goToStep(1);
    };
    const handleNextClick = () => {
        props.saveInfo({
            insuranceDetails:{
                ...info,
                policyExpiryDate: dayjs(info.policyExpiryDate).format('YYYY-MM-DD HH:mm:ss')
            }
        });
        props.goToStep(3);
    };
    const handleYesNoClick = (name, val) => {
        props.saveInfo({
            [name]: val
        });
    };
    const handleChange = (name, value) => {
        if (name === "sumAssured") {
            setInfo((prev) => {
                return {
                    ...prev,
                    [name]: isNaN(value)?0:parseInt(value),
                };
            });
        } else {
            setInfo((prev) => {
                return {
                    ...prev,
                    [name]: value,
                };
            });
        }
    };
    useEffect(()=>{
        if(props.info && props.info.insuranceDetails !== null && props.info.insuranceDetails !== undefined) {
            setInfo((prev)=>{
                return {
                    ...prev,
                    ...props.info.insuranceDetails,
                    policyExpiryDate: new Date(props.info.insuranceDetails.policyExpiryDate)
                }
            })
        }
    }, [props.info])
    return (
        <>
            {
                insuranceScreen === 0 ?
                    <div className="min-h-[50vh] flex flex-col justify-center items-center">
                        <h1>Does Patient Have Insurance Policy ?</h1>
                        <div className="mt-6 flex justify-center">
                            <div>
                                <Button
                                    text="Yes"
                                    textStyle="mx-3 md:mx-6"
                                    onClick={() => {setInsuranceScreen(1);handleYesNoClick("insuranceScreen", "Yes");}}
                                />
                            </div>
                            <div className="ml-2">
                                <Button
                                    text="No"
                                    textStyle="mx-3 md:mx-6"
                                    onClick={() => {  setInsuranceScreen(1);handleYesNoClick("insuranceScreen", "No");props.goToStep(3);}}
                                />
                            </div>
                        </div>
                    </div> :
                    <div className="p-5 lg:px-[30px] lg:pb-9 lg:pt-6">
                        <div className="grid grid-cols-1 md:grid-cols-3 md:gap-4 lg:gap-6 xl:gap-9">
                            <TextInput
                                label="Insurance Company"
                                placeholder="Insurance Company"
                                name="company"
                                value={info.company}
                                onChange={handleChange}
                            />
                            <TextInput
                                label="Insured Person"
                                placeholder="Insured Person"
                                name="personName"
                                value={info.personName}
                                onChange={handleChange}
                            />
                            <TextInput
                                label="Insured Person ID"
                                placeholder="Insured Person ID"
                                name="personId"
                                value={info.personId}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="grid grid-cols-1 md:grid-cols-3 md:gap-4 lg:gap-6 xl:gap-9">
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-x-9 col-span-2">
                                <TextInput
                                    label="Sum Assured"
                                    placeholder="Sum Assured"
                                    name="sumAssured"
                                    value={!isNaN(info.sumAssured) ?info.sumAssured.toString(): 0}
                                    onChange={handleChange}
                                />
                                <DatePicker
                                    label="Policy Expiry Date"
                                    placeholderText="DD / MM / YYYY"
                                    name="policyExpiryDate"
                                    value={info.policyExpiryDate}
                                    onChange={handleChange}
                                />
                                <div className="flex items-center text-sm text-[#404040]">
                                    <input
                                        type="checkbox"
                                        className="h-[16px] w-[16px] rounded-[5px] outline-[#CED4DA]"
                                        checked={info.premiumPaid === "Y"}
                                        onChange={(event) => {
                                            handleChange("premiumPaid", event.target.checked ? "Y" : "N");
                                        }}
                                    />
                                    <p className="ml-2">Is last premium paid</p>
                                </div>
                                <Dropdown
                                    label="Policy Type"
                                    placeholder="Select Polcy Type"
                                    options={policyTypeOption}
                                    value={info.policyType}
                                    onChange={(value) => { handleChange("policyType", value.value) }}
                                />
                                <TextInput
                                    label="Insurance Contact Details"
                                    placeholder="Person Name"
                                    name="policyPersonName"
                                    value={info.policyPersonName}
                                    onChange={handleChange}
                                    containerStyle="mt-2"
                                />
                                <TextInput
                                    label=""
                                    placeholder="Person Contact Number"
                                    name="policyPersonContact"
                                    value={info.policyPersonContact}
                                    onChange={handleChange}
                                    containerStyle="mt-2"
                                />
                            </div>
                            <div className="grid grid-rows-subgrid gap-4 row-span-2">
                                <ImageUpload
                                    className="mt-7"
                                    userType="patient"
                                    userId={info.patientId}
                                    label="Click to Upload Photo/PDF"
                                    image={info.policyFile}
                                    fileType="insurancePolicy"
                                    onImageUpload={(imagePath, imageName) => {
                                        handleChange("policyFile", imagePath)
                                    }}
                                />
                            </div>
                        </div>
                        <div className="flex flex-col md:flex-row md:items-center justify-between md:mt-10 gap-5 md:gap-0">
                            <div>
                                <Button
                                    text="Previous"
                                    textStyle="mx-5 md:mx-10"
                                    onClick={handleBackClick}
                                />
                            </div>
                            <div>
                                <Button
                                    text="Save & Next"
                                    textStyle="mx-5 md:mx-10"
                                    onClick={handleNextClick}
                                />
                            </div>
                        </div>
                    </div>
            }
        </>

    );
}

export default InsuranceInfo;
