import React, { createRef, useEffect, useRef, useState } from "react";
import WithHeaderSidebar from "../../../layout/withHeaderSidebar";
import WithAuth from "../../../layout/withAuth";
import { getAllFunctionalities } from "../../../services/permissions/functionality";
import TextInput from "../../../components/textInput";
import Dropdown from "../../../components/dropdown";
import {
  createModule,
  getModuleById,
  updateModuleById,
} from "../../../services/permissions/modules";
import { useNavigate, useParams } from "react-router-dom";
import CheckboxGroup from "../../../components/checkboxGroup";
import { getAllPolicy } from "../../../services/permissions/policy";
import Button from "../../../components/button";

const CreateModule = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [moduleInfo, setModuleInfo] = useState({
    policies: [],
    moduleName: "",
    functionalityId: "",
  });
  const ref = useRef([createRef(), createRef()]);
  const [functionalityList, setFunctionalityList] = useState([]);
  const [policyList, setPolicyList] = useState([]);

  const getFunctionalityList = () => {
    getAllFunctionalities().then((res) => {
      if (res.status === 200) {
        const functionalityList = res.result?.functionalityList.map((item) => {
          return {
            label: item.functionalityName,
            value: item.functionalityId,
          };
        });
        setFunctionalityList(functionalityList);
      }
    });
  };

  const getPolicyList = () => {
    getAllPolicy().then((res) => {
      if (res.status === 200) {
        const policyList = res.result.policyList.map((item) => {
          return {
            label: item.policyName,
            value: item.policyId.toString(),
          };
        });
        setPolicyList(policyList);
      }
    });
  };

  const getModuleDetails = () => {
    if (id) {
      getModuleById(id).then((res) => {
        if (res.status === 200) {
          const { moduleName, functionalityId, policies } = res.result?.module;
          setModuleInfo({
            moduleName,
            functionalityId,
            policies,
          });
        }
      });
    }
  };
  useEffect(() => {
    getModuleDetails();
    getPolicyList();
    getFunctionalityList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAddAndEdit = () => {
    const isValid =
      ref.current[0].current.validate() && ref.current[1].current.validate();
    if (!isValid) {
      return;
    }
    if (id) {
      updateModuleById({ moduleId: id, ...moduleInfo }).then((res) => {
        if (res.status === 200) {
          navigate("/modules/manage");
        }
      });
    } else {
      createModule(moduleInfo).then((res) => {
        if (res.status === 200) {
          navigate("/modules/manage");
        }
      });
    }
  };
  return (
    <div className="h-full">
      <div className="p-5 sm:pl-8 sm:pt-7 sm:pb-[23px]">
        <p className="text-xl md:text-2xl md:leading-6 text-[#404040] font-bold">
          Modules
        </p>
      </div>
      <div className="bg-[#F7F8F9] sm:pt-[18px] p-5 sm:pl-7 sm:pr-11 sm:pb-9">
        <div className="bg-white w-full h-full shadow-[0px_9px_20px] shadow-[#2E235E1A] rounded-[15px]">
          <div className="flex flex-row items-center justify-between p-3 sm:p-7 border-b ">
            <p className="text-base md:text-xl leading-6 text-[#404040] font-bold">
              {id ? "Edit Module" : "Create Module"}
            </p>
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-2 sm:gap-x-5 p-3 sm:p-7 sm:pt-5">
            <TextInput
              validations={[
                { type: "required", message: "Please enter module name" },
              ]}
              value={moduleInfo.moduleName}
              ref={ref.current[0]}
              label="Module Name"
              onChange={(name, value) => {
                setModuleInfo((prev) => {
                  return {
                    ...prev,
                    moduleName: value,
                  };
                });
              }}
            />
            <Dropdown
              options={functionalityList}
              label="Functionality"
              placeholder="Select functionality"
              value={moduleInfo.functionalityId}
              ref={ref.current[1]}
              onChange={(value) => {
                setModuleInfo((prev) => {
                  return {
                    ...prev,
                    functionalityId: value.value,
                  };
                });
              }}
              validations={[
                { type: "required", message: "Please select functionality" },
              ]}
            />
            <CheckboxGroup
              options={policyList}
              label="Policies"
              className="col-span-2"
              value={moduleInfo.policies.map((item) => item.toString())}
              optionsContainerStyle="flex flex-row gap-[12px] mt-2"
              inputStyle="rounded-[5px] outline-[#CED4DA] h-[12px] w-[12px] -mr-2"
              onChange={(name, value) => {
                let selectedPolicyIds = JSON.parse(
                  JSON.stringify(moduleInfo.policies)
                );
                if (selectedPolicyIds.includes(value)) {
                  const index = selectedPolicyIds.indexOf(value);
                  selectedPolicyIds.splice(index, 1);
                } else {
                  selectedPolicyIds.push(value);
                }
                setModuleInfo((prev) => {
                  return {
                    ...prev,
                    policies: selectedPolicyIds,
                  };
                });
              }}
            />
            <div className="flex justify-end mt-3 col-span-2">
              <Button
                text={id ? "Edit" : "Create"}
                onClick={handleAddAndEdit}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WithAuth(WithHeaderSidebar(CreateModule));
