import React, {
  createRef,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import TableWithSearchAndPagination from "../../components/tableWithSearchAndPagination";
import Button from "../../components/button";
import WithHeaderSidebar from "../../layout/withHeaderSidebar";
import WithAuth from "../../layout/withAuth";
import {
  createPolicy,
  deletePolicyById,
  policyListPage,
  updatePolicyById,
} from "../../services/permissions/policy";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import TextInput from "../../components/textInput";
import CheckPermission from "../../components/checkPermission";

const headings = [
  {
    label: "Policy Id",
    width: "10%",
    align: "left",
  },
  {
    label: "Policy Name",
    width: "70%",
    align: "left",
  },
  {
    label: "Actions",
    width: "20%",
    align: "center",
  },
];

const dataToShow = [
  {
    key: "policyId",
    align: "left",
  },
  {
    key: "policyName",
    align: "left",
  },
];

const Policy = () => {
  const [totalData, setTotalData] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [callbackAttributes, setCallbackAttributes] = useState({
    searchSend: "",
    perPage: "25",
    selectedPage: "0",
    sort: "",
  });
  const [policyList, setPolicyList] = useState([]);
  const [policyInfo, setPolicyInfo] = useState({
    policyId: 0,
    policyName: "",
  });
  const ref = useRef(createRef());
  const [openModal, setOpenModal] = useState(false);

  const displayPolicyList = useCallback(() => {
    setPolicyList([]);
    const { searchSend, selectedPage, perPage, sort } = callbackAttributes;
    let data = `searchKey=${searchSend}&page=${selectedPage}&size=${perPage}&sort=${sort}`;
    policyListPage(data).then((res) => {
      if (res?.status === 200) {
        setPolicyList(res?.result?.policyList || []);
        setTotalData(res?.result?.getTotalRecords || 0);
        setTotalPages(res?.result?.getTotalPages || 0);
      } else {
        // props?.setSnackbar({
        //     showSnackbar: true,
        //     message: res?.message,
        //     variant: "error"
        // })
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callbackAttributes]);

  useEffect(() => {
    displayPolicyList();
  }, [displayPolicyList, callbackAttributes]);

  const handleDelete = (item) => {
    deletePolicyById(item.policyId).then((res) => {
      if (res.status === 200) {
        displayPolicyList();
      }
    });
  };
  const handleEdit = (item) => {
    setPolicyInfo({
      policyId: item.policyId,
      policyName: item.policyName,
    });
    setOpenModal(true);
  };

  const handleAddAndEdit = () => {
    const isValid = ref.current.validate();
    if (!isValid) {
      return;
    }
    if (policyInfo.policyId === 0) {
      createPolicy({
        policyName: policyInfo.policyName,
      }).then((res) => {
        if (res.status === 200) {
          setOpenModal(false);
          displayPolicyList();
        }
      });
    } else {
      updatePolicyById(policyInfo).then((res) => {
        if (res.status === 200) {
          setOpenModal(false);
          displayPolicyList();
        }
      });
    }
  };

  const handleModalClose = () => {
    setPolicyInfo({
      policyId: 0,
      policyName: "",
    });
    setOpenModal(false);
  };

  return (
    <div className="h-full">
      <div className="p-5 sm:pl-8 sm:pt-7 sm:pb-[23px]">
        <p className="text-xl md:text-2xl md:leading-6 text-[#404040] font-bold">
          Policy
        </p>
      </div>
      <div className="bg-[#F7F8F9] sm:pt-[18px] p-5 sm:pl-7 sm:pr-11 sm:pb-9">
        <div className="bg-white w-full h-full shadow-[0px_9px_20px] shadow-[#2E235E1A] rounded-[15px]">
          <div className="flex flex-row items-center justify-between p-3 sm:p-7 border-b ">
            <p className="text-base md:text-xl leading-6 text-[#404040] font-bold">
              Policy List
            </p>
            <CheckPermission module="Policy" permission="Add">
              <Button
                text="New Entry"
                onClick={() => {
                  setPolicyInfo({
                    policyId: 0,
                    policyName: "",
                  });
                  setOpenModal(true);
                }}
              />
            </CheckPermission>
          </div>
          <div className="p-3 sm:p-7 sm:pt-5">
            <TableWithSearchAndPagination
              headings={headings}
              dataToShow={dataToShow}
              data={policyList}
              totalData={totalData}
              totalPages={totalPages}
              actions={["delete", "edit"]}
              setCallbackAttributes={setCallbackAttributes}
              handleActionEdit={handleEdit}
              handleActionDelete={handleDelete}
              module="Policy"
              actionPermission={{
                edit: "Edit",
                delete: "Delete",
              }}
            />
          </div>
        </div>
      </div>
      <Dialog
        open={openModal}
        TransitionProps={{
          style: {
            height: "auto",
          },
        }}
        fullWidth={true}
        maxWidth="sm"
      >
        <DialogTitle>
          <p className="text-base md:text-xl leading-6 text-[#404040] font-bold">
            {policyInfo.policyId === 0 ? "Add Policy" : "Edit Policy"}
          </p>
        </DialogTitle>
        <DialogContent dividers>
          <TextInput
            validations={[
              { type: "required", message: "Please enter policy name" },
            ]}
            value={policyInfo.policyName}
            ref={ref}
            label="Policy Name"
            onChange={(name, value) => {
              setPolicyInfo((prev) => {
                return {
                  ...prev,
                  policyName: value,
                };
              });
            }}
          />
        </DialogContent>
        <DialogActions>
          <div className="gap-2 flex">
            <Button text="Cancel" onClick={handleModalClose} />
            <Button
              text={policyInfo.policyId === 0 ? "Add" : "Edit"}
              onClick={handleAddAndEdit}
            />
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default WithAuth(WithHeaderSidebar(Policy));
