import React from "react";
import { connect } from "react-redux";
import { setLoader } from "../actions/loaderActions";

const Loader = ({ loader }) => {
  return (
    <>
      {loader?.load ? (
        <div className="flex flex-col justify-center items-center fixed h-screen w-screen top-0 left-0 bg-[rgba(0,0,0,0.4)] z-[1350]">
          <div>
            <i className="fa-solid fa-spinner fa-spin fa-5x text-white"></i>
          </div>
          {loader?.text && (
            <div className="mt-3 text-white text-2xl">{loader?.text}</div>
          )}
        </div>
      ) : null}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    loader: state.loader,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setLoader: (data) => {
      dispatch(setLoader(data));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Loader);
