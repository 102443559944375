import DatePicker from "../../components/datePicker";
import TextInput from "../../components/textInput";
import WithHeaderSidebar from "../../layout/withHeaderSidebar";
import TextArea from "../../components/textArea";
import Button from "../../components/button";
import SearchBox from "../../components/searchbox";
import { createRef, useEffect, useRef, useState } from "react";
import { addNewPostalInfo, getPostalById } from "../../services/postal";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import dayjs from "dayjs";
import WithAuth from "../../layout/withAuth";

const validations = {
  referenceNumber: [
    {
      type: "required",
      message: "Please enter reference number",
    },
  ],
  date: [
    {
      type: "required",
      message: "Please select date",
    },
  ],
  postalFrom: [
    {
      type: "required",
      message: "Please enter from name",
    },
  ],
  postalTo: [
    {
      type: "required",
      message: "Please enter to name",
    },
  ],
  fromAddress: [
    {
      type: "required",
      message: "Please enter from address",
    },
  ],
  toAddress: [
    {
      type: "required",
      message: "Please enter to address",
    },
  ],
  receiptNumber: [
    {
      type: "required",
      message: "Please enter receipt number",
    },
  ],
  remark: [
    {
      type: "required",
      message: "Please enter remark",
    },
  ],
  departmentName: [
    {
      type: "required",
      message: "Please enter department name",
    },
  ],
};

const RegisterPostal = (props) => {
  const location = useLocation();
  const { flow } = location.state || {};
  const { id } = useParams();
  const navigate = useNavigate();
  const inputRefs = useRef([
    createRef(),
    createRef(),
    createRef(),
    createRef(),
    createRef(),
    createRef(),
    createRef(),
    createRef(),
    createRef(),
  ]);

  const [info, setInfo] = useState({
    postalId: 0,
    referenceNumber: "",
    date: "",
    postalFrom: "",
    postalTo: "",
    fromAddress: "",
    toAddress: "",
    remark: "",
    receiptNumber: "",
    departmentName: "",
    isReceived: "",
  });

  const handleChange = (name, value) => {
    setInfo((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  useEffect(() => {
    if (flow) {
      handleChange("isReceived", flow === "addReceive" ? "Y" : "N");
    }
  }, [flow]);

  useEffect(() => {
    if (id) {
      getPostalById(id).then((res) => {
        if (res.status === 200) {
          const postalDto = res?.result?.postalDto;
          setInfo({
            ...postalDto,
            date: new Date(postalDto.date),
          });
        }
      });
    }
  }, [id]);

  const handleRegister = () => {
    let isValid = true;
    for (let i = 0; i < inputRefs.current.length; i++) {
      const valid = inputRefs.current[i].current.validate();
      if (!valid) {
        isValid = false;
      }
    }
    if (!isValid) {
      return;
    }
    const payload = {
      ...info,
      date: dayjs(info.date).format("YYYY-MM-DD HH:mm:ss"),
    };
    addNewPostalInfo(payload).then((res) => {
      if (res.status === 200) {
        navigate("/postal/manage");
      }
    });
  };

  return (
    <div
      className="flex flex-col"
  style={{ /*minHeight: `calc(100vh - ${props.headerHeight}px)`*/ }}
    >
      <div className="p-5 sm:pl-8 sm:pt-[17px] sm:pb-[23px]">
        <p className="text-xl sm:text-2xl sm:leading-6 text-[#404040] font-bold">
          Postal
        </p>
      </div>
      <div className="flex flex-col lg:flex-row bg-[#F7F8F9] pt-[18px] p-5 sm:px-7 sm:pb-9 gap-[22px]">
        <div className="flex flex-col bg-[#FFFFFF] rounded-[15px] lg:w-[20%] shadow-[0px_9px_20px] shadow-[#2E235E1A]">
          <div className="p-3 lg:px-[30px] border-b">
            <p className="text-base md:text-xl md:leading-6 text-[#404040] font-bold">
              {flow === "addReceive" ? "Received Postal" : "Dispatch Postal"}
            </p>
          </div>
          <div className="p-5 lg:px-[30px]">
            <SearchBox />
          </div>
        </div>
        <div className="flex flex-col bg-[#FFFFFF] rounded-[15px] lg:w-[80%] shadow-[0px_9px_20px] shadow-[#2E235E1A]">
          <div className="p-5 lg:px-[30px] border-b">
            <p className="text-base md:text-xl md:leading-6 text-[#404040] font-bold">
              {flow === "addReceive"
                ? "Received Postal Register"
                : "Dispatch Postal Register"}
            </p>
          </div>
          <div className="p-5 lg:px-[30px] lg:pb-9 lg:pt-6">
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 sm:gap-4 lg:gap-6 xl:gap-9">
              <TextInput
                label="Reference No."
                placeholder="000000"
                ref={inputRefs.current[0]}
                value={info.referenceNumber}
                name="referenceNumber"
                onChange={handleChange}
                validations={validations.referenceNumber}
              />
              <DatePicker
                label="Date"
                placeholderText="DD / MM / YYYY"
                ref={inputRefs.current[1]}
                value={info.date}
                name="date"
                onChange={handleChange}
                validations={validations.date}
              />
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 md:gap-4 lg:gap-6 xl:gap-9">
              <TextInput
                label="Received From"
                placeholder="Received From"
                ref={inputRefs.current[2]}
                value={info.postalFrom}
                name="postalFrom"
                onChange={handleChange}
                validations={validations.postalFrom}
              />
              <TextInput
                label="AWB / Receipt No."
                placeholder="AWB / Receipt No."
                ref={inputRefs.current[3]}
                value={info.receiptNumber}
                name="receiptNumber"
                onChange={handleChange}
                validations={validations.receiptNumber}
              />
            </div>
            <TextArea
              label="Address"
              placeholder="Address"
              rows={3}
              ref={inputRefs.current[4]}
              value={info.fromAddress}
              name="fromAddress"
              onChange={handleChange}
              validations={validations.fromAddress}
            />
            <div className="grid grid-cols-1 md:grid-cols-2 md:gap-4 lg:gap-6 xl:gap-9">
              <TextInput
                label="Received To"
                placeholder="Received To"
                ref={inputRefs.current[5]}
                value={info.postalTo}
                name="postalTo"
                onChange={handleChange}
                validations={validations.postalTo}
              />
              <TextInput
                label="Deparment"
                placeholder="Name of Department"
                ref={inputRefs.current[6]}
                value={info.departmentName}
                name="departmentName"
                onChange={handleChange}
                validations={validations.departmentName}
              />
            </div>
            <TextArea
              label="Address"
              placeholder="Address"
              rows={3}
              ref={inputRefs.current[7]}
              value={info.toAddress}
              name="toAddress"
              onChange={handleChange}
              validations={validations.toAddress}
            />
            <div className="grid grid-cols-1 md:grid-cols-3">
              <TextInput
                containerStyle="col-span-2"
                label="Remark"
                ref={inputRefs.current[8]}
                value={info.remark}
                name="remark"
                onChange={handleChange}
                validations={validations.remark}
              />
              <div className="flex items-center justify-end">
                <Button
                  text="Register"
                  textStyle="mx-10"
                  onClick={handleRegister}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WithAuth(WithHeaderSidebar(RegisterPostal));
