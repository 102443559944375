import React, { useEffect, useMemo, useState } from "react";
import { useDropzone } from "react-dropzone";
import { uploadFile } from "../services/common";

const activeStyle = {
    borderColor: "#2196f3",
};
const acceptStyle = {
    borderColor: "#00e676",
};
const rejectStyle = {
    borderColor: "#ff1744",
};

// function getBase64(file, cb) {
//     let reader = new FileReader();
//     reader.readAsDataURL(file);
//     reader.onload = function () {
//         cb(reader.result);
//     };
//     reader.onerror = function (error) {
//     };
// }

const IdUpload = ({
    className = "",
    userType = "",
    userId = "",
    onImageUpload = () => { },
    image = null,
    placeholder = "Upload Photo id",
    label = "Click to Upload Photo",
    containerStyle = "",
    labelStyle = ""
}) => {
    // const [error, setError] = useState("")
    const [acceptedImage, setAcceptedImage] = useState({});

    useEffect(() => {
        setAcceptedImage(image);
    }, [image]);

    const {
        getRootProps,
        getInputProps,
        isDragActive,
        isDragAccept,
        isDragReject,
    } = useDropzone({
        noClick: false,
        noKeyboard: true,
        multiple: true,
        maxFiles: 1,
        onDrop: (acceptedFiles) => {
            acceptedFiles.forEach((file) => {
                handleSaveFile(file);
            });
        },
    });

    const handleSaveFile = async (file) => {
        let formData = new FormData()
        formData.append('file', file)
        formData.append("userType", userType)
        formData.append("fileType", "photoId")
        if (userId !== "0") {
            formData.append("id", userId)
        }
        uploadFile(formData).then(res => {
            if (res.status === 200) {
                const { imageName, imagePath } = res?.result;
                onImageUpload(imagePath);
                setAcceptedImage({ imagePath, imageName });
            }
        })
    };

    const style = useMemo(
        () => ({
            // ...baseStyle,
            ...(isDragActive ? activeStyle : {}),
            ...(isDragAccept ? acceptStyle : {}),
            ...(isDragReject ? rejectStyle : {}),
        }),
        [isDragActive, isDragAccept, isDragReject]
    );
    return (
        <div className={`flex flex-col w-full ${containerStyle}`}>
            {label ? (
                <p
                    className={`text-sm leading-[21px] text-[#404040] mb-2 ${labelStyle}`}
                >
                    {label}
                </p>
            ) : null}
            <div {...getRootProps({ style })} className={`w-full ${className}`}>
                <div className="flex flex-row w-full border rounded-[5px] border-[#CED4DA]">
                    <div className="py-[6px] px-[14px] bg-[#CED4DA] rounded-l-[5px] cursor-pointer">
                        <p className="text-[14px] leading-[21px] text-black">Browse</p>
                    </div>
                    <input value={acceptedImage?.imageName} className="text-[14px] leading-[21px] text-[#C5C5C5] bg-white w-full my-[6px] ml-[14px]" placeholder={placeholder} disabled={true} />
                </div>
                <input {...getInputProps()} />
            </div>
            {/* {error ? (
                <span className="text-sm text-red-500 mb-5 mt-1">{error}</span>
            ) : (
                <span className="h-5" />
            )} */}
        </div>
    );
};

export default IdUpload;
