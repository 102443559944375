import React from "react";

const Label = ({
    containerStyle = "",
    labelStyle = "",
    label = "",
    value = ""
}) => {
    return (
        <div className={`flex flex-col w-full ${containerStyle}`}>
            {label ? (
                <p
                    className={`text-sm leading-[21px] text-[#404040] mb-2 ${labelStyle}`}
                >
                    {label}
                </p>
            ) : <div className="mb-7" />}
            <p className="text-sm leading-[21px] text-[#404040]">{value}</p>
            <span className="h-5" />
        </div>
    )
}

export default Label