import React from "react";

const SecondaryBtn = ({
    text = "",
    textStyle = "",
    buttonStyle = "",
    disabled = false,
    onClick = () => { },
    ...props
}) => {
    return (
        <button
            onClick={onClick}
            disabled={disabled}
            className={`${disabled ? "bg-[#848D9C]" : "bg-[#CED4DA]"
                }  py-[5px] px-4 rounded-[5px] ${buttonStyle}`}
        >
            <p
                className={`text-sm leading-[21px] text-center text-black ${textStyle}`}
            >
                <i className="far fa-times-circle text-black mr-2"></i>
                {text}
            </p>
        </button>
    );
};

export default SecondaryBtn;
