import { useNavigate } from "react-router-dom";
import Button from "../../components/button";
import { getAllPolicy } from "../../services/permissions/policy";
import { useEffect, useState } from "react";
import { editPolicy, getPolicy } from "../../services/user";

const EditUserPolicy = ({ userId, userDetails, resetUser = () => {} }) => {
  const navigate = useNavigate();
  const [functionalities, setFunctionalities] = useState([]);
  const [policies, setPolicies] = useState([]);
  const getPolicies = () => {
    getAllPolicy().then((res) => {
      if (res.status === 200) {
        setPolicies(res.result?.policyList);
      }
    });
  };

  const getUserPolicy = () => {
    if (userId) {
      getPolicy(userId).then((res) => {
        if (res.status === 200) {
          setFunctionalities(res.result?.functionalities);
        }
      });
    }
  };
  useEffect(() => {
    getPolicies();
    getUserPolicy();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleEdit = () => {
    editPolicy(userId, { functionalities }).then((res) => {
      if (res.status === 200) {
        if (userDetails.userId.toString() === userId) {
          resetUser();
          sessionStorage.removeItem("token");
          navigate("/");
        } else {
          navigate("/users/manage");
        }
      } else {
      }
    });
  };

  const handleToggle = (functionalityId, moduleId, policyId) => {
    const functionalitiesTemp = JSON.parse(JSON.stringify(functionalities));
    functionalitiesTemp.forEach((functionality) => {
      if (functionality.functionalityId === functionalityId) {
        functionality.modules.forEach((module) => {
          if (module.moduleId === moduleId) {
            if (module.userAssignedPolicy?.includes(policyId)) {
              const index = module.userAssignedPolicy.indexOf(policyId);
              module.userAssignedPolicy.splice(index, 1);
            } else {
              module.userAssignedPolicy.push(policyId);
            }
          }
        });
      }
    });
    setFunctionalities(functionalitiesTemp);
  };

  const toggleAll = (event) => {
    const functionalitiesTemp = JSON.parse(JSON.stringify(functionalities));
    functionalitiesTemp.forEach((functionality) => {
      functionality.modules.forEach((module) => {
        if (event.target.checked) {
          module.userAssignedPolicy = module.moduleAssignedPolicy;
        } else {
          module.userAssignedPolicy = [];
        }
      });
    });
    setFunctionalities(functionalitiesTemp);
  };

  const toggleRow = (event, functionalityId) => {
    const functionalitiesTemp = JSON.parse(JSON.stringify(functionalities));
    functionalitiesTemp.forEach((functionality) => {
      if (functionality.functionalityId === functionalityId) {
        functionality.modules.forEach((module) => {
          if (event.target.checked) {
            module.userAssignedPolicy = module.moduleAssignedPolicy;
          } else {
            module.userAssignedPolicy = [];
          }
        });
      }
    });
    setFunctionalities(functionalitiesTemp);
  };

  const toggleCol = (event, policyId) => {
    const functionalitiesTemp = JSON.parse(JSON.stringify(functionalities));
    functionalitiesTemp.forEach((functionality) => {
      functionality.modules.forEach((module) => {
        if (
          event.target.checked &&
          module.moduleAssignedPolicy?.includes(policyId) &&
          !module.userAssignedPolicy?.includes(policyId)
        ) {
          module.userAssignedPolicy.push(policyId);
        } else if (
          !event.target.checked &&
          module.userAssignedPolicy?.includes(policyId)
        ) {
          const index = module.userAssignedPolicy.indexOf(policyId);
          module.userAssignedPolicy.splice(index, 1);
        }
      });
    });
    setFunctionalities(functionalitiesTemp);
  };
  return (
    <>
      <div className="table-content-wrapper height-58 overflow-auto col-span-2">
        <table striped="true" className="w-full">
          <thead>
            <tr>
              <th style={{ width: "5%" }} align="center">
                <input type="checkbox" onChange={toggleAll} />
              </th>
              <th style={{ width: "20%" }} align="left">
                Functionality
              </th>
              <th style={{ width: "15%" }} align="left">
                Modules
              </th>
              {policies.map((policy) => {
                return (
                  <th style={{ width: "10%" }} key={policy.policyId}>
                    <p>{policy.policyName}</p>
                    <input
                      type="checkbox"
                      onChange={(event) => toggleCol(event, policy.policyId)}
                    />
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {functionalities.map((functionality) => {
              return functionality.modules.map((module, index) => {
                return (
                  <tr
                    key={functionality.functionalityId + "_" + module.moduleId}
                  >
                    <td
                      align="center"
                      key={module.moduleId}
                      className="pr-[10px]"
                    >
                      {index === 0 ? (
                        <input
                          type="checkbox"
                          onChange={(event) =>
                            toggleRow(event, functionality.functionalityId)
                          }
                        />
                      ) : null}
                    </td>
                    <td>
                      {index === 0 ? functionality.functionalityName : ""}
                    </td>
                    <td>{module.moduleName}</td>
                    {policies.map((policy) => {
                      return (
                        <td
                          align="center"
                          key={policy.policyId}
                          className="pr-[10px]"
                        >
                          {module.moduleAssignedPolicy?.includes(
                            policy.policyId
                          ) ? (
                            <input
                              type="checkbox"
                              checked={module.userAssignedPolicy?.includes(
                                policy.policyId
                              )}
                              onChange={() => {
                                handleToggle(
                                  functionality.functionalityId,
                                  module.moduleId,
                                  policy.policyId
                                );
                              }}
                            />
                          ) : null}
                        </td>
                      );
                    })}
                  </tr>
                );
              });
            })}
          </tbody>
        </table>
      </div>
      <div className="flex items-center justify-end col-span-2 mt-3 gap-4">
        <Button
          text="Cancel"
          onClick={() => {
            navigate("/users/manage");
          }}
        />
        <Button text="Save" onClick={handleEdit} />
      </div>
    </>
  );
};

export default EditUserPolicy;
