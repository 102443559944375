import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../components/button";
import TableWithSearchAndPagination from "../../components/tableWithSearchAndPagination";
import WithAuth from "../../layout/withAuth";
import WithHeaderSidebar from "../../layout/withHeaderSidebar";
import { deletePatientById, getPatientListPage } from "../../services/patient";
import dayjs from "dayjs";
import CheckPermission from "../../components/checkPermission";
import Modal from "../../components/modal";
import DangerBtn from "../../components/dangerBtn";
import SecondaryBtn from "../../components/secondaryBtn";

const headings = [
  {
    label: "Patient ID",
    width: "10%",
    align: "left",
  },
  {
    label: "Patient Name",
    width: "20%",
    align: "left",
  },
  {
    label: "Registration Date",
    width: "15%",
    align: "left",
  },
  {
    label: "Contact",
    width: "15%",
    align: "left",
  },
  {
    label: "Actions",
    width: "10%",
    align: "center",
  },
];

const dataToShow = [
  {
    key: "patientId",
    align: "left",
  },
  {
    key: "fullName",
    align: "left",
  },
  {
    key: "patientRegistrationDate",
    align: "left",
  },
  {
    key: "primaryContactNumber",
    align: "left",
  },
];

const ManagePatient = () => {
  const navigate = useNavigate();
  const [totalData, setTotalData] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [callbackAttributes, setCallbackAttributes] = useState({
    searchSend: "",
    perPage: "25",
    selectedPage: "0",
    sort: "date,desc",
  });
  const [phoneCallList, setPatientList] = useState([]);
  const [selectedPatientId, setSelectedPatientId] = useState(0);
  const [isModalVisible, setModalVisible] = useState(false);
  const openModal = (aptId) => {
    setSelectedPatientId(aptId);
    setModalVisible(true)
  };
  const closeModal = () => setModalVisible(false);

  const displayPatientList = useCallback(() => {
    setPatientList([]);
    const { searchSend, selectedPage, perPage, sort } = callbackAttributes;
    let data = `searchKey=${searchSend}&page=${selectedPage}&size=${perPage}&sort=${sort}`;
    getPatientListPage(data).then((res) => {
      if (res?.status === 200) {
        const patientList = res?.result?.patientList?.map((item) => {
          return {
            ...item,
            fullName:
              item.firstName + " " + item.middleName + " " + item.lastName,
            patientRegistrationDate: dayjs(item.patientRegistrationDate).format("DD/MM/YYYY"),
          };
        });
        setPatientList(patientList || []);
        setTotalData(res?.result?.getTotalRecords || 0);
        setTotalPages(res?.result?.getTotalPages || 0);
      } else {
        // props?.setSnackbar({
        //     showSnackbar: true,
        //     message: res?.message,
        //     variant: "error"
        // })
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callbackAttributes]);

  useEffect(() => {
    displayPatientList();
  }, [displayPatientList, callbackAttributes]);

  const handleDelete = (item) => {
    openModal(item.patientId);
  };

  const handleEdit = (item) => {
    navigate("/patients/edit/" + item.patientId);
  };

  return (
    <>
      <Modal
        isVisible={isModalVisible}
        onClose={closeModal}
      >
        <div className="flex justify-center mb-4">
          <i className="far fa-times-circle fa-3x text-[#D42A46] "></i>
        </div>
        <p className="text-center mb-4">Are you sure, you want to delete Patient ?</p>
        <div className="flex justify-around">
          <DangerBtn
            text="DELETE"
            onClick={() => {
              if (selectedPatientId !== 0) {
                deletePatientById(selectedPatientId).then((res) => {
                  if (res.status === 200) {
                    closeModal();
                    displayPatientList();
                  }
                });
              } else {
                closeModal();
              }
            }}
          />
          <SecondaryBtn
            text="CANCEL"
            onClick={closeModal}
          />
        </div>
      </Modal>
      <div className="h-full ">
        <div className="p-7">
          <p className="text-xl md:text-2xl md:leading-6 text-[#404040] font-bold">
            Patient
          </p>
        </div>
        <div className="bg-[#F7F8F9] sm:pt-[18px] p-5 sm:pl-7 sm:pr-11 sm:pb-9">
          <div className="bg-white w-full h-full shadow-[0px_9px_20px] shadow-[#2E235E1A] rounded-[15px]">
            <div className="flex flex-row items-center justify-between p-3 sm:p-7 border-b ">
              <p className="text-base md:text-xl leading-6 text-[#404040] font-bold">
                Patient List
              </p>
              <CheckPermission module="Manage Patient" permission="Add">
                <Button
                  text="New Entry"
                  onClick={() => {
                    navigate("/patients/register");
                  }}
                />
              </CheckPermission>
            </div>
            <div className="p-3 sm:p-7 sm:pt-5">
              <TableWithSearchAndPagination
                headings={headings}
                dataToShow={dataToShow}
                data={phoneCallList}
                totalData={totalData}
                totalPages={totalPages}
                actions={["delete", "edit", "history"]}
                setCallbackAttributes={setCallbackAttributes}
                handleActionEdit={handleEdit}
                handleActionDelete={handleDelete}
                handleActionHistory={()=>{}}
                module="Manage Patient"
                actionPermission={{
                  edit: "Edit",
                  delete: "Delete",
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WithAuth(WithHeaderSidebar(ManagePatient));
