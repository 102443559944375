import axiosInterceptors from "../../axiosInterceptor/axiosInterceptor";
import { functionalityUrl } from "../../config/apiConfig";

export const createFunctionality = (data) => {
  return axiosInterceptors()
    .post(functionalityUrl + "/createFunctionality", data)
    .then((res) => res);
};

export const functionalityListPage = (data) => {
  return axiosInterceptors()
    .get(functionalityUrl + "/functionalityListPage?" + data)
    .then((res) => res);
};

export const getFunctionalityById = (id) => {
  return axiosInterceptors()
    .get(functionalityUrl + "/" + id)
    .then((res) => res);
};

export const updateFunctionalityById = (data) => {
  return axiosInterceptors()
    .put(functionalityUrl + "/" + data.functionalityId, data)
    .then((res) => res);
};

export const deleteFunctionalityById = (id) => {
  return axiosInterceptors()
    .delete(functionalityUrl + "/" + id)
    .then((res) => res);
};

export const getAllFunctionalities = () => {
  return axiosInterceptors()
    .get(functionalityUrl + "/getAllFunctionalities")
    .then((res) => res);
};
