import React from "react";

const SuccessBtn = ({
    text = "",
    textStyle = "",
    buttonStyle = "",
    disabled = false,
    onClick = () => { },
    ...props
}) => {
    return (
        <button
            onClick={onClick}
            disabled={disabled}
            className={`${disabled ? "bg-[#CED4DA]" : "bg-[#118C42]"
                }  py-[5px] px-4 rounded-[5px] ${buttonStyle}`}
        >
            <p
                className={`text-sm leading-[21px] text-center text-white ${textStyle}`}
            >
                <i className="fas fa-check-circle text-white mr-2"></i>
                {text}
            </p>
        </button>
    );
};

export default SuccessBtn;
