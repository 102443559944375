import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const DatePicker = (
  {
    label = "",
    labelStyle = "",
    value = "",
    rightIcon = <i className="fa-solid fa-calendar-days h-3 w-3"></i>,
    name = "",
    onChange = () => { },
    className = "",
    disabled = false,
    validations = [],
    ...props
  },
  ref
) => {
  const [openPicker, setOpenPicker] = useState(false);
  const [error, setError] = useState("");

  useImperativeHandle(ref, () => {
    return {
      validate: () => validate(),
      setError: (e) => setError(e),
    };
  });


  useEffect(() => {
    if (value) {
      setError(false)
    }
  }, [value])

  const handleChange = (date) => {
    setError(false);
    onChange && onChange(name, date);
  };

  const validate = () => {
    let isValid = true;
    for (let i = 0; i < validations.length; i++) {
      const validation = validations[i];
      if (validation.type === "required") {
        if (!value) {
          setError(validation.message);
          isValid = false;
          break;
        }
      } else if (validation.type === "regex") {
        if (!new RegExp(validation.regex).test(value)) {
          setError(validation.message);
          isValid = false;
          break;
        }
      }
    }
    return isValid;
  };
  return (
    <div className={`flex flex-col w-full ${className}`}>
      {label ? (
        <p
          className={`text-sm leading-[21px] text-[#404040] mb-2 ${labelStyle}`}
        >
          {label}
        </p>
      ) : null}
      <div className="flex justify-between  border border-[#CED4DA] rounded-[5px] w-full">
        <ReactDatePicker
          selected={value}
          className="outline-none rounded-[5px] py-[6px] px-[14px] bg-transparent w-full"
          onChange={(date) => {
            handleChange(date);
            setOpenPicker(false);
          }}
          closeOnScroll={true}
          dateFormat="dd/MM/yyyy"
          name={name}
          disabled={true}
          open={openPicker}
          onClickOutside={() => {
            setOpenPicker(false)
          }}
          {...props}
        />
        {rightIcon ? (
          <div
            className="flex justify-center items-center p-[9px] bg-[#E9ECEF] rounded-r-[5px] border-l border-[#CED4DA]"
            onClick={() => {
              if (disabled) {
                return
              }
              setOpenPicker(true);
            }}
          >
            {rightIcon}
          </div>
        ) : null}
      </div>
      {error ? (
        <span className="text-sm text-red-500">{error}</span>
      ) : (
        <span className="h-5" />
      )}
    </div>
  );
};
export default forwardRef(DatePicker);
