import React, { useEffect, createRef, useState } from "react";
import TextInput from "../../../components/textInput";
import Dropdown from "../../../components/dropdown";
import TextArea from "../../../components/textArea";
import Button from "../../../components/button";
import { OtherFeesSection } from "./util";
import { useNavigate } from "react-router-dom";
import { addNewPatient, updatePatientById } from "../../../services/patient";
import { getDoctorById } from "../../../services/doctor";
import Modal from "../../../components/modal";
import SuccessBtn from "../../../components/successBtn";

const paymentModeOptions = [
  {
    label: "Cash",
    value: "Cash",
  },
  {
    label: "Cheque",
    value: "Cheque",
  },
];

const PaymentInformation = (props) => {
  const navigate = useNavigate();
  const [info, setInfo] = useState({
    fees: "",
    paymentMode: "Cash",
    transactionId: "",
    remarks: "",
    otherFees1: "",
    otherFees2: "",
    otherFees3: "",
    otherFees4: "",
    insuranceDetailsJson: {
      insuranceCompany: "",
      policyNumber: "",
      groupNumber: ""

    },
    feesDetailsJson: {
      otherFees1: "0",
      otherFees2: "0",
      otherFees3: "0",
      otherFees4: "0",
      totalFees: "0",
      paymentMode: "Cash",
      transactionId: ""
    }
  });
  const [otherFeesOptions, setOtherFeesOptions] = useState([]);
  const [otherFees, setOtherFees] = useState([]);
  const [totalFee, setTotalFee] = useState(0);
  const [isModalVisible, setModalVisible] = useState(false);
  const openModal = () => {
    setModalVisible(true)
  };
  const closeModal = () => setModalVisible(false);

  useEffect(() => {
    if (props.info) {
      const patientInfo = props.info;
      setInfo({
        fees: patientInfo.fees,
        paymentMode: patientInfo.paymentMode,
        transactionId: patientInfo.transactionId,
        remarks: patientInfo.remarks,
        insuranceDetailsJson: patientInfo.hasOwnProperty("insuranceDetailsJson") ? patientInfo.insuranceDetailsJson : { insuranceCompany: "", policyNumber: "", groupNumber: "" },
        feesDetailsJson: patientInfo.hasOwnProperty("feesDetailsJson") ? patientInfo.feesDetailsJson : { otherFees1: "0", otherFees2: "0", otherFees3: "0", otherFees4: "0", totalFees: "0", paymentMode: "Cash", transactionId: "" },
      });
      const otherFees = patientInfo?.otherFees?.split(",");
      otherFees?.forEach((item, index) => {
        setInfo((prev) => {
          return {
            ...prev,
            [`otherFees${index + 1}`]: item,
          };
        });
        const otherFeesOptionsTemp = JSON.parse(
          JSON.stringify(otherFeesOptions)
        );
        otherFeesOptionsTemp.push(index);
        setOtherFeesOptions(otherFeesOptionsTemp);
      });
      let k = Object.keys(info.feesDetailsJson);
      k = k.filter((e) => (e !== "totalFees" && e !== "paymentMode" && e !== "transactionId"));
      let t = k.map((e, i) => {
        return {
          otherFeeLabel: e,
          otherFeeValue: info.feesDetailsJson[e],
          labelRef: createRef(),
          valueRef: createRef()
        }
      })
      setOtherFees(t);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.info]);
  useEffect(() => {
    calculateTotalFee();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [info.fees]);
  const handleChange = (name, value) => {
    setInfo((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };
  const handleNextClick = () => {
    // props.goToStep(4);
    let otherFees = [];
    otherFeesOptions.forEach((option) => {
      const value = info[`otherFees${option}`];
      otherFees.push(value);
    });
    let validate = true;
    otherFees.forEach(e => {
      if (e.otherFeeLabel === "") {
        e.labelRef.current.focus();
        e.labelRef.current.style = "outline: 1px red solid"
        validate = false;
        return false;
      }
      if (e.otherFeeValue === "" || e.otherFeeValue === 0 || e.otherFeeValue === "0") {
        e.valueRef.current.focus();
        e.valueRef.current.style = "outline: 1px red solid"
        validate = false;
        return false;
      }
    })
    if (!validate)
      return;
    let otherFeesTemp = {};
    otherFees.forEach(e => {
      otherFeesTemp[e.otherFeeLabel] = e.otherFeeValue;
    })
    const payload = {
      ...props.patientRegInfo,
      fees: parseInt(info.fees),
      paymentMode: info.paymentMode,
      transactionId: info.transactionId,
      remarks: info.remarks,
      otherFees: otherFees.join(","),
      insuranceDetailsJson: info.insuranceDetailsJson,
      feesDetailsJson: { ...otherFeesTemp, totalFees: totalFee }
    };
    if (props.patientRegInfo?.patientId !== 0 && props.patientRegInfo?.patientId !== '0') {
      updatePatientById(props.patientRegInfo?.patientId, payload).then((res) => {
        if (res.status === 200) {
          openModal();
          // navigate("/patients/manage");
        }
      })
    } else {
      addNewPatient(payload).then((res) => {
        if (res.status === 200) {
          openModal();
          // navigate("/patients/manage");
        }
      });
    }
  };
  const handleNextPrintClick = () => {
    let validate = true;
    otherFees.forEach(e => {
      if (e.otherFeeLabel === "") {
        e.labelRef.current.focus();
        e.labelRef.current.style = "outline: 1px red solid"
        validate = false;
        return false;
      }
      if (e.otherFeeValue === "" || e.otherFeeValue === 0 || e.otherFeeValue === "0") {
        e.valueRef.current.focus();
        e.valueRef.current.style = "outline: 1px red solid"
        validate = false;
        return false;
      }
    })
    if (!validate)
      return;
    let otherFeesTemp = {};
    otherFees.forEach(e => {
      otherFeesTemp[e.otherFeeLabel] = e.otherFeeValue;
    })
    let payload = {
      ...props.patientRegInfo,
      fees: parseInt(info.fees),
      paymentMode: info.paymentMode,
      transactionId: info.transactionId,
      remarks: info.remarks,
      otherFees: otherFees.join(","),
      insuranceDetailsJson: info.insuranceDetailsJson,
      feesDetailsJson: { ...otherFeesTemp, totalFees: totalFee }
    };
    addNewPatient(payload).then((res) => {
      if (res.status === 200) {
        const patientInfo = res.result.patientDto;
        payload = {
          ...payload,
          appointmentId: patientInfo.appointmentId,
          appointmentDate: patientInfo.date.split(" ")[0],
          appointmentTime: patientInfo.time,
          patientName: `${patientInfo?.firstName} ${patientInfo?.lastName}`,
          priority: patientInfo.priority,
          patientAge: patientInfo.age,
          patientGender: patientInfo.gender,
          maritalStatus: patientInfo.maritalStatus,
          patientOccupation: patientInfo.occupation
        }
        getDoctorById(payload.doctorId).then(res2 => {
          if (res2.status === 200) {
            payload = { ...payload, doctorName: res2.result.doctorDetails.docName };
            sessionStorage.setItem("pdfData", JSON.stringify(payload))
            window.open("/opdReportPdf");
            navigate("/opd-appointment/manage");
          }
        })
        navigate("/patients/manage");
      }
    });
  }
  const handleBackClick = () => {
    props.goToStep(4);
  };
  const calculateTotalFee = () => {
    let temp = parseFloat(info.fees);
    otherFees.forEach(element => {
      temp += parseFloat(element.otherFeeValue);
    });
    setTotalFee(isNaN(temp) ? 0 : temp);
  }
  if (typeof props.id !== "undefined") {
    return <></>
  } else {
    return (
      <>
        <Modal
          isVisible={isModalVisible}
          onClose={closeModal}
        >
          <div className="flex justify-center mb-4">
            <i className="far fa-check-circle fa-2x text-[#118C42] "></i>
          </div>
          <p className="text-center mb-4">Patient Registered Successfully.</p>
          <div className="flex justify-center">
            <SuccessBtn
              text="DONE"
              onClick={() => {
                closeModal();
                navigate("/patients/manage");
              }}
            />
          </div>
        </Modal>
        {
          <div className="p-5 lg:px-[30px] lg:pb-9 lg:pt-6">
            <div className={"grid grid-cols-1 md:grid-cols-3 md:gap-4 lg:gap-6 xl:gap-9"}>
              <div className="">
                <TextInput
                  label="Fees"
                  leftIcon={<span>₹</span>}
                  onChange={handleChange}
                  value={info.fees}
                  name="fees"
                />
                <Dropdown
                  label="Payment Mode"
                  placeholder="Payment Mode"
                  options={paymentModeOptions}
                  value={info.feesDetailsJson.paymentMode}
                  onChange={(val) => {
                    setInfo((prev) => {
                      return {
                        ...prev,
                        feesDetailsJson: {
                          ...prev.feesDetailsJson,
                          paymentMode: val.value
                        }
                      }
                    })
                  }}
                />
                <TextInput
                  label="Cheque No / Transaction ID"
                  placeholder="Cheque No / Transaction ID"
                  value={info.feesDetailsJson.transactionId}
                  name="transactionId"
                  onChange={(name, val) => {
                    setInfo((prev) => {
                      return {
                        ...prev,
                        feesDetailsJson: {
                          ...prev.feesDetailsJson,
                          [name]: val
                        }
                      }
                    });
                  }}
                />
                <TextArea
                  label="Remark"
                  rows={3}
                  value={info.remarks}
                  name="remarks"
                  onChange={handleChange}
                />
              </div>
              <OtherFeesSection
                otherFees={otherFees}
                setOtherFees={setOtherFees}
                mainFee={info.fees}
                totalFee={totalFee}
                setTotalFee={setTotalFee}
                calculateTotalFee={calculateTotalFee}
              />
            </div>
            <div className="flex flex-col md:flex-row md:items-center justify-between md:mt-10 gap-5 md:gap-0">
              <div>
                <Button
                  text="Previous"
                  textStyle="mx-5 md:mx-10"
                  onClick={handleBackClick}
                />
              </div>
              <div className="flex md:items-center gap-2 sm:gap-7">
                <Button
                  text="Book Appointment"
                  textStyle="mx-3 md:mx-10"
                  onClick={handleNextClick}
                />
                <Button
                  text="Book Appointment And Print"
                  textStyle="mx-3 md:mx-10"
                  onClick={handleNextPrintClick}
                />
              </div>
            </div>
          </div>
        }
      </>

    );
  }
};

export default PaymentInformation;
