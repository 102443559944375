import React, { forwardRef, useImperativeHandle, useState } from "react";

const CheckboxGroup = (
  {
    className = "",
    label = "",
    name = "",
    options = [],
    optionsContainerStyle = "",
    value = "",
    onChange = () => {},
    validations = [],
    inputStyle = "",
  },
  ref
) => {
  const [error, setError] = useState("");
  useImperativeHandle(ref, () => {
    return {
      validate: () => validate(),
      setError: (e) => setError(e),
    };
  });

  const handleChange = (event) => {
    // if (event.target.checked) {
    //   setError("");
    onChange(name, event.target.value);
    // }
  };

  const validate = () => {
    let isValid = true;
    for (let i = 0; i < validations.length; i++) {
      const validation = validations[i];
      if (validation.type === "required") {
        if (!value) {
          setError(validation.message);
          isValid = false;
          break;
        }
      } else if (validation.type === "regex") {
        if (!new RegExp(validation.regex).test(value)) {
          setError(validation.message);
          isValid = false;
          break;
        }
      }
    }
    return isValid;
  };

  return (
    <div className={`${className}`}>
      <p className="text-sm leading-[21px] text-[#404040]">{label}</p>
      <div
        className={`${
          optionsContainerStyle
            ? optionsContainerStyle
            : "flex flex-col gap-[25px] mt-2"
        }`}
      >
        {options?.map((option) => {
          return (
            <div className="flex flex-row items-center" key={option.value}>
              <input
                name={name}
                type="checkbox"
                className={
                  inputStyle
                    ? inputStyle
                    : `h-[29px] w-[29px] rounded-[5px] outline-[#CED4DA]`
                }
                value={option.value}
                onChange={handleChange}
                checked={value.includes(option.value) ? true : false}
                size="small"
              />
              <p className="text-sm leading-[21px] text-[#404040] ml-4">
                {option.label}
              </p>
            </div>
          );
        })}
      </div>
      {error ? (
        <span className="text-sm text-red-500">{error}</span>
      ) : (
        <span className="h-5" />
      )}
    </div>
  );
};

export default forwardRef(CheckboxGroup);
