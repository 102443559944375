import { useNavigate } from "react-router-dom";
import UserInfo from "./userInfo";
import Button from "../../components/button";
import { editUser, getUserById } from "../../services/user";
import { createRef, useEffect, useRef, useState } from "react";

const EditUserInfo = ({ userId, userDetails, resetUser = () => {} }) => {
  const navigate = useNavigate();
  const [userInfo, setUserInfo] = useState({
    firstName: "",
    lastName: "",
    userName: "",
    password: "",
    mobileNumber: "",
    roleId: "",
  });
  const inputRefs = useRef([
    createRef(),
    createRef(),
    createRef(),
    createRef(),
    createRef(),
    createRef(),
  ]);

  const handleChange = (name, value) => {
    setUserInfo((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  useEffect(() => {
    if (userId) {
      getUserById(userId).then((res) => {
        if (res.status === 200) {
          const {
            firstName,
            lastName,
            userName,
            roleId,
            mobileNumber,
            password,
          } = res?.result?.user;
          setUserInfo({
            firstName,
            lastName,
            userName,
            roleId,
            mobileNumber,
            password,
          });
        }
      });
    }
  }, [userId]);

  const handleRegister = () => {
    let isValid = true;
    for (let i = 0; i < inputRefs.current.length; i++) {
      const valid = inputRefs.current[i].current.validate();
      if (!valid) {
        isValid = false;
      }
    }
    if (!isValid) {
      return;
    }
    editUser({ userId, ...userInfo }).then((res) => {
      if (res.status === 200) {
        if (userDetails.userId.toString() === userId) {
          resetUser();
          sessionStorage.removeItem("token");
          navigate("/");
        } else {
          navigate("/users/manage");
        }
      } else {
      }
    });
  };

  return (
    <>
      <UserInfo
        userInfo={userInfo}
        handleChange={handleChange}
        inputRefs={inputRefs}
        editMode={true}
      />
      <div className="flex items-center justify-end gap-5 col-span-2">
        <Button
          text="Cancel"
          onClick={() => {
            navigate("/users/manage");
          }}
        />
        <Button text="Submit" onClick={handleRegister} />
      </div>
    </>
  );
};

export default EditUserInfo;
