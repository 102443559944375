import React, {
  createRef,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import TableWithSearchAndPagination from "../../components/tableWithSearchAndPagination";
import Button from "../../components/button";
import WithHeaderSidebar from "../../layout/withHeaderSidebar";
import WithAuth from "../../layout/withAuth";
import {
  deleteFunctionalityById,
  functionalityListPage,
  updateFunctionalityById,
} from "../../services/permissions/functionality";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import TextInput from "../../components/textInput";
import { createFunctionality } from "../../services/permissions/functionality";
import CheckPermission from "../../components/checkPermission";

const headings = [
  {
    label: "Functionality Id",
    width: "20%",
    align: "left",
  },
  {
    label: "Functionality Name",
    width: "60%",
    align: "left",
  },
  {
    label: "Actions",
    width: "20%",
    align: "center",
  },
];

const dataToShow = [
  {
    key: "functionalityId",
    align: "left",
  },
  {
    key: "functionalityName",
    align: "left",
  },
];

const Functionality = () => {
  const [totalData, setTotalData] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [callbackAttributes, setCallbackAttributes] = useState({
    searchSend: "",
    perPage: "25",
    selectedPage: "0",
    sort: "",
  });
  const [rolesList, setFunctionalityList] = useState([]);
  const [functionalityInfo, setFuncationalityInfo] = useState({
    functionalityId: 0,
    functionalityName: "",
  });
  const ref = useRef(createRef());
  const [openModal, setOpenModal] = useState(false);

  const displayFuncationalityList = useCallback(() => {
    setFunctionalityList([]);
    const { searchSend, selectedPage, perPage, sort } = callbackAttributes;
    let data = `searchKey=${searchSend}&page=${selectedPage}&size=${perPage}&sort=${sort}`;
    functionalityListPage(data).then((res) => {
      if (res?.status === 200) {
        setFunctionalityList(res?.result?.functionalityList || []);
        setTotalData(res?.result?.getTotalRecords || 0);
        setTotalPages(res?.result?.getTotalPages || 0);
      } else {
        // props?.setSnackbar({
        //     showSnackbar: true,
        //     message: res?.message,
        //     variant: "error"
        // })
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callbackAttributes]);

  useEffect(() => {
    displayFuncationalityList();
  }, [displayFuncationalityList, callbackAttributes]);

  const handleDelete = (item) => {
    deleteFunctionalityById(item.functionalityId).then((res) => {
      if (res.status === 200) {
        displayFuncationalityList();
      }
    });
  };

  const handleEdit = (item) => {
    setFuncationalityInfo({
      functionalityId: item.functionalityId,
      functionalityName: item.functionalityName,
    });
    setOpenModal(true);
  };

  const handleAddAndEdit = () => {
    const isValid = ref.current.validate();
    if (!isValid) {
      return;
    }
    if (functionalityInfo.functionalityId === 0) {
      createFunctionality({
        functionalityName: functionalityInfo.functionalityName,
      }).then((res) => {
        if (res.status === 200) {
          setOpenModal(false);
          displayFuncationalityList();
        }
      });
    } else {
      updateFunctionalityById(functionalityInfo).then((res) => {
        if (res.status === 200) {
          setOpenModal(false);
          displayFuncationalityList();
        }
      });
    }
  };

  const handleModalClose = () => {
    setFuncationalityInfo({
      functionalityId: 0,
      functionalityName: "",
    });
    setOpenModal(false);
  };
  return (
    <div className="h-full">
      <div className="p-5 sm:pl-8 sm:pt-7 sm:pb-[23px]">
        <p className="text-xl md:text-2xl md:leading-6 text-[#404040] font-bold">
          Functionality
        </p>
      </div>
      <div className="bg-[#F7F8F9] sm:pt-[18px] p-5 sm:pl-7 sm:pr-11 sm:pb-9">
        <div className="bg-white w-full h-full shadow-[0px_9px_20px] shadow-[#2E235E1A] rounded-[15px]">
          <div className="flex flex-row items-center justify-between p-3 sm:p-7 border-b ">
            <p className="text-base md:text-xl leading-6 text-[#404040] font-bold">
              Functionality List
            </p>
            <CheckPermission module="Functionality" permission="Add">
              <Button
                text="New Entry"
                onClick={() => {
                  setFuncationalityInfo({
                    functionalityId: 0,
                    functionalityName: "",
                  });
                  setOpenModal(true);
                }}
              />
            </CheckPermission>
          </div>
          <div className="p-3 sm:p-7 sm:pt-5">
            <TableWithSearchAndPagination
              headings={headings}
              dataToShow={dataToShow}
              data={rolesList}
              totalData={totalData}
              totalPages={totalPages}
              actions={["delete", "edit"]}
              setCallbackAttributes={setCallbackAttributes}
              handleActionEdit={handleEdit}
              handleActionDelete={handleDelete}
              module="Functionality"
              actionPermission={{
                edit: "Edit",
                delete: "Delete",
              }}
            />
          </div>
        </div>
      </div>
      <Dialog
        open={openModal}
        TransitionProps={{
          style: {
            height: "auto",
          },
        }}
        fullWidth={true}
        maxWidth="sm"
      >
        <DialogTitle>
          <p className="text-base md:text-xl leading-6 text-[#404040] font-bold">
            {functionalityInfo.functionalityId === 0
              ? "Add Functionality"
              : "Edit Functionality"}
          </p>
        </DialogTitle>
        <DialogContent dividers>
          <TextInput
            validations={[
              { type: "required", message: "Please enter funcationality name" },
            ]}
            value={functionalityInfo.functionalityName}
            ref={ref}
            label="Functionality Name"
            onChange={(name, value) => {
              setFuncationalityInfo((prev) => {
                return {
                  ...prev,
                  functionalityName: value,
                };
              });
            }}
          />
        </DialogContent>
        <DialogActions>
          <div className="gap-2 flex">
            <Button text="Cancel" onClick={handleModalClose} />
            <Button
              text={functionalityInfo.functionalityId === 0 ? "Add" : "Edit"}
              onClick={handleAddAndEdit}
            />
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default WithAuth(WithHeaderSidebar(Functionality));
