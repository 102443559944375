import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const AccountCreated = () => {
  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      navigate("/");
    }, 2000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      className="flex flex-col md:flex-row h-screen w-screen"
      style={{
        backgroundImage: "url(/images/auth_screens_Background.png)",
        backgroundSize: "cover",
      }}
    >
      <div className="md:flex items-center justify-center md:w-[50%] bg-white opacity-90 hidden">
        <img
          src="/images/Pulse_Logo_Auth_Screens.png"
          className="w-[192px] h-[232px]"
          alt="logo"
        />
      </div>
      <div className="flex flex-col md:w-[50%] h-screen bg-[#f3f3ffe6] items-center sm:justify-center p-5 md:px-10 lg:px-28">
        <img
          src="/images/Pulse_Logo_Auth_Screens.png"
          className="w-[132px] h-[160px] m-5 md:hidden"
          alt="logo"
        />
        <div className="flex flex-col bg-white p-8 sm:px-10 sm:pt-[71px] sm:pb-[75px] w-full rounded-[10px] shadow-2xl">
          <p className="text-2xl leading-7 text-[#000000] font-bold">
            Congratulations
          </p>
          <p className="text-sm leading-[17px] text-[#898989] font-bold mt-2">
            Your Account Created Successfully
          </p>
        </div>
      </div>
    </div>
  );
};

export default AccountCreated;
