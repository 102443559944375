import React, { createRef, useRef, useState } from "react";
import WithAuth from "../../layout/withAuth";
import WithHeaderSidebar from "../../layout/withHeaderSidebar";
import Button from "../../components/button";
import { useNavigate } from "react-router-dom";
import { createAccount } from "../../services/auth";
import UserInfo from "./userInfo";

const AddUser = () => {
  const navigate = useNavigate();
  const [userInfo, setUserInfo] = useState({
    firstName: "",
    lastName: "",
    userName: "",
    password: "",
    mobileNumber: "",
    roleId: "",
  });
  const inputRefs = useRef([
    createRef(),
    createRef(),
    createRef(),
    createRef(),
    createRef(),
    createRef(),
  ]);

  const handleChange = (name, value) => {
    setUserInfo((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  const handleRegister = () => {
    let isValid = true;
    for (let i = 0; i < inputRefs.current.length; i++) {
      const valid = inputRefs.current[i].current.validate();
      if (!valid) {
        isValid = false;
      }
    }
    if (!isValid) {
      return;
    }
    createAccount(userInfo).then((res) => {
      if (res.status === 200) {
        navigate("/users/manage");
      } else {
      }
    });
  };

  return (
    <div className="h-full">
      <div className="p-5 sm:pl-8 sm:pt-7 sm:pb-[23px]">
        <p className="text-xl md:text-2xl md:leading-6 text-[#404040] font-bold">
          Users
        </p>
      </div>
      <div className="bg-[#F7F8F9] sm:pt-[18px] p-5 sm:pl-7 sm:pr-11 sm:pb-9">
        <div className="bg-white w-full h-full shadow-[0px_9px_20px] shadow-[#2E235E1A] rounded-[15px]">
          <div className="flex flex-row items-center justify-between p-3 sm:p-7 border-b ">
            <p className="text-base md:text-xl leading-6 text-[#404040] font-bold">
              Add User
            </p>
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-2 sm:gap-x-5 p-3 sm:p-7 sm:pt-5">
            <UserInfo
              userInfo={userInfo}
              handleChange={handleChange}
              inputRefs={inputRefs}
            />
            <div className="flex items-center justify-end gap-5 col-span-2">
              <Button
                text="Cancel"
                onClick={() => {
                  navigate("/users/manage");
                }}
              />
              <Button text="Submit" onClick={handleRegister} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WithAuth(WithHeaderSidebar(AddUser));
