import { combineReducers } from "redux";
import { loaderReducer } from "./loaderReducer";
import { globalAlertReducer } from "./globalAlertReducer";
import { confirmDialogReducer } from "./confirmDialogReducer";
import { userReducer } from "./userReducer";

export default combineReducers({
  globalAlert: globalAlertReducer,
  confirmDialog: confirmDialogReducer,
  loader: loaderReducer,
  user: userReducer
});
