import axios from "axios";
import store from "../store";
import { setLoader } from "../actions/loaderActions";

const axiosInterceptors = (loader = true) => {
  const baseURL = process.env.REACT_APP_MAIN_BASE_URL;

  let headers = {
    "Access-Control-Allow-Headers": "Content-Type",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "DELETE,GET,HEAD,OPTIONS,PATCH,POST,PUT",
  };

  if (sessionStorage.getItem("token")) {
    headers.Authorization = "Bearer " + sessionStorage.getItem("token");
  }

  const axiosInterceptors = axios.create({
    baseURL: baseURL,
    headers,
    validateStatus: function (status) {
      return status <= 500;
    },
  });
  axiosInterceptors.interceptors.request.use(
    (config) => {
      if (loader) {
        store.dispatch(
          setLoader({
            load: true,
            text: "Please wait !!!",
          })
        );
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
  axiosInterceptors.interceptors.response.use(
    (response) => {
      if (typeof response.data.result != "undefined") {
        if (response.data.result.token) {
          if (sessionStorage.getItem("token")) {
            sessionStorage.removeItem("token");
            sessionStorage.setItem("token", response.data.result.token);
          } else {
            sessionStorage.setItem("token", response.data.result.token);
          }
        }
      }
      setTimeout(() => {
        store.dispatch(
          setLoader({
            load: false,
          })
        );
      }, 1000);
      return new Promise((resolve, reject) => {
        resolve(response.data);
      });
    },
    (error) => {
      return new Promise((resolve, reject) => {
        reject(error);
      });
    }
  );

  return axiosInterceptors;
};

export default axiosInterceptors;
