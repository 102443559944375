import React, { useState, useEffect, useRef, createRef } from "react";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import { Tab, Tabs } from "@mui/material";
import WithHeaderSidebar from "../../layout/withHeaderSidebar";
import WithAuth from "../../layout/withAuth";
import { getOpdAppointmentById } from "../../services/opdAppointment";
import { getPatientById } from "../../services/patient";
import { getDoctorById } from "../../services/doctor";
import { createPatientOpdLifeCycle, getPatientHistory, checkEditablePatientOpdLifeCycle, updatePatientOpdLifeCycleById } from "../../services/patientOpdLifeCycle";
import { Accordion, ComplaintDiagnosisSection, FeesSection, IntervalHistorySection, PatientInfo, RxSection, SaveSection, SeenByHeader, VitalSection, SuccessModal } from "./util"
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
const dayjs = require("dayjs").extend(require("dayjs/plugin/advancedFormat"))



const PatientHistory2 = ({ doctorId }) => {
    const { id, opdId } = useParams();
    const navigate = useNavigate();
    const [speech, setSpeech] = useState({
        complaint: false,
        diagnosis: false,
        notes    : false
    });
    const [polId, setPolId] = useState(0);
    const [fees, setFees] = useState(0);
    const [totalFee, setTotalFee] = useState(0);
    const [otherFees, setOtherFees] = useState([]);
    const [tabVal, setTabVal] = useState("patientInfo");
    const [patientInfo, setPatientInfo] = useState(null);
    const [doctorInfo, setDoctorInfo] = useState(null);
    const [patientHistory, setPatientHistory] = useState([]);
    const [vitals, setVitals] = useState({ temprature: "", height: "", weight: "", pulse: "", headCircum: "", spo2: "", bmi: "" });
    const [chiefComplaints, setChiefComplaints] = useState("");
    const [diagnosis, setDiagonosis] = useState("");
    const [notes, setNotes] = useState("");
    const [rx, setRx] = useState([
        { medicine: "", form: "Tablet",dose: "", timing: "Before Food", frequency: "Daily", duration: 0, qty: 0 },
        { medicine: "", form: "Tablet",dose: "", timing: "Before Food", frequency: "Daily", duration: 0, qty: 0 },
    ]);
    const [isModalVisible, setModalVisible] = useState(false);
    const { resetTranscript } = useSpeechRecognition();
    const [intervalHistory, setIntervalHistory] = useState([
        { date: dayjs(new Date()).format("YYYY-MM-DD HH:mm:ss"), doctorName: "", reason: "", note: "" },
    ]);
    const vitalRefs = useRef([createRef(), createRef(), createRef(), createRef(), createRef(), createRef(), createRef()]);
    const feesRef = useRef(createRef())
    const complainDiagnosisRef = useRef([createRef(), createRef()]);
    const [nextApointmentDate, setNextApointmentDate] = useState({ cbx: false, dateVal: dayjs(new Date()).format("YYYY-MM-DD HH:mm:ss") });
    const openModal = () => {
        setModalVisible(true)
    };
    const closeModal = () => setModalVisible(false);
    const handleSave = (opdStatus) => {
        let validate = true;
        otherFees.forEach(e => {
            if (e.otherFeeLabel === "") {
                e.labelRef.current.focus();
                e.labelRef.current.style = "outline: 1px red solid"
                validate = false;
                return false;
            }
            if (e.otherFeeValue === "" || e.otherFeeValue === 0 || e.otherFeeValue === "0") {
                e.valueRef.current.focus();
                e.valueRef.current.style = "outline: 1px red solid"
                validate = false;
                return false;
            }
        })
        if (!validate)
            return;
        let otherFeesTemp = {};
        otherFees.forEach(e => {
            otherFeesTemp[e.otherFeeLabel] = e.otherFeeValue;
        })
        const payload = {
            patientId: id,
            opdId: opdId,
            doctorId: doctorId,
            vitals: vitals,
            complaints: chiefComplaints,
            diagnosis: diagnosis,
            rx: rx,
            notes: notes,
            intervalHistory: intervalHistory,
            ix: "",
            opdStatus: opdStatus,
            fees: parseInt(fees),
            feesDetailsJson: { ...otherFeesTemp, totalFees: totalFee }
        }
        if(nextApointmentDate.cbx) {
            payload.nextVisitOn = nextApointmentDate.dateVal
        }
        if (polId === 0) {
            createPatientOpdLifeCycle(payload).then(res => {
                if (res.status === 200) {
                    openModal();
                }
            });
        } else {
            updatePatientOpdLifeCycleById(polId, { polId: polId, ...payload }).then(res => {
                if (res.status === 200) {
                    openModal();
                }
            })
        }
    }
    const handleChange = (event, newValue) => {
        setTabVal(newValue);
    };
    const calculateTotalFee = () => {
        let temp = parseFloat(fees);
        otherFees.forEach(element => {
            temp += parseFloat(element.otherFeeValue);
        });
        setTotalFee(isNaN(temp) ? 0 : temp);
    }
    useEffect(() => {
        checkEditablePatientOpdLifeCycle(id, opdId).then((res) => {
            if (res.status === 200) {
                let obj = res.result.patientHistory;
                if (obj.polId !== null) {
                    setPolId(parseInt(obj.polId))
                    if (obj.vitals !== null) {
                        setVitals(_ => ({ ...obj.vitals }));
                    }
                    if (obj.chiefComplaints !== null) {
                        setChiefComplaints(_ => obj.complaints);
                    }
                    if (obj.diagnosis !== null) {
                        setDiagonosis(_ => obj.diagnosis);
                    }
                    if (obj.rx !== null) {
                        setRx(_ => ([...obj.rx]));
                    }
                    if (obj.notes !== null) {
                        setNotes(_ => obj.notes);
                    }
                    if (obj.intervalHistory !== null) {
                        setIntervalHistory(_ => ([...obj.intervalHistory]));
                    }
                }
            }
        });
        getPatientById(id).then((res) => {
            if (res.status === 200) {
                setPatientInfo((prev) => {
                    return {
                        ...prev,
                        ...res.result.patientDto,
                    };
                });
            }
        });
        getPatientHistory(id).then((res) => {
            if (res.status === 200) {
                setPatientHistory((prev) => {
                    prev = res.result.patientHistory
                    return [...prev];
                })
            }
        });
        getOpdAppointmentById(opdId).then((res) => {
            if (res.status === 200) {
                setFees(res.result.opdAppointmentResponseDto.fees);
                let k = Object.keys(res.result.opdAppointmentResponseDto.feesDetailsJson);
                k = k.filter((e) => (e !== "totalFees" && e !== "paymentMode" && e !== "transactionId"));
                let t = k.map((e, i) => {
                    return {
                        otherFeeLabel: e,
                        otherFeeValue: res.result.opdAppointmentResponseDto.feesDetailsJson[e],
                        labelRef: createRef(),
                        valueRef: createRef()
                    }
                })
                setOtherFees(t);
            }
        });
    }, [id, opdId]);
    useEffect(() => {
        getDoctorById(doctorId).then((res) => {
            if (res.status === 200) {
                setDoctorInfo((prev) => {
                    return {
                        ...prev,
                        ...res.result.doctorDetails,
                    };
                });
            }
        });
    }, [doctorId]);
    useEffect(()=>{
        setTimeout(()=>{
            document.querySelector('body').addEventListener("click", ()=>{
                SpeechRecognition.stopListening();
                resetTranscript();
                setSpeech(prev=>{
                    prev.complaint = false;
                    prev.diagnosis = false;
                    prev.notes     = false;
                    return prev;
                });
            })
        }, 1000);
    }, []);
    return (
        <div className="h-full text-[#404040]">
            <SuccessModal closeModal={closeModal} isModalVisible={isModalVisible}/>
            <div className="mx-[60px] mt-7 mb-0">
                <p className="text-2xl leading-6 text-[#404040] font-bold">
                    <i className="far fa-long-arrow-left mr-4 cursor-pointer" onClick={()=>{navigate("/opd-out/manage")}}></i>
                    Checkup
                </p>
            </div>
            <div className="bg-[#F7F8F9] py-6 px-[60px] h-[90vh]">
                <div className="bg-white w-full h-full shadow-[0px_9px_20px] shadow-[#2E235E1A] rounded-[15px]">
                    <PatientInfo patientInfo={patientInfo} />
                    <hr className="mx-[30px]" />
                    <SaveSection
                        nextApointmentDate={nextApointmentDate}
                        rx={rx}
                        setNextApointmentDate={setNextApointmentDate}
                        handleSave={handleSave}
                    />
                    <div className="border-b mx-2 sm:mx-5 pt-0 mt-0 sm:pt-0 flex justify-between items-center">
                        <Tabs
                            value={tabVal}
                            onChange={handleChange}
                            textColor="secondary"
                            indicatorColor="secondary"
                            aria-label="secondary tabs example"
                        >
                            <Tab
                                value={"patientInfo"}
                                label="Patient Information"
                                style={{ fontSize: 14, lineHeight: "20px", fontWeight: 500 }}
                            />
                            <Tab
                                value={"recentHistory"}
                                label="Old History"
                                style={{ fontSize: 14, lineHeight: "20px", fontWeight: 500 }}
                            />
                            <Tab
                                value={"otherDoctor"}
                                label="Transfer Summary"
                                style={{ fontSize: 14, lineHeight: "20px", fontWeight: 500 }}
                            />
                        </Tabs>
                    </div>
                    {
                        tabVal === "patientInfo" &&
                        <div className="h-[48vh] overflow-auto">
                            <div className="m-[30px] border-solid border-[#2E235E1A] border-[1px]">
                                <SeenByHeader doctorInfo={doctorInfo} />
                                <VitalSection vitalRefs={vitalRefs} vitals={vitals} setVitals={setVitals} />
                                <ComplaintDiagnosisSection
                                    complainDiagnosisRef={complainDiagnosisRef}
                                    chiefComplaints={chiefComplaints}
                                    setChiefComplaints={setChiefComplaints}
                                    diagnosis={diagnosis}
                                    setDiagonosis={setDiagonosis}
                                    speech={speech}
                                    setSpeech={setSpeech}
                                />
                                <RxSection
                                    rx={rx}
                                    setRx={setRx}
                                    notes={notes}
                                    setNotes={setNotes}
                                    speech={speech}
                                    setSpeech={setSpeech}
                                />
                                <FeesSection
                                    fees={fees}
                                    setFees={setFees}
                                    calculateTotalFee={calculateTotalFee}
                                    otherFees={otherFees}
                                    setOtherFees={setOtherFees}
                                    totalFee={totalFee}
                                    feesRef={feesRef}
                                />
                            </div>
                            <div className="mx-[30px] border-solid border-[#2E235E1A] border-[1px]">
                                {
                                    patientHistory.length > 0 ?
                                        <Accordion
                                            doctorInfo={doctorInfo}
                                            patientHistory={patientHistory.filter((e, i) => (i <= 2))}
                                        />
                                        : null
                                }
                            </div>
                        </div>
                    }
                    {
                        tabVal === "recentHistory" &&
                        <div className="h-[48vh] overflow-auto">
                            <div className="m-[30px] border-solid border-[#2E235E1A] border-[1px]">
                                {
                                    patientHistory.length > 0 ?
                                        <Accordion
                                            doctorInfo={doctorInfo}
                                            patientHistory={patientHistory}
                                        />
                                        : <p className="p-4">No Patient History</p>
                                }
                            </div>
                        </div>
                    }
                    {
                        tabVal === "otherDoctor" &&
                        <div className="h-[60vh] overflow-auto">
                            <IntervalHistorySection intervalHistory={intervalHistory} setIntervalHistory={setIntervalHistory} />
                        </div>
                    }
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        doctorId: state.user.doctorId,
    };
};

export default WithAuth(
    WithHeaderSidebar(connect(mapStateToProps, null)(PatientHistory2))
);
