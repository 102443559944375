import React, { useCallback, useEffect, useState } from "react";
import WithHeaderSidebar from "../../layout/withHeaderSidebar";
import Button from "../../components/button";
import TableWithSearchAndPagination from "../../components/tableWithSearchAndPagination";
import { useNavigate } from "react-router-dom";
import {
  deletePhoneCallLogById,
  getPhoneCallList,
} from "../../services/phoneCall";
import dayjs from "dayjs";
import WithAuth from "../../layout/withAuth";

const headings = [
  {
    label: "Ref. No.",
    width: "10%",
    align: "left",
  },
  {
    label: "Date",
    width: "12%",
    align: "left",
  },
  {
    label: "Contact No.",
    width: "13%",
    align: "left",
  },
  {
    label: "Contact Name",
    width: "20%",
    align: "left",
  },
  {
    label: "Call Type",
    width: "15%",
    align: "left",
  },
  {
    label: "Follow up Date",
    width: "15%",
    align: "left",
  },
  {
    label: "Actions",
    width: "15%",
    align: "center",
  },
];

const dataToShow = [
  {
    key: "referenceNumber",
    align: "left",
  },
  {
    key: "date",
    align: "left",
  },
  {
    key: "contactNumber",
    align: "left",
  },
  {
    key: "contactName",
    align: "left",
  },
  {
    key: "callType",
    align: "left",
  },
  {
    key: "followUpDate",
    align: "left",
  },
];

const PhoneCallBook = () => {
  const navigate = useNavigate();
  const [totalData, setTotalData] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [callbackAttributes, setCallbackAttributes] = useState({
    searchSend: "",
    perPage: "25",
    selectedPage: "0",
    sort: "phoneCallId,desc",
  });
  const [phoneCallList, setPhoneCallList] = useState([]);

  const displayPhoneCallList = useCallback(() => {
    setPhoneCallList([]);
    const { searchSend, selectedPage, perPage, sort } = callbackAttributes;
    let data = `searchKey=${searchSend}&page=${selectedPage}&size=${perPage}&sort=${sort}`;
    getPhoneCallList(data).then((res) => {
      if (res?.status === 200) {
        const phoneList = res?.result?.phoneCallListPage.map((item) => {
          return {
            ...item,
            date: dayjs(item.date).format("DD/MM/YYYY"),
            followUpDate: dayjs(item.followUpDate).format("DD/MM/YYYY"),
          };
        });
        setPhoneCallList(phoneList || []);
        setTotalData(res?.result?.getTotalRecords || 0);
        setTotalPages(res?.result?.getTotalPages || 0);
      } else {
        // props?.setSnackbar({
        //     showSnackbar: true,
        //     message: res?.message,
        //     variant: "error"
        // })
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callbackAttributes]);

  useEffect(() => {
    displayPhoneCallList();
  }, [displayPhoneCallList, callbackAttributes]);

  const handleDelete = (item) => {
    deletePhoneCallLogById(item.phoneCallId).then((res) => {
      if (res.status === 200) {
        displayPhoneCallList();
      }
    });
  };

  const handleEdit = (item) => {
    navigate("/phone-call-book/edit/" + item.phoneCallId);
  };

  return (
    <div className="h-full">
      <div className="p-5 sm:pl-8 sm:pt-[17px] sm:pb-[23px]">
        <p className="text-xl md:text-2xl md:leading-6 text-[#404040] font-bold">
          Phone
        </p>
      </div>
      <div className="bg-[#F7F8F9] sm:pt-[18px] p-5 sm:pl-7 sm:pr-11 sm:pb-9">
        <div className="bg-white w-full h-full shadow-[0px_9px_20px] shadow-[#2E235E1A] rounded-[15px]">
          <div className="flex flex-row items-center justify-between p-3 sm:p-7 border-b ">
            <p className="text-base md:text-xl leading-6 text-[#404040] font-bold">
              Phone Call Book
            </p>
            <div>
              <Button
                text="New Entry"
                onClick={() => {
                  navigate("/phone-call-book/add");
                }}
              />
            </div>
          </div>
          <div className="p-3 sm:p-7 sm:pt-5">
            <TableWithSearchAndPagination
              headings={headings}
              dataToShow={dataToShow}
              data={phoneCallList}
              totalData={totalData}
              totalPages={totalPages}
              actions={["delete", "edit"]}
              setCallbackAttributes={setCallbackAttributes}
              handleActionEdit={handleEdit}
              handleActionDelete={handleDelete}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default WithAuth(WithHeaderSidebar(PhoneCallBook));
