import DatePicker from "../../components/datePicker";
import TextInput from "../../components/textInput";
import WithHeaderSidebar from "../../layout/withHeaderSidebar";
import Button from "../../components/button";
import SearchBox from "../../components/searchbox";
import ImageUpload from "../../components/imageUpload";
import { createRef, useEffect, useRef, useState } from "react";
import IdUpload from "../../components/idUpload";
import { addVisitor, getVisitor } from "../../services/visitor";
import { useNavigate, useParams } from "react-router-dom";
import dayjs from "dayjs";
import WithAuth from "../../layout/withAuth";

const validations = {
  visitorId: [
    {
      type: "required",
      message: "Please enter visitor id",
    },
  ],
  firstName: [
    {
      type: "required",
      message: "Please enter first name",
    },
  ],
  middleName: [
    {
      type: "required",
      message: "Please enter middle name",
    },
  ],
  lastName: [
    {
      type: "required",
      message: "Please enter last name",
    },
  ],
  date: [
    {
      type: "required",
      message: "Please select date",
    },
  ],
  inTime: [
    {
      type: "required",
      message: "Please enter in time",
    },
  ],
  outTime: [
    {
      type: "required",
      message: "Please enter out time",
    },
  ],
  purposeOfVisit: [
    {
      type: "required",
      message: "Please enter purpose of visit",
    },
  ],
  departmentName: [
    {
      type: "required",
      message: "Please enter department name.",
    },
  ],
  personName: [
    {
      type: "required",
      message: "Please enter person name",
    },
  ],
  remark: [
    {
      type: "required",
      message: "Please enter remark",
    },
  ],
};

const NewVisitRegistration = (props) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const inputRefs = useRef([
    createRef(),
    createRef(),
    createRef(),
    createRef(),
    createRef(),
    createRef(),
    createRef(),
    createRef(),
    createRef(),
    createRef(),
    createRef(),
  ]);

  const [info, setInfo] = useState({
    visitorId: "0",
    firstName: "",
    middleName: "",
    lastName: "",
    date: "",
    inTime: "",
    outTime: "",
    purposeOfVisit: "",
    departmentName: "",
    personName: "",
    photoID: "",
    profilePicture: "",
    remark: "",
    contactNumber: "",
  });

  const handleChange = (name, value) => {
    setInfo((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  useEffect(() => {
    if (id) {
      getVisitor(id).then((res) => {
        if (res.status === 200) {
          const visitorDto = res?.result?.visitorDto;
          setInfo({
            ...visitorDto,
            visitorId: visitorDto.visitorId.toString(),
            date: new Date(visitorDto.date),
          });
        }
      });
    }
  }, [id]);

  const handleRegister = () => {
    let isValid = true;
    for (let i = 0; i < inputRefs.current.length; i++) {
      const valid = inputRefs.current[i].current.validate();
      if (!valid) {
        isValid = false;
      }
    }
    if (!isValid) {
      return;
    }
    const payload = {
      ...info,
      date: dayjs(info.date).format("YYYY-MM-DD HH:mm:ss"),
    };
    addVisitor(payload).then((res) => {
      if (res.status === 200) {
        navigate("/visitors-book/manage");
      }
    });
  };
  return (
    <div
      className="flex flex-col"
  style={{/* minHeight: `calc(100vh - ${props.headerHeight}px)` */}}
    >
      <div className="p-5 sm:pl-8 sm:pt-7 sm:pb-[23px]">
        <p className="text-xl sm:text-2xl sm:leading-6 text-[#404040] font-bold">
          Visitor's Book
        </p>
      </div>
      <div className="flex flex-col lg:flex-row bg-[#F7F8F9] pt-[18px] p-5 sm:px-7 sm:pb-9 gap-[22px]">
        <div className="flex flex-col bg-[#FFFFFF] rounded-[15px] lg:w-[20%] shadow-[0px_9px_20px] shadow-[#2E235E1A]">
          <div className="p-3 lg:px-[30px] border-b">
            <p className="text-base md:text-xl md:leading-6 text-[#404040] font-bold">
              Search Visitor
            </p>
          </div>
          <div className="p-5 lg:px-[30px]">
            <SearchBox />
          </div>
        </div>
        <div className="flex flex-col bg-[#FFFFFF] rounded-[15px] lg:w-[80%] shadow-[0px_9px_20px] shadow-[#2E235E1A]">
          <div className="p-3 lg:px-[30px] border-b">
            <p className="text-base md:text-xl md:leading-6 text-[#404040] font-bold">
              Visitor Registration
            </p>
          </div>
          <div className="p-5 lg:px-[30px] lg:pb-9 lg:pt-6">
            <div className="grid grid-cols-1 md:grid-cols-3 md:gap-4 lg:gap-6 xl:gap-9">
              <TextInput
                label="Visitor Number"
                placeholder="000000"
                ref={inputRefs.current[0]}
                value={info.visitorId}
                name="visitorId"
                onChange={handleChange}
                validations={validations.visitorId}
                disabled={true}
              />
            </div>
            <div className="grid grid-cols-1 md:grid-cols-3 md:gap-4 lg:gap-6 xl:gap-9 ">
              <TextInput
                label="Visitor Name"
                placeholder="First Name"
                ref={inputRefs.current[1]}
                validations={validations.firstName}
                name="firstName"
                onChange={handleChange}
                value={info.firstName}
              />
              <TextInput
                placeholder="Middle Name"
                ref={inputRefs.current[2]}
                validations={validations.middleName}
                name="middleName"
                onChange={handleChange}
                value={info.middleName}
              />
              <TextInput
                placeholder="Last Name"
                ref={inputRefs.current[3]}
                validations={validations.lastName}
                name="lastName"
                onChange={handleChange}
                value={info.lastName}
              />
            </div>
            <div className="grid grid-cols-1 md:grid-cols-3 md:gap-4 lg:gap-6 xl:gap-9">
              <DatePicker
                label="Date"
                placeholderText="DD / MM / YYYY"
                ref={inputRefs.current[4]}
                validations={validations.date}
                name="date"
                onChange={handleChange}
                value={info.date}
              />
              <TextInput
                label="In Time"
                placeholder="00 : 00 --"
                ref={inputRefs.current[5]}
                validations={validations.inTime}
                name="inTime"
                onChange={handleChange}
                value={info.inTime}
              />
              <TextInput
                label="Out Time"
                placeholder="00 : 00 --"
                ref={inputRefs.current[6]}
                validations={validations.outTime}
                name="outTime"
                onChange={handleChange}
                value={info.outTime}
              />
            </div>
            <div className="grid grid-cols-1 md:grid-cols-3 md:gap-4 lg:gap-6 xl:gap-9">
              <div className="col-span-2">
                <TextInput
                  placeholder="Enter Purpose of Visit"
                  label="Purpose of Visit"
                  ref={inputRefs.current[7]}
                  validations={validations.purposeOfVisit}
                  name="purposeOfVisit"
                  onChange={handleChange}
                  value={info.purposeOfVisit}
                />
                <div className="flex flex-col md:flex-row md:gap-4 lg:gap-6 xl:gap-9">
                  <TextInput
                    label="Department"
                    placeholder="Enter Department Name"
                    ref={inputRefs.current[8]}
                    validations={validations.departmentName}
                    name="departmentName"
                    onChange={handleChange}
                    value={info.departmentName}
                  />
                  <TextInput
                    label="Person Name"
                    placeholder="Name of Person to be Visited"
                    ref={inputRefs.current[9]}
                    validations={validations.personName}
                    name="personName"
                    onChange={handleChange}
                    value={info.personName}
                  />
                </div>
                <IdUpload
                  image={{
                    imagePath: info.photoID,
                    imageName:
                      info.photoID?.split("/")?.[
                        info.photoID?.split("/").length - 1
                      ],
                  }}
                  userId={info.visitorId}
                  userType="visitor"
                  placeholder="Upload Visitor's Photo ID"
                  label="Visitor's Photo ID"
                  onImageUpload={(path, fileName) => {
                    handleChange("photoID", path);
                  }}
                />
                <TextInput
                  label="Remark"
                  ref={inputRefs.current[10]}
                  validations={validations.remark}
                  name="remark"
                  onChange={handleChange}
                  value={info.remark}
                />
              </div>
              <div className="flex flex-col flex-1">
                <ImageUpload
                  className="flex-[0.8]"
                  userType="visitor"
                  userId={info.visitorId}
                  label="Visitor's Photo"
                  image={info.profilePicture}
                  onImageUpload={(imagePath, imageName) => {
                    handleChange("profilePicture", imagePath);
                  }}
                />
                <div className="flex items-center justify-end mt-9">
                  <Button text="Register" onClick={handleRegister} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WithAuth(WithHeaderSidebar(NewVisitRegistration));
