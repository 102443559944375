import InfoItem from "../components/infoItem";
import WithHeaderSidebar from "../layout/withHeaderSidebar";
import { ReactComponent as FilePlus } from "../assets/svgs/file-plus.svg";
import { ReactComponent as FileAlt } from "../assets/svgs/file-alt.svg";
import { ReactComponent as UserInjured } from "../assets/svgs/user-injured.svg";
import { ReactComponent as NurseIcon } from "../assets/svgs/user-nurse.svg";
import { ReactComponent as PlusCircle } from "../assets/svgs/plus-circle.svg";
import { ReactComponent as Report } from "../assets/svgs/file-chart-pie.svg";
import { ReactComponent as LayerPlus } from "../assets/svgs/layer-plus.svg";
import { ReactComponent as CalendarPlus } from "../assets/svgs/calendar-plus.svg";
import { ReactComponent as Users } from "../assets/svgs/users.svg";
import { ReactComponent as ShippingFast } from "../assets/svgs/shipping-fast.svg";
import { ReactComponent as GridIcon } from "../assets/svgs/grid-add.svg";
import { useEffect, useState } from "react";
import { getDashBoardPageData } from "../services/dashboard";
import { useNavigate } from "react-router-dom";
import WithAuth from "../layout/withAuth";

const Dashboard = () => {
  const navigate = useNavigate();
  const [info, setInfo] = useState({});
  useEffect(() => {
    getDashBoardPageData().then((res) => {
      if (res.status === 200) {
        setInfo(res.result);
      }
    });
  }, []);
  return (
    <div className="py-[22px] mt-2 mx-7">
      <p className="text-[#404040] text-2xl leading-[24px] font-bold">
        Dashboard
      </p>
      <div className="grid grid-cols-1 lg:grid-cols-4 mt-[45px] gap-y-[27px] lg:gap-x-[22px]">
        <div className="flex flex-row col-span-1 lg:col-span-2 justify-between pb-0 p-3 sm:pt-8 sm:pl-[30px] sm:pr-4 bg-gradient-to-r from-[#3CA3EE] to-[#7366FF] rounded-[15px]">
          <div className="pb-3">
            <p className="text-lg sm:text-2xl sm:leading-7 text-white font-bold">
              Welcome to Pulse EMR
            </p>
            <p className="text-xs sm:text-sm sm:leading-6 text-white font-bold mt-1 sm:mt-3">
              Click Below Button to Book Patient's Appointment
            </p>
            <button
              className="mt-3 sm:mt-[29px] border border-[#FFFFFF] p-2 sm:pt-[10px] sm:pb-[6px] sm:px-9 rounded"
              onClick={() => {
                navigate("/opd-appointment/add");
              }}
            >
              <p className="text-xs sm:text-sm sm:leading-6 text-white font-bold">
                Book Appointment
              </p>
            </button>
          </div>
          <img
            src="/images/doctor.png"
            className="h-[120px] sm:h-[193px] w-[120px] sm:w-[193px] self-end"
            alt="doctor"
          />
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 col-span-2 gap-x-[22px] gap-y-[27px]">
          <InfoItem
            icon={UserInjured}
            // value={info?.todayAppointmentsCount}
            label="Patient"
            iconColor="#F73164"
            onClick={()=>{navigate("/patients/manage")}}
            />
          <InfoItem
            icon={NurseIcon}
            value="127"
            label="OPD Appointment"
            iconColor="#FFAF14"
            onClick={()=>{navigate("/opd-appointment/manage")}}
            />
          <InfoItem
            icon={UserInjured}
            iconColor="#7265FE"
            value={info.totalEmergencyCasesCount}
            label="OPD Out"
            onClick={()=>{navigate("/opd-out/manage")}}
            />
          <InfoItem
            icon={Report}
            iconColor="#54BA4A"
            value="40"
            label="Reports"
            onClick={()=>{navigate("/reports/opd")}}
          />
        </div>
      </div>
      {/* <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-[22px] mt-7">
        <InfoItem
          icon={CalendarPlus}
          value={info?.totalAppointmentsCount}
          label="Total Appointment"
          iconColor="#3009FF"
        />
        <InfoItem
          icon={Users}
          value={info?.totalVisitorsCount}
          label="Visitor's Book"
          iconColor="#0D802F"
        />
        <InfoItem
          icon={ShippingFast}
          iconColor="#BC2700"
          value={info.totalPostalCount}
          label="Postal"
        />
      </div> */}
    </div>
  );
};

export default WithAuth(WithHeaderSidebar(Dashboard));
