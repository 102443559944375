import React, { useCallback, useEffect, useState } from "react";
import WithHeaderSidebar from "../../layout/withHeaderSidebar";
import Button from "../../components/button";
import TableWithSearchAndPagination from "../../components/tableWithSearchAndPagination";
import { useNavigate } from "react-router-dom";
import {
  deleteAmbulanceLogById,
  getAmbulanceLogListPage,
} from "../../services/ambulance";
import dayjs from "dayjs";
import WithAuth from "../../layout/withAuth";

const headings = [
  {
    label: "AMB No.",
    width: "10%",
    align: "left",
  },
  {
    label: "Vehicle No.",
    width: "12%",
    align: "left",
  },
  {
    label: "Patient Name",
    width: "13%",
    align: "left",
  },
  {
    label: "Address",
    width: "20%",
    align: "left",
  },
  {
    label: "Date",
    width: "15%",
    align: "left",
  },
  {
    label: "Time",
    width: "15%",
    align: "left",
  },
  {
    label: "Actions",
    width: "15%",
    align: "center",
  },
];

const dataToShow = [
  {
    key: "ambulanceCode",
    align: "left",
  },
  {
    key: "vehicleNumber",
    align: "left",
  },
  {
    key: "patientName",
    align: "left",
  },
  {
    key: "patientAddress",
    align: "left",
  },
  {
    key: "date",
    align: "left",
  },
  {
    key: "time",
    align: "left",
  },
];

const AmbulanceLogBook = () => {
  const navigate = useNavigate();
  const [totalData, setTotalData] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [callbackAttributes, setCallbackAttributes] = useState({
    searchSend: "",
    perPage: "25",
    selectedPage: "0",
    // sort: "visitorId,asc"
  });
  const [ambulanceLogList, setAmbulanceLogList] = useState([]);

  const displayAmbulanceList = useCallback(() => {
    setAmbulanceLogList([]);
    const { searchSend, selectedPage, perPage } = callbackAttributes;
    let data = `searchKey=${searchSend}&page=${selectedPage}&size=${perPage}`;
    getAmbulanceLogListPage(data).then((res) => {
      if (res?.status === 200) {
        const ambulanceLogList = res?.result?.ambulanceLogListPage?.map(
          (item) => {
            return {
              ...item,
              patientName: item.patientFirstName + " " + item.patientLastName,
              date: dayjs(item.date).format("DD/MM/YYYY"),
            };
          }
        );
        setAmbulanceLogList(ambulanceLogList || []);
        setTotalData(res?.result?.getTotalRecords || 0);
        setTotalPages(res?.result?.getTotalPages || 0);
      } else {
        // props?.setSnackbar({
        //     showSnackbar: true,
        //     message: res?.message,
        //     variant: "error"
        // })
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callbackAttributes]);

  useEffect(() => {
    displayAmbulanceList();
  }, [displayAmbulanceList, callbackAttributes]);

  const handleDelete = (item) => {
    deleteAmbulanceLogById(item.ambulanceLogId).then((res) => {
      if (res.status === 200) {
        displayAmbulanceList();
      }
    });
  };

  const handleEdit = (item) => {
    navigate("/ambulance-log-book/edit/:" + item.ambulanceLogId);
  };
  return (
    <div className="h-full">
      <div className="p-5 sm:pl-8 sm:pt-[17px] sm:pb-[23px]">
        <p className="text-xl md:text-2xl md:leading-6 text-[#404040] font-bold">
          Ambulance
        </p>
      </div>
      <div className="bg-[#F7F8F9] sm:pt-[18px] p-5 sm:pl-7 sm:pr-11 sm:pb-9">
        <div className="bg-white w-full h-full shadow-[0px_9px_20px] shadow-[#2E235E1A] rounded-[15px]">
          <div className="flex flex-row items-center justify-between p-3 sm:p-7 border-b ">
            <p className="text-base md:text-xl leading-6 text-[#404040] font-bold">
              Ambulance Log Book
            </p>
            <div>
              <Button
                text="New Entry"
                onClick={() => {
                  navigate("/ambulance-log-book/add");
                }}
              />
            </div>
          </div>
          <div className="p-3 sm:p-7 pt-5">
            <TableWithSearchAndPagination
              headings={headings}
              dataToShow={dataToShow}
              data={ambulanceLogList}
              totalData={totalData}
              totalPages={totalPages}
              actions={["delete", "edit"]}
              handleActionDelete={handleDelete}
              handleActionEdit={handleEdit}
              setCallbackAttributes={setCallbackAttributes}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default WithAuth(WithHeaderSidebar(AmbulanceLogBook));
