import React from "react";
import OTPInput from "react-otp-input";
import useWindowDimensions from "../hooks/useWindowDimension";

const OtpInput = ({
  otp = "",
  onChange = () => { },
  numInputs = 4,
  inputStyle = {},
}) => {
  const { width } = useWindowDimensions();

  return (
    <OTPInput
      value={otp}
      onChange={onChange}
      numInputs={numInputs}
      inputStyle={{
        width: width > 430 ? 50 : 40,
        height: width > 430 ? 41 : 31,
        borderWidth: 1,
        borderColor: "#F3F3FF",
        borderRadius: 3,
        fontSize: 14,
        lineHeight: 17,
        color: "#898989",
        ...inputStyle,
      }}
      containerStyle={{
        display: "flex",
        width: "100%",
        justifyContent: "space-between",
      }}
      inputType="tel"
      renderInput={(props) => <input {...props} inputMode="tel" />}
    />
  );
};

export default OtpInput;
