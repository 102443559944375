import axiosInterceptors from "../../axiosInterceptor/axiosInterceptor";
import { rolesUrl } from "../../config/apiConfig";

export const createRole = (data) => {
  return axiosInterceptors()
    .post(rolesUrl + "/createRole", data)
    .then((res) => res);
};

export const rolesListPage = (data) => {
  return axiosInterceptors()
    .get(rolesUrl + "/rolesListPage?" + data)
    .then((res) => res);
};

export const getRoleById = (id) => {
  return axiosInterceptors()
    .get(rolesUrl + "/" + id)
    .then((res) => res);
};

export const updateRoleById = (data) => {
  return axiosInterceptors()
    .put(rolesUrl + "/" + data.roleId, data)
    .then((res) => res);
};

export const deleteRoleById = (id) => {
  return axiosInterceptors()
    .delete(rolesUrl + "/" + id)
    .then((res) => res);
};

export const getPoliciesForRoles = () => {
  return axiosInterceptors()
    .get(rolesUrl + "/getPolicies/0")
    .then((res) => res);
};

export const getAllRoles = () => {
  return axiosInterceptors()
    .get(rolesUrl + "/getAllRoles")
    .then((res) => res);
};
