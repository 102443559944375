import axiosInterceptors from "../../axiosInterceptor/axiosInterceptor";
import { moduleUrl } from "../../config/apiConfig";

export const createModule = (data) => {
  return axiosInterceptors()
    .post(moduleUrl + "/createModule", data)
    .then((res) => res);
};

export const allModuleListPage = (data) => {
  return axiosInterceptors()
    .get(moduleUrl + "/allModuleListPage?" + data)
    .then((res) => res);
};

export const getModuleById = (id) => {
  return axiosInterceptors()
    .get(moduleUrl + "/" + id)
    .then((res) => res);
};

export const updateModuleById = (data) => {
  return axiosInterceptors()
    .put(moduleUrl + "/" + data.moduleId, data)
    .then((res) => res);
};

export const deleteModuleById = (id) => {
  return axiosInterceptors()
    .delete(moduleUrl + "/" + id)
    .then((res) => res);
};

export const moduleByFunctionalityListPage = (data) => {
  return axiosInterceptors()
    .get(moduleUrl + "/moduleByFunctionalityListPage?" + data)
    .then((res) => res);
};
