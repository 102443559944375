import React, { forwardRef, useImperativeHandle, useState } from "react";

const RadioGroup = (
  {
    className = "",
    label = "",
    name = "",
    options = [],
    optionsContainerStyle = "flex flex-col gap-[17px] mt-2",
    value = "",
    onChange = () => {},
    validations = [],
  },
  ref
) => {
  const [error, setError] = useState("");
  useImperativeHandle(ref, () => {
    return {
      validate: () => validate(),
      setError: (e) => setError(e),
    };
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setError("");
    onChange(name, value);
  };

  const validate = () => {
    let isValid = true;
    for (let i = 0; i < validations.length; i++) {
      const validation = validations[i];
      if (validation.type === "required") {
        if (!value) {
          setError(validation.message);
          isValid = false;
          break;
        }
      } else if (validation.type === "regex") {
        if (!new RegExp(validation.regex).test(value)) {
          setError(validation.message);
          isValid = false;
          break;
        }
      }
    }
    return isValid;
  };

  return (
    <div className={`${className}`}>
      <p className="text-sm leading-[21px] text-[#404040]">{label}</p>
      <div className={optionsContainerStyle}>
        {options?.map((option) => {
          return (
            <div className="flex flex-row items-center" key={option.value}>
              <input
                name={name}
                type="radio"
                size=""
                className={`h-[14px] w-[14px] rounded-[5px] accent-[#80509F]`}
                value={option.value}
                onChange={handleChange}
                checked={option.value === value ? true : false}
              />
              <p className="text-sm leading-[17px] text-[#404040] ml-[9px] font-bold">
                {option.label}
              </p>
            </div>
          );
        })}
      </div>
      {error ? (
        <span className="text-sm text-red-500">{error}</span>
      ) : (
        <span className="h-5" />
      )}
    </div>
  );
};

export default forwardRef(RadioGroup);
