import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import WithAuth from "../../layout/withAuth";
import WithHeaderSidebar from "../../layout/withHeaderSidebar";
import { getPatientHistory } from "../../services/patientOpdLifeCycle";
import { getPatientById } from "../../services/patient";
const dayjs = require("dayjs").extend(require("dayjs/plugin/advancedFormat"));

const Accordion = ({ patientHistory }) => {
    const [activeIndex, setActiveIndex] = useState(0); // Set the initial state to 0
    const toggleAccordion = (index) => {
        setActiveIndex(index === activeIndex ? null : index);
    };
    return (
        <div className="divide-y divide-gray-200">
            {patientHistory.map((item, index) => (
                <div key={index}>
                    <button
                        className="w-full text-left focus:outline-none"
                        onClick={() => toggleAccordion(index)}
                    >
                        <div className="flex items-center gap-4 px-4 py-2 bg-[#2E235E1A] text-sm">
                            {
                                activeIndex === index ? <i className="fas fa-chevron-down"></i> : <i className="fas fa-chevron-right"></i>

                            }
                            <span className="font-[800] text-black">{dayjs(item.createdOn).format("Do MMMM YYYY")}</span><span> by Dr. {item.doctorName}</span>
                        </div>
                    </button>
                    {activeIndex === index && (
                        <div>
                            <div className="mt-1 px-2 py-2">
                                <p className="font-[800] text-sm mb-1">Vitals :</p>
                                <p className="text-sm">
                                    Temprature: {item.vitals.temprature}
                                    &nbsp;|&nbsp;
                                    Height:  {item.vitals.height}
                                    &nbsp;|&nbsp;
                                    Weight: {item.vitals.weight}
                                    &nbsp;|&nbsp;
                                    Pulse: {item.vitals.pulse}
                                    &nbsp;|&nbsp;
                                    Head Circum: {item.vitals.headCircum}
                                    &nbsp;|&nbsp;
                                    SPO2: {item.vitals.spo2}
                                    &nbsp;|&nbsp;
                                    BMI: {item.vitals.bmi}
                                </p>
                            </div>
                            <div className="mt-1 px-2 py-2">
                                <p className="font-[800] text-sm mb-1">Chief Complaints :</p>
                                <p className="text-sm">
                                    {item.complaints}
                                </p>
                            </div>
                            <div className="mt-1 px-2 py-2">
                                <p className="font-[800] text-sm mb-1">Diagnosis :</p>
                                <p className="text-sm">
                                    {item.diagnosis}
                                </p>
                            </div>
                            <div className="mt-1 px-2 py-2">
                                <p className="font-[800] text-sm mb-1">Doctor's Notes :</p>
                                <p className="text-sm">
                                    {item.notes}
                                </p>
                            </div>
                            <div className="mt-1 px-2 py-2">
                                <p className="font-[800] text-sm mb-1">Rx :</p>
                                <div className="table-content-wrapper">
                                    <table striped="true" className="w-full">
                                        <thead>
                                            <tr>
                                                <th align="left" style={{ width: "20%" }}>Medicine</th>
                                                <th align="left" style={{ width: "12%" }}>Dose</th>
                                                <th align="left" style={{ width: "14%" }}>Timing</th>
                                                <th align="left" style={{ width: "12%" }}>Frequency</th>
                                                <th align="left" style={{ width: "10%" }}>Duration</th>
                                                <th align="left" style={{ width: "10%" }}>Qty</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                item.rx.length > 0 ?
                                                    item.rx.map((r, k) => {
                                                        return (
                                                            <tr key={k}>
                                                                <td>{r.medicine}</td>
                                                                <td>{r.dose}</td>
                                                                <td>{r.timing}</td>
                                                                <td>{r.frequency}</td>
                                                                <td>{r.duration}</td>
                                                                <td>{r.qty}</td>
                                                            </tr>
                                                        );
                                                    })
                                                    : null
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            ))}
        </div>
    );
};

const PatientInfo = ({ patientInfo }) => {
    return (
        <div className="flex flex-col sm:flex-row sm:items-center p-[30px] sm:py-4 sm:pl-[30px]">
            <img src={patientInfo?.profilePicture} alt="" className="w-[50px] h-[50px] border-[1px] border-[#666] rounded-[50%] bg-[#ababab]" />
            <div className="flex flex-col ml-4">
                <p className="text-lg font-[800]">
                    {patientInfo?.firstName} {patientInfo?.middleName} {patientInfo?.lastName}
                </p>
                <p className="text-sm font-[300]">
                    <span>
                        {patientInfo?.age} years
                    </span>
                    <span className="mx-4">
                        |
                    </span>
                    <span>
                        {patientInfo?.gender}
                    </span>
                    <span className="mx-4">
                        |
                    </span>
                    <span>
                        {patientInfo?.primaryContactNumber}
                    </span>
                </p>
            </div>
        </div>
    );
}

const ViewPatientHistory = () => {
    const { patientId } = useParams();
    const [patientInfo, setPatientInfo] = useState(null);
    const [patientHistory, setPatientHistory] = useState([]);

    useEffect(() => {
        getPatientById(patientId).then((res) => {
            if (res.status === 200) {
                setPatientInfo((prev) => {
                    return {
                        ...prev,
                        ...res.result.patientDto,
                    };
                });
            }
        });
        getPatientHistory(patientId).then((res) => {
            if (res.status === 200) {
                setPatientHistory((prev) => {
                    prev = res.result.patientHistory
                    return [...prev];
                })
            }
        });
    }, [patientId]);

    return (
        <div className="h-full text-[#404040]">
            <div className="mx-[60px] mt-7 mb-0">
                <p className="text-2xl leading-6 text-[#404040] font-bold">
                    Patient History
                </p>
            </div>
            <div className="bg-[#F7F8F9] py-6 px-[60px] h-[90vh]">
                <div className="bg-white w-full h-full shadow-[0px_9px_20px] shadow-[#2E235E1A] rounded-[15px]">
                    <PatientInfo patientInfo={patientInfo} />
                    <div className="mx-[30px] border-solid border-[#2E235E1A] border-[1px]">
                        {
                            patientHistory.length > 0 ?
                                <Accordion
                                    patientHistory={patientHistory}
                                />
                                : <p className="p-4">No Patient History</p>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

export default WithAuth(WithHeaderSidebar(ViewPatientHistory));

