import React from "react";
import { connect } from "react-redux";

const CheckPermission = ({
  userPermissions,
  userDetails,
  module,
  permission,
  children,
}) => {
  if (
    userDetails?.roleName === "Owner" ||
    userPermissions?.[module]?.includes(permission)
  )
    return <div>{children}</div>;
  else return null;
};

const mapStateToProps = (state) => {
  return {
    userPermissions: state.user.permission,
    userDetails: state.user.userDetails,
  };
};

export default connect(mapStateToProps, null)(CheckPermission);
