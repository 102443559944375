import { useCallback, useEffect, useState } from "react";
import TableWithSearchAndPagination from "../../components/tableWithSearchAndPagination";
import { patientAppointmentReminder } from "../../services/notifications";
import WithAuth from "../../layout/withAuth";
import WithHeaderSidebar from "../../layout/withHeaderSidebar";
import dayjs from "dayjs";

const headings = [
    {
        label: "Patient Name",
        width: "25%",
        align: "left",
    },
    {
        label: "Contact Number",
        width: "15%",
        align: "left",
    },
    {
        label: "Email",
        width: "15%",
        align: "left",
    },
    {
        label: "Doctor",
        width: "15%",
        align: "left",
    },
    {
        label: "Last Visit On",
        width: "15%",
        align: "left",
    },
    {
        label: "Next Visit On",
        width: "15%",
        align: "left",
    }
];

const dataToShow = [
    {
        key: "patientName",
        align: "left",
    },
    {
        key: "primaryContactNumber",
        align: "left",
    },
    {
        key: "email",
        align: "left",
    },
    {
        key: "doctorName",
        align: "left",
    },
    {
        key: "lastVisitOn",
        align: "left",
    },
    {
        key: "nextVisitOn",
        align: "left",
    },
];

const PatientReminders = () => {
    const [totalData, setTotalData] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [callbackAttributes, setCallbackAttributes] = useState({
        perPage: "25",
        selectedPage: "0",
        sort: "nextVisitOn,asc",
    });
    const [reminderList, setReminderList] = useState([]);

    const displayReminderList = useCallback(() => {
        setReminderList([]);
        const { selectedPage, perPage, sort } = callbackAttributes;
        let data = `page=${selectedPage}&size=${perPage}&sort=${sort}`;
        patientAppointmentReminder(data).then((res) => {
            if (res?.status === 200) {
                const patientList = res?.result?.reminderList?.map((item) => {
                    return {
                        ...item,
                        lastVisitOn: dayjs(item.lastVisitOn).format("DD/MM/YYYY"),
                        nextVisitOn: dayjs(item.nextVisitOn).format("DD/MM/YYYY"),
                    };
                });
                setReminderList(patientList || []);
                setTotalData(res?.result?.getTotalRecords || 0);
                setTotalPages(res?.result?.getTotalPages || 0);
            } else {
                // props?.setSnackbar({
                //     showSnackbar: true,
                //     message: res?.message,
                //     variant: "error"
                // })
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [callbackAttributes]);
    useEffect(() => {
        displayReminderList();
    }, [displayReminderList, callbackAttributes]);

    return (
        <div className="h-full ">
            <div className="p-7">
                <p className="text-xl md:text-2xl md:leading-6 text-[#404040] font-bold">
                    Patient Reminders 
                </p>
            </div>
            <div className="bg-[#F7F8F9] sm:pt-[18px] p-5 sm:pl-7 sm:pr-11 sm:pb-9">
                <div className="bg-white w-full h-full shadow-[0px_9px_20px] shadow-[#2E235E1A] rounded-[15px]">
                    <div className="flex flex-row items-center justify-between p-3 sm:p-7 border-b ">
                        <p className="text-base md:text-xl leading-6 text-[#404040] font-bold">
                            Patient List
                        </p>
                    </div>
                    <div className="p-3 sm:p-7 sm:pt-5">
                        <TableWithSearchAndPagination
                            headings={headings}
                            dataToShow={dataToShow}
                            data={reminderList}
                            totalData={totalData}
                            totalPages={totalPages}
                            actions={[]}
                            setCallbackAttributes={setCallbackAttributes}
                            module="Patient Reminders"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default WithAuth(WithHeaderSidebar(PatientReminders));
