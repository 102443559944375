import React, { useState, useEffect } from "react";



const BlinkIcon = ({duration, iconClass, otherClasses = "", onClick, style}) => {
    const [blinking, setBlinking] = useState(false);
    const [visible, setVisible] = useState(true);

    useEffect(() => {
        let intervalId, timeoutId;
    
        if (blinking) {
          const toggleVisibility = () => {
            setVisible(prevVisible => !prevVisible);
          };
    
          intervalId = setInterval(toggleVisibility, 500); // Change every 500ms
    
          timeoutId = setTimeout(() => {
            clearInterval(intervalId);
            setBlinking(false); // Stop blinking
            setVisible(true); // Ensure icon is visible after blinking stops
          }, duration);
        }
        return () => {
          clearInterval(intervalId);
          clearTimeout(timeoutId);
        };
      }, [blinking]);
      return (
            visible &&
            <i
                style={style}
                className={`ml-2 font-[400] cursor-pointer ${iconClass} ${otherClasses}`}
                onClick={(e)=>{
                    e.stopPropagation();
                    setBlinking(true);
                    onClick();
                }}
            />
      );

}

export default BlinkIcon;
