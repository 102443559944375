import React, { forwardRef, useImperativeHandle, useState } from "react";

const TextArea = (
  {
    containerStyle = "",
    labelStyle = "",
    inputStyle = "",
    label = "",
    placeholder = "",
    name = "",
    value = "",
    onChange = () => {},
    rows = 1,
    validations = [],
  },
  ref
) => {
  const [error, setError] = useState("");
  useImperativeHandle(ref, () => {
    return {
      validate: () => validate(),
      setError: (e) => setError(e),
    };
  });

  const handleChange = (event) => {
    if (event.target.value.trim() === "") {
      event.target.value = "";
    } else {
      setError(false);
    }
    onChange(event.target.name, event.target.value);
  };

  const validate = () => {
    let isValid = true;
    for (let i = 0; i < validations.length; i++) {
      const validation = validations[i];
      if (validation.type === "required") {
        if (!value.trim()) {
          setError(validation.message);
          isValid = false;
          break;
        }
      } else if (validation.type === "regex") {
        if (!new RegExp(validation.regex).test(value)) {
          setError(validation.message);
          isValid = false;
          break;
        }
      }
    }
    return isValid;
  };

  return (
    <div className={`flex flex-col w-full ${containerStyle}`}>
      {label ? (
        <p
          className={`text-sm leading-[21px] text-[#404040] mb-2 ${labelStyle}`}
        >
          {label}
        </p>
      ) : null}
      <div className="py-[6px] px-[14px] w-full border border-[#CED4DA] rounded-[5px]">
        <textarea
          name={name}
          value={value}
          placeholder={placeholder}
          rows={rows}
          className={`text-sm leading-[21px] placeholder-[#C5C5C5] text-[#404040] outline-none rounded-[5px] w-full ${inputStyle}`}
          onChange={handleChange}
        />
      </div>
      {error ? (
        <span className="text-sm text-red-500">{error}</span>
      ) : (
        <span className="h-5" />
      )}
    </div>
  );
};

export default forwardRef(TextArea);
