import * as types from "../config/actionTypes";

export const setUser = (payload) => {
  return {
    type: types.SET_USER,
    payload,
  };
};

export const resetUser = () => {
  return {
    type: types.RESET_USER,
  };
};
