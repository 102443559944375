import { RESET_USER, SET_USER } from "../config/actionTypes";

const initialState = {
  userDetails: {},
  permission: {},
  doctorId: 0
};

export const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER:
      return { ...state, ...action.payload };
    case RESET_USER:
      return initialState;
    default:
      return state;
  }
};
