import axiosInterceptors from "../axiosInterceptor/axiosInterceptor";
import { visitorUrl } from "../config/apiConfig";

export const getVisitorsList = (data) => {
  return axiosInterceptors()
    .get(visitorUrl + "/getVisitorsListPage?" + data)
    .then((res) => res);
};

export const deleteVisitor = (id) => {
  return axiosInterceptors()
    .delete(visitorUrl + "/deleteVisitorById/" + id)
    .then((res) => res);
};

export const addVisitor = (data) => {
  return axiosInterceptors()
    .post(visitorUrl + "/addNewVisitor", data)
    .then((res) => res);
};

export const getVisitor = (id) => {
  return axiosInterceptors()
    .get(visitorUrl + "/getVisitorById/" + id)
    .then((res) => res);
};
