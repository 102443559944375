import React from "react";

const InfoItem = ({ icon: Icon = "", label = "", value = "", iconColor = "", onClick = ()=>{}}) => {
  return (
    <div className="flex flex-row p-4 bg-[#ECECEC] rounded-[15px] cursor-pointer" onClick={onClick}>
      <div
        className={`flex justify-center items-center h-[56px] w-[56px] rounded-[28px] border border-solid`}
        style={{ borderColor: iconColor }}
      >
        <Icon height={26} width={26} fill={iconColor} />
      </div>
      <div className="flex flex-col items-center justify-center ml-[10px]">
        {/* <p className="text-2xl leading-6 text-[#404040] font-bold">{value}</p> */}
        <p className="text-sm leading-6 text-[#404040] font-bold">
          {label}
        </p>
      </div>
    </div>
  );
};

export default InfoItem;
