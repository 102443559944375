import React, { useEffect, useState } from "react";
import Button from "../../components/button";
import OtpInput from "../../components/otpInput";
import { useLocation, useNavigate } from "react-router-dom";
import { resendOtp, verifyOtp } from "../../services/auth";

const heading = {
  email: "Verify Email",
  phoneNumber: "Verify Mobile Number",
  forgotPassword: "Enter Verification Code",
};

const label = {
  email: "Verification code sent to your registered email",
  phoneNumber: "Verification code sent to your registered Mobile Number",
  forgotPassword:
    "Verification code sent to your registered Email / Mobile Number",
};

const buttonText = {
  email: "Verify Email",
  phoneNumber: "Verify Contact Number",
  forgotPassword: "Verify",
};

const VerifyOtp = () => {
  let location = useLocation();
  const { verifyFlow, userId, email, mobileNumber, toVerify } = location?.state || {};
  const [otp, setOtp] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    if (!verifyFlow) {
      navigate("/")
      return
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [verifyFlow])

  const handleSubmitOtp = () => {
    if (verifyFlow === "email") {
      const data = `userId=${userId}&emailOtp=${otp}`
      verifyOtp(data).then(res => {
        if (res.status === 200) {
          let payload = {
            userId,
            mobileNumber,
            email: null
          }
          resendOtp(payload).then(res => {
            if (res.status === 200) { }
          })
          navigate("/verify-otp", { state: { verifyFlow: "phoneNumber", userId, email, mobileNumber } });
          // navigate("/security-questions", { state: { userId, flow: "registration" } });
          setOtp("");
        }
      })
    } else if (verifyFlow === "phoneNumber") {
      // const data = `userId=${userId}&mobileOtp=${otp}`
      // verifyOtp(data).then(res => {
      //   if (res.status === 200) {
      //     navigate("/security-questions", { state: { userId, flow: "registration" } });
      //     setOtp("");
      //   }
      // })
      navigate("/security-questions", { state: { userId, flow: "registration" } });
    } else if (verifyFlow === "forgotPassword") {
      let data = ""
      if (toVerify === "email") {
        data = `userId=${userId}&emailOtp=${otp}`
      } else {
        data = `userId=${userId}&mobileOtp=${otp}`
      }
      verifyOtp(data).then(res => {
        if (res.status === 200) {
          navigate("/reset-password", { state: { userId } });
          setOtp("");
        }
      })
    }
  };

  const handleResendOtp = () => {
    let data = {}
    if (verifyFlow === "email") {
      data = {
        userId,
        email,
        mobileNumber: null
      }
    } else if (verifyFlow === "phoneNumber") {
      data = {
        userId,
        mobileNumber,
        email: null
      }
    } else if (verifyFlow === "forgotPassword") {
      if (toVerify === "email") {
        data = {
          userId,
          mobileNumber: null,
          email
        }
      } else {
        data = {
          userId,
          mobileNumber,
          email: null
        }
      }
    }
    resendOtp(data).then(res => {
      if (res.status === 200) { }
    })
  }

  return (
    <div
      className="flex flex-col md:flex-row h-screen w-screen"
      style={{
        backgroundImage: "url(/images/auth_screens_Background.png)",
        backgroundSize: "cover",
      }}
    >
      <div className="md:flex items-center justify-center md:w-[50%] bg-white opacity-90 hidden">
        <img
          src="/images/Pulse_Logo_Auth_Screens.png"
          className="w-[192px] h-[232px]"
          alt="logo"
        />
      </div>
      <div className="flex flex-col md:w-[50%] h-screen bg-[#f3f3ffe6] items-center sm:justify-center p-5 md:px-10 lg:px-28">
        <img
          src="/images/Pulse_Logo_Auth_Screens.png"
          className="w-[132px] h-[160px] m-5 md:hidden"
          alt="logo"
        />
        <div className="flex flex-col bg-white p-8 sm:px-10 sm:pt-[71px] sm:pb-[75px] w-full rounded-[10px] shadow-2xl">
          <p className="text-2xl leading-7 text-[#000000] font-bold">
            {heading[verifyFlow]}
          </p>
          <p className="text-sm leading-[17px] text-[#898989] font-bold mt-2">
            {label[verifyFlow]}
          </p>
          <p className="text-sm leading-[17px] text-[#404040] mt-[25px] font-bold">
            Enter Verification code
          </p>
          <div className="mt-[7px]">
            <OtpInput
              otp={otp}
              numInputs={6}
              inputStyle={{ backgroundColor: "#F3F3FF" }}
              onChange={setOtp}
            />
          </div>
          <Button
            disabled={otp.length < 6}
            text={buttonText[verifyFlow]}
            buttonStyle="py-[9px] mt-[26px] border border-[#B9C1CC] rounded-[3px]"
            onClick={handleSubmitOtp}
          />
          <div className="h-[1px] w-full bg-[#707070] mt-[18px]" />
          <p className="text-center text-[#404040] text-sm leading-[17px] mt-3">
            Didn't Received Verification Code?{" "}
            <span className="text-[#00ADEE]" onClick={handleResendOtp}>Resend</span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default VerifyOtp;
