export const baseURL = process.env.REACT_APP_MAIN_BASE_URL;
export const userUrl = baseURL + "/user";
export const phoneCallUrl = baseURL + "/phonecall";
export const visitorUrl = baseURL + "/visitor";
export const ambulanceUrl = baseURL + "/ambulance";
export const postalUrl = baseURL + "/postal";
export const dashboardUrl = baseURL + "/dashboard";
export const doctorUrl = baseURL + "/doctor";
export const patientUrl = baseURL + "/patient";
export const insuranceDetailUrl = baseURL + "/insuranceDetail";
export const opdUrl = baseURL + "/opdAppointment";
export const rolesUrl = baseURL + "/roles";
export const functionalityUrl = baseURL + "/functionality";
export const moduleUrl = baseURL + "/module";
export const policyUrl = baseURL + "/policy";
export const patientOpdLifeCycleUrl = "/patientOpdLifeCycle";
export const opdReportUrl = baseURL + "/report/opd";
export const notificationUrl = baseURL + "/notification";
