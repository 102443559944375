import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import TableWithSearchAndPagination from "../../components/tableWithSearchAndPagination";
import WithAuth from "../../layout/withAuth";
import WithHeaderSidebar from "../../layout/withHeaderSidebar";
import { getPatientListPage } from "../../services/patient";
import dayjs from "dayjs";

const headings = [
  {
    label: "Patient ID",
    width: "10%",
    align: "left",
  },
  {
    label: "Patient Name",
    width: "20%",
    align: "left",
  },
  {
    label: "Registration Date",
    width: "15%",
    align: "left",
  },
  {
    label: "Contact",
    width: "15%",
    align: "left",
  },
  {
    label: "Actions",
    width: "10%",
    align: "center",
  },
];

const dataToShow = [
  {
    key: "patientId",
    align: "left",
  },
  {
    key: "fullName",
    align: "left",
  },
  {
    key: "patientRegistrationDate",
    align: "left",
  },
  {
    key: "primaryContactNumber",
    align: "left",
  },
];

const PatientHistoryList = () => {
  const navigate = useNavigate();
  const [totalData, setTotalData] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [callbackAttributes, setCallbackAttributes] = useState({
    searchSend: "",
    perPage: "25",
    selectedPage: "0",
    sort: "date,desc",
  });
  const [phoneCallList, setPatientList] = useState([]);

  const handleActionHistory = (item) => {
    navigate("/patients/history/" + item.patientId);
  }

  const displayPatientList = useCallback(() => {
    setPatientList([]);
    const { searchSend, selectedPage, perPage, sort } = callbackAttributes;
    let data = `searchKey=${searchSend}&page=${selectedPage}&size=${perPage}&sort=${sort}`;
    getPatientListPage(data).then((res) => {
      if (res?.status === 200) {
        const patientList = res?.result?.patientList?.map((item) => {
          return {
            ...item,
            fullName:
              item.firstName + " " + item.middleName + " " + item.lastName,
            patientRegistrationDate: dayjs(item.patientRegistrationDate).format("DD/MM/YYYY"),
          };
        });
        setPatientList(patientList || []);
        setTotalData(res?.result?.getTotalRecords || 0);
        setTotalPages(res?.result?.getTotalPages || 0);
      } else {
        // props?.setSnackbar({
        //     showSnackbar: true,
        //     message: res?.message,
        //     variant: "error"
        // })
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callbackAttributes]);

  useEffect(() => {
    displayPatientList();
  }, [displayPatientList, callbackAttributes]);

  return (
    <>
      <div className="h-full ">
        <div className="p-7">
          <p className="text-xl md:text-2xl md:leading-6 text-[#404040] font-bold">
            Patient
          </p>
        </div>
        <div className="bg-[#F7F8F9] sm:pt-[18px] p-5 sm:pl-7 sm:pr-11 sm:pb-9">
          <div className="bg-white w-full h-full shadow-[0px_9px_20px] shadow-[#2E235E1A] rounded-[15px]">
            <div className="flex flex-row items-center justify-between p-3 sm:p-7 border-b ">
              <p className="text-base md:text-xl leading-6 text-[#404040] font-bold">
                Patient List
              </p>
            </div>
            <div className="p-3 sm:p-7 sm:pt-5">
              <TableWithSearchAndPagination
                headings={headings}
                dataToShow={dataToShow}
                data={phoneCallList}
                totalData={totalData}
                totalPages={totalPages}
                actions={["history"]}
                setCallbackAttributes={setCallbackAttributes}
                handleActionHistory={handleActionHistory}
                module="Manage Patient"
                actionPermission={{
                  view: "View"
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WithAuth(WithHeaderSidebar(PatientHistoryList));
