import React from "react";
import WithHeaderSidebar from "../layout/withHeaderSidebar";

const NotGranted = () => {
  return (
    <div className="flex items-center justify-center pt-32 px-10 md:px-44">
      <div className="shadow-[0_0.5rem_1rem_#00000026] p-4 md:p-14 rounded-lg w-full">
        <p className="text-7xl font-bold text-center">403</p>
        <p className="text-2xl text-center font-normal mt-5">
          OOPS!!! FORBIDDEN
        </p>
        <p className="text-2xl text-center font-normal mt-14">
          Sorry, you are not allowed to visit this page.
        </p>
      </div>
    </div>
  );
};

export default WithHeaderSidebar(NotGranted);
