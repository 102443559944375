import React, { useEffect, useState } from "react";

const SearchBox = ({
  containerStyle = "",
  labelStyle = "",
  inputStyle = "",
  label = "",
  placeholder = "",
  name = "",
  value = "",
  onChange = () => {},
}) => {
    
  const [inputValue, setInputValue] = useState("");
  useEffect(() => {
    setInputValue(value);
  }, [value]);

  return (
    <div className={`flex flex-col w-full ${containerStyle}`}>
      {label ? (
        <p
          className={`text-xs sm:text-sm sm:leading-[21px] text-[#404040] mb-2 ${labelStyle}`}
        >
          {label}
        </p>
      ) : null}
      <div className="flex justify-between  border border-[#CED4DA] rounded-[5px]">
        <div className="px-2 py-[6px] sm:px-[14px] w-[90%]">
          <input
            name={name}
            value={inputValue}
            placeholder={placeholder}
            className={`text-sm leading-[21px] placeholder-[#C5C5C5] text-[#404040] outline-none rounded-[5px] w-full ${inputStyle}`}
            onChange={(event) => {
              setInputValue(event.target.value);
              onChange(name, event.target.value);
            }}
          />
        </div>
        <div
          className="flex justify-center items-center px-2 py-[6px] sm:px-[12px] bg-[#E9ECEF] rounded-r-[5px] border-l border-[#CED4DA] w-[39px] cursor-pointer"
          onClick={() => {
            onChange(name, inputValue);
          }}
        >
          <i className="fa-solid fa-magnifying-glass"></i>
        </div>
      </div>
    </div>
  );
};

export default SearchBox;
