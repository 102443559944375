import axiosInterceptors from "../axiosInterceptor/axiosInterceptor";
import { patientUrl } from "../config/apiConfig";

export const addNewPatient = (data) => {
  return axiosInterceptors()
    .post(patientUrl + "/addNewPatient", data)
    .then((res) => res);
};

export const updatePatientById = (id, data) => {
  return axiosInterceptors()
    .put(`${patientUrl}/updatePatientById/${id}`, data)
    .then((res) => res)
};

export const getPatientById = (id) => {
  return axiosInterceptors()
    .get(patientUrl + "/getPatientById/" + id)
    .then((res) => res);
};

export const searchPatientByName = (data) => {
  return axiosInterceptors()
    .get(patientUrl + "/searchPatientByName?" + data)
    .then((res) => res);
};

export const getAllPatients = (data) => {
  return axiosInterceptors()
    .get(patientUrl + "/getAllPatients?" + data)
    .then((res) => res);
};

export const deletePatientById = (id) => {
  return axiosInterceptors()
    .delete(patientUrl + "/deletePatientById/" + id)
    .then((res) => res);
};

export const getPatientListPage = (data) => {
  return axiosInterceptors()
    .get(patientUrl + "/getPatientListPage?" + data)
    .then((res) => res);
};
