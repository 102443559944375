import React, { createRef, useRef, useState } from "react";
import TextInput from "../../components/textInput";
import Button from "../../components/button";
import { useNavigate } from "react-router-dom";
import RadioGroup from "../../components/radioGroup";

const upiOptions = [
  {
    option: "gpay",
    image: "./images/gpay.png",
  },
  {
    option: "paytm",
    image: "/images/paytm.png",
  },
  {
    option: "phonepay",
    image: "/images/phonepay.png",
  },
  {
    option: "upi",
    image: "/images/upi.png",
  },
];

const PaymentMethod = () => {
  const navigate = useNavigate();
  const [methodSelected, setMethodSelected] = useState("card");
  const inputRefs = useRef([createRef(), createRef()]);
  const [info, setInfo] = useState({
    email: "",
    password: "",
  });

  const handleChange = (name, value) => {
    setInfo((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  const handleLogin = () => {
    let isValid = true;
    for (let i = 0; i < inputRefs.current.length; i++) {
      const valid = inputRefs.current[i].current.validate();
      if (!valid) {
        isValid = false;
      }
    }
    if (!isValid) {
      return;
    }
  };
  const renderPaymentMethodSelect = () => {
    return (
      <div className="flex flex-row items-end w-full mt-6">
        <div
          className={`flex items-center justify-center border-[#898989] py-2 px-3 border  ${
            methodSelected === "card"
              ? "border-b-transparent rounded-t-[5px]"
              : "border-transparent border-b-[#898989]"
          }`}
          onClick={() => {
            setMethodSelected("card");
          }}
        >
          <p className="text-sm leading-[17px] text-[#898989] font-bold">
            Credit / Debit Card
          </p>
        </div>
        <div
          className={`flex items-center justify-center border-[#898989] py-2 px-3 border ${
            methodSelected === "upi"
              ? "border-b-transparent rounded-t-[5px]"
              : "border-transparent border-b-[#898989]"
          }`}
          onClick={() => {
            setMethodSelected("upi");
          }}
        >
          <p className="text-sm leading-[17px] text-[#898989] font-bold">UPI</p>
        </div>
        <div
          className={`flex items-center justify-center  border-[#898989] py-2 px-3 border ${
            methodSelected === "offline"
              ? "border-b-transparent rounded-t-[5px]"
              : "border-transparent border-b-[#898989]"
          }`}
          onClick={() => {
            setMethodSelected("offline");
          }}
        >
          <p className="text-sm leading-[17px] text-[#898989] font-bold">
            Offline
          </p>
        </div>
        <div className="bg-[#898989] h-[1px] w-auto" />
      </div>
    );
  };
  const renderUpiOptions = () => {
    return (
      <div className="mt-6">
        <p className="text-sm leading-[17px] text-[#404040] font-bold">
          Select Your UPI Platform
        </p>
        <div className="flex flex-row justify-between mt-3">
          {upiOptions?.map((item, index) => {
            return (
              <div
                className="px-[14px] py-[17px] border border-[#707070]"
                key={index}
              >
                <img
                  className="h-[18px] w-14"
                  src={item.image}
                  alt="payment method"
                />
              </div>
            );
          })}
        </div>
        <p className="mt-[9px] text-center text-sm leading-[17px] text-[#404040] font-bold">
          OR
        </p>
        <TextInput
          label="UPI ID"
          placeholder="pulse.emr@icici"
          inputContainerStyle="bg-[#F3F3FF] border-[0px]"
          name="cardNumber"
          containerStyle="mt-[3px]"
        />
        <div className="w-full justify-center flex">
          <img
            src="./images/qrcode.png"
            className="h-20 w-20 -mt-2 self-center"
            alt="qr code"
          />
        </div>
      </div>
    );
  };
  const renderCardForm = () => {
    return (
      <div className="mt-6">
        <TextInput
          label="Card Number"
          placeholder="**** **** ****"
          inputContainerStyle="bg-[#F3F3FF] border-[0px]"
          name="cardNumber"
        />
        <div className="flex flex-col sm:flex-row sm:gap-6">
          <TextInput
            label="Valid Through (MM/YY)"
            placeholder="MM / YY"
            inputContainerStyle="bg-[#F3F3FF] border-[0px]"
            name="expiry"
          />
          <TextInput
            label="CVV"
            placeholder="***"
            inputContainerStyle="bg-[#F3F3FF] border-[0px]"
            name="cvv"
            rightIcon={<i className="fa-solid fa-circle-info"></i>}
            rightIconContainerStyle="bg-[#F3F3FF] border-l-0"
          />
        </div>
        <TextInput
          label="Name on Card"
          placeholder="Name on Card"
          inputContainerStyle="bg-[#F3F3FF] border-[0px]"
          name="name"
        />
      </div>
    );
  };
  const renderOfflineMethod = () => {
    return (
      <div className="mt-6">
        <RadioGroup
          options={[
            {
              label: "Cheque",
              value: "cheque",
            },
            {
              label: "Cash",
              value: "cash",
            },
          ]}
          optionsContainerStyle="flex flex-row gap-12"
        />
        <div className="flex flex-col gap-3 mt-6 mb-[70px]">
          <div className="flex flex-row">
            <p className="text-xs sm:text-sm sm:leading-[27px] text-[#404040] font-bold w-[40%] sm:w-[30%]">
              Account Name
            </p>
            <p className="text-xs sm:text-sm sm:leading-[27px] text-[#404040] font-bold">
              : Ematrix Infotech Pvt. Ltd.
            </p>
          </div>
          <div className="flex flex-row">
            <p className="text-xs sm:text-sm sm:leading-[27px] text-[#404040] font-bold w-[40%] sm:w-[30%]">
              Account Number
            </p>
            <p className="text-xs sm:text-sm sm:leading-[27px] text-[#404040] font-bold">
              : 1712124138
            </p>
          </div>
          <div className="flex flex-row">
            <p className="text-xs sm:text-sm sm:leading-[27px] text-[#404040] font-bold w-[40%] sm:w-[30%]">
              IFSC Code
            </p>
            <p className="text-xs sm:text-sm sm:leading-[27px] text-[#404040] font-bold">
              : ICICI0024
            </p>
          </div>
          <div className="flex flex-row">
            <p className="text-xs sm:text-sm sm:leading-[27px] text-[#404040] font-bold w-[40%] sm:w-[30%]">
              Branch
            </p>
            <p className="text-xs sm:text-sm sm:leading-[27px] text-[#404040] font-bold">
              : Joggers Park, Jamnagar
            </p>
          </div>
        </div>
      </div>
    );
  };
  return (
    <div
      className="flex flex-col md:flex-row h-screen w-screen"
      style={{
        backgroundImage: "url(/images/auth_screens_Background.png)",
        backgroundSize: "cover",
      }}
    >
      <div className="md:flex items-center justify-center md:w-[50%] bg-white opacity-90 hidden">
        <img
          src="/images/Pulse_Logo_Auth_Screens.png"
          className="w-[192px] h-[232px]"
          alt="logo"
        />
      </div>
      <div className="flex flex-col md:w-[50%] h-screen bg-[#f3f3ffe6] items-center sm:justify-center p-5 md:px-10 lg:px-28">
        <img
          src="/images/Pulse_Logo_Auth_Screens.png"
          className="w-[132px] h-[160px] m-5 md:hidden"
          alt="logo"
        />
        <div className="flex flex-col bg-white p-8 sm:px-10 sm:pt-[71px] sm:pb-[75px] w-full rounded-[10px] shadow-2xl">
          <p className="text-2xl leading-7 text-[#000000] font-bold">
            Amount to Pay Rs. 12500/-
          </p>
          <p className="text-sm leading-[17px] text-[#898989] font-bold mt-2">
            Choose Payment Method
          </p>
          {renderPaymentMethodSelect()}
          {methodSelected === "card"
            ? renderCardForm()
            : methodSelected === "upi"
            ? renderUpiOptions()
            : renderOfflineMethod()}
          <Button
            text="Checkout"
            buttonStyle="py-[9px] mt-[10px] border border-[#B9C1CC] rounded-[3px]"
          />
        </div>
      </div>
    </div>
  );
};

export default PaymentMethod;
