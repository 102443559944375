import { LocalizationProvider, TimeField } from "@mui/x-date-pickers";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";

const TimePicker = (
  {
    name = "",
    label = "",
    labelStyle = "",
    value,
    validations = [],
    onChange = () => {},
  },
  ref
) => {
  const [timeValue, setTimeValue] = useState("");
  const [error, setError] = useState("");

  useImperativeHandle(ref, () => {
    return {
      validate: () => validate(),
      setError: (e) => setError(e),
    };
  });

  const handleTimeValue = (value) => {
    const [hoursMinutes, amOrPm] = value.split(" ");
    let [hours, minutes, seconds] = hoursMinutes.split(":");
    hours = parseInt(hours, 10);
    minutes = parseInt(minutes, 10);
    seconds = parseInt(seconds, 10);

    if (amOrPm === "PM" && hours < 12) {
      hours += 12;
    } else if (amOrPm === "AM" && hours === 12) {
      hours = 0;
    }
    const selectedDateTime = new Date();
    selectedDateTime.setHours(hours, minutes, seconds);
    setTimeValue(dayjs(selectedDateTime));
  };

  useEffect(() => {
    if (value) {
      handleTimeValue(value);
      setError(false);
    }
  }, [value]);

  const handleChange = (value, context) => {
    if (context.validationError) {
      setError("Please enter valid time");
    } else {
      setError(false);
      onChange && onChange(name, dayjs(value).format("hh:mm:ss A"));
    }
  };

  const validate = () => {
    let isValid = true;
    for (let i = 0; i < validations.length; i++) {
      const validation = validations[i];
      if (validation.type === "required") {
        if (!value) {
          setError(validation.message);
          isValid = false;
          break;
        }
      } else if (validation.type === "regex") {
        if (!new RegExp(validation.regex).test(value)) {
          setError(validation.message);
          isValid = false;
          break;
        }
      }
    }
    return isValid;
  };

  return (
    <div className="flex flex-col w-full">
      {label ? (
        <p
          className={`text-sm leading-[21px] text-[#404040] mb-2 ${labelStyle}`}
        >
          {label}
        </p>
      ) : (
        <div className="mb-7" />
      )}
      <div className="flex justify-between items-center  rounded-[5px] w-full">
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <TimeField
            fullWidth={true}
            InputProps={{
              style: {
                height: 38,
              },
            }}
            format="hh:mm A"
            value={timeValue ? timeValue : dayjs(new Date())}
            onChange={handleChange}
          />
        </LocalizationProvider>
      </div>
      {error ? (
        <span className="text-sm text-red-500">{error}</span>
      ) : (
        <span className="h-5" />
      )}
    </div>
  );
};

export default forwardRef(TimePicker);
