import React, { useEffect, useState } from "react";
import TextInput from "../../components/textInput";
import { ReactComponent as EyeIcon } from "../../assets/svgs/eye.svg";
import { ReactComponent as EyeSlashIcon } from "../../assets/svgs/eye-slash.svg";
import { getAllRoles } from "../../services/permissions/roles";
import Dropdown from "../../components/dropdown";

const validations = {
  firstName: [{ type: "required", message: "Please enter first name" }],
  lastName: [{ type: "required", message: "Please enter last name" }],
  userName: [{ type: "required", message: "Please enter user name" }],
  password: [
    { type: "required", message: "Please enter password" },
    { type: "passwordCapital", message: "Password should have at lest one capital letter" },
    { type: "passwordDigit", message: "Password should have at lest one digit" },
    { type: "passwordSpecialCharacter", message: "Password should have at lest one special character" },
    { type: "passwordLength", message: "Password should have at lest six characters" },
  ],
  mobileNumber: [{ type: "required", message: "Please enter mobile number" }],
  role: [{ type: "required", message: "Please select role" }],
};

const UserInfo = ({
  editMode = false,
  userInfo,
  inputRefs,
  handleChange = () => {},
  user,
}) => {
  const [rolesList, setRolesList] = useState([]);
  const [showPass, setShowPass] = useState(false);

  const getRolesList = () => {
    getAllRoles().then((res) => {
      if (res.status === 200) {
        const rolesList = res.result?.rolesList.map((role) => {
          return {
            label: role.roleName,
            value: role.roleId,
          };
        });
        setRolesList(rolesList);
      }
    });
  };
  useEffect(() => {
    getRolesList();
  }, []);
  return (
    <>
      <TextInput
        validations={validations.firstName}
        value={userInfo.firstName}
        ref={inputRefs.current[0]}
        label="First Name"
        name="firstName"
        onChange={handleChange}
        placeholder="Enter first name"
      />
      <TextInput
        validations={validations.lastName}
        value={userInfo.lastName}
        ref={inputRefs.current[1]}
        label="Last Name"
        name="lastName"
        onChange={handleChange}
        placeholder="Enter last name"
      />
      <TextInput
        validations={validations.userName}
        value={userInfo.userName}
        ref={inputRefs.current[2]}
        label="User Name"
        name="userName"
        onChange={handleChange}
        placeholder="Enter user name"
      />
      <TextInput
        validations={validations.password}
        value={userInfo.password}
        ref={inputRefs.current[3]}
        label="Password"
        name="password"
        onChange={handleChange}
        placeholder="Enter password"
        type={showPass ? "text" : "password"}
        disabled={editMode ? true : false}
        rightIcon={
          showPass ? (
            <EyeIcon className="h-4" />
          ) : (
            <EyeSlashIcon className="h-4" />
          )
        }
        onRightIconClick={() => {
          setShowPass((prev) => !prev);
        }}
      />
      <TextInput
        validations={validations.mobileNumber}
        value={userInfo.mobileNumber}
        ref={inputRefs.current[4]}
        label="Mobile Number"
        name="mobileNumber"
        onChange={handleChange}
        placeholder="Enter mobile number"
      />
      <Dropdown
        options={rolesList}
        label="Role"
        placeholder="Select Role"
        value={userInfo.roleId}
        ref={inputRefs.current[5]}
        onChange={(value) => {
          handleChange("roleId", value.value);
        }}
        validations={validations.role}
      />
    </>
  );
};

export default UserInfo;
