import React, { forwardRef } from "react";


const AddOnTextBox = ({
    addOn,
    labelText,
    labelStyle="",
    onChange = ()=>{},
    name="",
    value,
    placeholder="",
    type="text",
    inputContainerStyle="",
    inputStyle=""
}, ref) => {
    return (
        <div className="flex items-center w-full gap-2">
            <div className={`w-[35%] text-sm ${labelStyle}`}>{labelText}</div>
            <div className={`w-[65%] flex items-center col-span-2 border-[1px] border-[#CED4DA] rounded-[5px] ${inputContainerStyle}`}>
                <input
                    name={name} 
                    placeholder={placeholder}
                    type={type}
                    value={value}
                    onChange={onChange}
                    className={`w-[65%] text-sm pl-2 py-1 outline-none ${inputStyle}`}
                    ref={ref}
                />
                <div className="text-center bg-[#E9ECEF] w-[35%] rounded-r-[5px] border-l border-[#CED4DA] py-1 px-1">
                    {addOn}
                </div>
            </div>
        </div>
    );
}

export default forwardRef(AddOnTextBox);
