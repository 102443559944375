import axiosInterceptors from "../axiosInterceptor/axiosInterceptor";
import { ambulanceUrl } from "../config/apiConfig";

export const getAmbulanceLogListPage = (data) => {
  return axiosInterceptors()
    .get(ambulanceUrl + "/getAmbulanceLogListPage?" + data)
    .then((res) => res);
};

export const getAmbulanceLogById = (id) => {
  return axiosInterceptors()
    .get(ambulanceUrl + "/getAmbulanceLogById/" + id)
    .then((res) => res);
};

export const deleteAmbulanceLogById = (id) => {
  return axiosInterceptors()
    .delete(ambulanceUrl + "/deleteAmbulanceLogById/" + id)
    .then((res) => res);
};

export const addNewAmbulanceLogEntry = (data) => {
  return axiosInterceptors()
    .post(ambulanceUrl + "/addNewAmbulanceLogEntry", data)
    .then((res) => res);
};
