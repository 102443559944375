import axiosInterceptors from "../axiosInterceptor/axiosInterceptor";
import { doctorUrl } from "../config/apiConfig";

export const getAllDoctors = () => {
  return axiosInterceptors()
    .get(doctorUrl + "/getAllDoctors")
    .then((res) => res);
};

export const getDoctorById = (docId) => {
  return axiosInterceptors()
    .get(`${doctorUrl}/getDoctorById/${docId}`)
    .then((res)=>res);
}

export const getDoctorOpd = (docId, data, loader) => {
  return axiosInterceptors(loader)
    .get(`${doctorUrl}/getDoctorOpd/${docId}?${data}`)
    .then((res)=>res);
};
