import React, { createRef, useRef, useState } from "react";
import TextInput from "../../components/textInput";
import Button from "../../components/button";
import { useNavigate } from "react-router-dom";
import { createAccount } from "../../services/auth";

const validations = {
  firstName: [
    {
      type: "required",
      message: "Enter your first name",
    },
  ],
  lastName: [
    {
      type: "required",
      message: "Enter your last name",
    },
  ],
  mobileNumber: [
    {
      type: "required",
      message: "Enter your mobile number",
    },
  ],
  companyName: [
    {
      type: "required",
      message: "Enter your company name",
    },
  ],
  email: [
    {
      type: "required",
      message: "Enter your email address",
    },
    {
      type: "regex",
      regex:
        "^[_A-Za-z0-9-\\+]+(\\.[_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(\\.[A-Za-z0-9]+)*(\\.[A-Za-z]{2,})$",
      message: "Please enter a valid email address.",
    },
  ],
  password: [
    {
      type: "required",
      message: "Enter your password",
    },
  ],
};

const Register = () => {
  const navigate = useNavigate();
  const inputRefs = useRef([
    createRef(),
    createRef(),
    createRef(),
    createRef(),
    createRef(),
    createRef(),
  ]);
  const [privacyAgreed, setPrivacyAgreed] = useState(false)
  const [info, setInfo] = useState({
    firstName: "",
    lastName: "",
    email: "",
    mobileNumber: "",
    companyName: "",
    password: "",
  });
  const [error, setError] = useState()

  const handleChange = (name, value) => {
    setInfo((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  const handleRegister = () => {
    setError("")
    let isValid = true;
    for (let i = 0; i < inputRefs.current.length; i++) {
      const valid = inputRefs.current[i].current.validate();
      if (!valid) {
        isValid = false;
      }
    }
    if (!isValid) {
      return;
    }
    createAccount(info).then(res => {
      if (res.status === 200) {
        const { userId, email, mobileNumber } = res?.result?.user || {}
        navigate("/verify-otp", { state: { verifyFlow: "email", userId, email, mobileNumber } });
      } else {
        setError(res.message)
      }
    })
  };

  return (
    <div
      className="flex flex-col md:flex-row h-screen w-screen"
      style={{
        backgroundImage: "url(/images/auth_screens_Background.png)",
        backgroundSize: "cover",
      }}
    >
      <div className="md:flex items-center justify-center md:w-[50%] bg-white opacity-90 hidden">
        <img
          src="/images/Pulse_Logo_Auth_Screens.png"
          className="w-[192px] h-[232px]"
          alt="logo"
        />
      </div>
      <div className="flex flex-col md:w-[50%] h-screen bg-[#f3f3ffe6] items-center sm:justify-center p-5 md:px-10 lg:px-28">
        <img
          src="/images/Pulse_Logo_Auth_Screens.png"
          className="w-[132px] h-[160px] m-5 md:hidden"
          alt="logo"
        />
        <div className="flex flex-col bg-white p-8 sm:px-10 sm:pt-[71px] sm:pb-[75px] w-full rounded-[10px] shadow-2xl">
          <p className="text-2xl leading-7 text-[#000000] font-bold">
            Create Your Account
          </p>
          <p className="text-sm leading-[17px] text-[#898989] font-bold mt-2">
            Enter your details to create new account
          </p>
          <div className="flex flex-col sm:flex-row mt-4 sm:mt-[25px] sm:gap-[26px]">
            <TextInput
              label="Your Name"
              placeholder="First Name"
              inputContainerStyle="bg-[#F3F3FF] border-[0px]"
              name="firstName"
              value={info.firstName}
              onChange={handleChange}
              ref={inputRefs.current[0]}
              validations={validations.firstName}
            />
            <TextInput
              placeholder="Last Name"
              inputContainerStyle="bg-[#F3F3FF] border-[0px]"
              name="lastName"
              value={info.lastName}
              onChange={handleChange}
              ref={inputRefs.current[1]}
              validations={validations.lastName}
            />
          </div>
          <TextInput
            label="Email Address"
            placeholder="YourEmail@pulseemr.com"
            inputContainerStyle="bg-[#F3F3FF] border-[0px]"
            name="email"
            value={info.email}
            onChange={handleChange}
            ref={inputRefs.current[2]}
            validations={validations.email}
          />
          <TextInput
            label="Mobile Number"
            placeholder="+91 00000 00000"
            inputContainerStyle="bg-[#F3F3FF] border-[0px]"
            name="mobileNumber"
            value={info.mobileNumber}
            onChange={handleChange}
            ref={inputRefs.current[3]}
            validations={validations.mobileNumber}
          />
          <TextInput
            label="Company Name"
            placeholder="Your Company Name"
            inputContainerStyle="bg-[#F3F3FF] border-[0px]"
            name="companyName"
            value={info.companyName}
            onChange={handleChange}
            ref={inputRefs.current[4]}
            validations={validations.companyName}
          />
          <TextInput
            label="Password"
            placeholder="******"
            inputContainerStyle="bg-[#F3F3FF] border-[0px]"
            name="password"
            type="password"
            value={info.password}
            onChange={handleChange}
            ref={inputRefs.current[5]}
            validations={validations.password}
          />
          <div className="flex flex-row">
            <input type="checkbox" checked={privacyAgreed} onChange={() => {
              setPrivacyAgreed(val => !val)
            }} />
            <p className="text-[#404040] text-sm leading-[17px] font-bold ml-2">
              Agree With{" "}
              <span className="text-[#00ADEE] cursor-pointer">
                Privacy Policy
              </span>
            </p>
          </div>
          {error ? <span className="text-sm text-red-500 mt-2">{error}</span> : null}
          <Button
            text="Create New Account"
            buttonStyle="py-[9px] mt-[17px] border border-[#B9C1CC] rounded-[3px]"
            onClick={handleRegister}
            disabled={!privacyAgreed}
          />
          <div className="h-[1px] w-full bg-[#707070] mt-6" />
          <p className="text-center text-[#404040] text-sm leading-[17px] mt-3">
            Already have an account?{" "}
            <span
              className="text-[#00ADEE] cursor-pointer"
              onClick={() => {
                navigate("/");
              }}
            >
              Sign in
            </span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Register;
