import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";

const TextInput = (
  {
    leftIcon = null,
    rightIcon = null,
    containerStyle = "",
    labelStyle = "",
    inputStyle = "",
    label = "",
    placeholder = "",
    name = "",
    value = "",
    type = "",
    disabled = false,
    onChange = () => {},
    validations = [],
    inputContainerStyle = "",
    rightIconContainerStyle = "",
    withoutLabel = false,
    onRightIconClick = () => {},
    extraArgs=0,
    arg="",
    onKeyUp = ()=>{}
  },
  ref
) => {
  const [error, setError] = useState("");
  useImperativeHandle(ref, () => {
    return {
      validate: () => validate(),
      setError: (e) => setError(e),
    };
  });

  useEffect(() => {
    if (value) {
      setError(false);
    }
  }, [value]);

  const handleChange = (event) => {
    if (event.target.value.trim() === "") {
      event.target.value = "";
    } else {
      setError(false);
    }
    if(extraArgs === 0) {
      onChange(event.target.name, event.target.value);
    } else {
      onChange(event.target.name, event.target.value, arg);
    }
  };

  const validate = () => {
    let isValid = true;
    for (let i = 0; i < validations.length; i++) {
      const validation = validations[i];
      if (validation.type === "required") {
        if (!value.trim()) {
          setError(validation.message);
          isValid = false;
          break;
        }
      } else if (validation.type === "regex") {
        if (!new RegExp(validation.regex).test(value)) {
          setError(validation.message);
          isValid = false;
          break;
        }
      } else if (validation.type === "passwordCapital") {
        if (!new RegExp("(?=.*[A-Z]).+").test(value)) {
          setError(validation.message);
          isValid = false;
          break;
        }
      } else if (validation.type === "passwordDigit") {
        if (!new RegExp("[0-9].*").test(value)) {
          setError(validation.message);
          isValid = false;
          break;
        }
      } else if (validation.type === "passwordSpecialCharacter") {
        if (!new RegExp("[@,!,#,$,%,^,&,*,(,),-,_,=,+,|,<,>]+").test(value)) {
          setError(validation.message);
          isValid = false;
          break;
        }
      } else if (validation.type === "passwordLength") {
        if (value.length < 6) {
          setError(validation.message);
          isValid = false;
          break;
        }
      }
    }
    return isValid;
  };

  return (
    <div className={`flex flex-col w-full ${containerStyle}`}>
      {!withoutLabel ? (
        <>
          {label ? (
            <p
              className={`text-sm leading-[21px] text-[#404040] mb-2 ${labelStyle}`}
            >
              {label}
            </p>
          ) : (
            <div className="mb-7" />
          )}
        </>
      ) : null}
      <div
        className={`flex justify-between items-center border border-[#CED4DA] rounded-[5px] ${inputContainerStyle}`}
      >
        {leftIcon ? (
          <div className="flex justify-center items-center h-[36px] w-[36px] bg-[#E9ECEF] rounded-l-[5px] border-r border-[#CED4DA]">
            {leftIcon}
          </div>
        ) : null}
        <div className="py-[6px] px-[14px] w-full">
          <input
            name={name}
            value={value}
            placeholder={placeholder}
            type={type}
            disabled={disabled}
            className={`text-sm leading-[21px] placeholder-[#C5C5C5] text-[#404040] outline-none w-full bg-transparent ${inputStyle}`}
            onChange={handleChange}
            onKeyUp={onKeyUp}
          />
        </div>
        {rightIcon ? (
          <div
            className={
              rightIconContainerStyle
                ? rightIconContainerStyle
                : "flex justify-center items-center h-[36px] w-[36px] bg-[#E9ECEF] rounded-r-[5px] border-l border-[#CED4DA] "
            }
            onClick={onRightIconClick}
          >
            {rightIcon}
          </div>
        ) : null}
      </div>
      {error ? (
        <span className="text-sm text-red-500 mb-5 mt-1">{error}</span>
      ) : (
        <span className="h-5" />
      )}
    </div>
  );
};

export default forwardRef(TextInput);
