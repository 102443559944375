import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { resetUser } from "../../actions/userActions";


const Logout = ({resetUser}) => {
    const navigate = useNavigate();
    useEffect(() => {
        resetUser();
        sessionStorage.removeItem("token");
        navigate("/");
    });
    return <></>
}

const mapDispatchToProps = (dispatch) => {
    return {
        resetUser: () => dispatch(resetUser()),
    };
};

export default connect(null, mapDispatchToProps)(Logout);
